<!-- <div style="display:flex;" >
	<app-metro style="position:sticky;top:40%;min-width:340px;height: 340px"></app-metro>

	<app-song-part style="margin:10px" [index]="0" [part]="part" ></app-song-part>
</div>

	<app-tab-parser ></app-tab-parser>
	
	<app-chords-from-youtube ></app-chords-from-youtube> -->

<!-- <app-piano></app-piano> -->

<div id="testpage">

	<app-midi-chord-detect-abc ></app-midi-chord-detect-abc>
</div>