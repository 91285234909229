import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class ConfigModel {
  private autoScroll: boolean = true;

  private follow: boolean = true;

  public minimalRendering: boolean = false;

  private displayMode: "chords" | "analyse" | "both" = "chords";

  public editor_visible: boolean = false;

  public metro_visible: boolean = false;

  public metroVolume: number = 0.0001;

  public dico_visible: boolean = false;

  public options_visible: boolean = false;

  public chordsBrowse_visible: boolean = false;

  public tutorial_skipped: boolean = true;

  public midi_input_selected: string = "";

  public control_input_selected: string = "";

  public assigned_controls: any = [];

  // The braid / fifths circle menu
  public circle_visible: boolean = true;

  public fifths_visible: boolean = false;

  public braid_visible: boolean = true;

  public midi_is_guitar: boolean = false;

  public display_fretboard: boolean = true;

  public display_piano: boolean = true;

  public display_audio: boolean = false;

  public display_chordstrip: boolean = true;

  public tonalityLockedToScore: boolean = true;

  public braid_is_roman: boolean = false;

  public tonality_button_always_open: boolean = true;

  public emphasis_chords: boolean = false;

  public emphasis_scale: boolean = false;

  public braid_simpler: boolean = false;

  public braid_one_tona: boolean = false;

  public midi_highlight_visible: boolean = true;

  public score_highlight_visible: boolean = true;

  public novaxe_notation_on: boolean = false;

  public browse_dislplay_list: boolean = false;

  public letters_Numbers_sync: boolean = true;

  public display_midi_chord: boolean = true;

  public displayNotesMode: "numbers" | "letters" = "numbers";

  public global_show_score_chords: boolean = true;

  public use_chromakey: boolean = false;

  private displayNotesMode_SUBJ = new Subject<any>();
  public displayNotesMode_SUBJ_update$ =
    this.displayNotesMode_SUBJ.asObservable();

  private global_show_score_chords_SUBJ = new Subject<any>();
  public global_show_score_chords_SUBJ$ =
    this.global_show_score_chords_SUBJ.asObservable();

  private letters_numbers_sync_subj = new Subject<any>();
  public letters_numbers_sync_update$ =
    this.letters_numbers_sync_subj.asObservable();

  private use_chromakey_subj = new Subject<any>();
  public use_chromakey_update$ = this.use_chromakey_subj.asObservable();


  constructor(private cookie: CookieService) {
    if (!this.loadFromLocalStorage()) this.saveInLocalStorage();

    this.letters_numbers_sync_subj.next(this.letters_Numbers_sync);
  }

  public isEditor_visible(): boolean {
    return this.editor_visible;
  }
  public isMetro_visible(): boolean {
    return this.metro_visible;
  }
  public isCircle_visible(): boolean {
    return this.circle_visible;
  }
  public isDico_visible(): boolean {
    return this.dico_visible;
  }
  public isOptions_visible(): boolean {
    return this.options_visible;
  }
  public isChordsBrowse_visible(): boolean {
    return this.chordsBrowse_visible;
  }
  public isFifths_visible(): boolean {
    return this.fifths_visible;
  }
  public isBraid_visible(): boolean {
    return this.braid_visible;
  }
  public isMidi_highlight_visible(): boolean {
    return this.midi_highlight_visible;
  }
  public isScore_highlight_visible(): boolean {
    return this.score_highlight_visible;
  }
  public is_midi_guitar(): boolean {
    return this.midi_is_guitar;
  }

  public set_midi_chord_display_visible(x: boolean) {
    this.display_midi_chord = x;
    this.saveInLocalStorage();
  }
  public is_midi_chord_display_visible(): boolean {
    return this.display_midi_chord;
  }

  public is_fretboard_visible() {
    return this.display_fretboard;
  }

  public set_fretboard_visible(onOff: boolean) {
    this.display_fretboard = onOff;
    this.saveInLocalStorage();
  }

  public is_piano_visible() {
    return this.display_piano;
  }

  public set_piano_visible(onOff: boolean) {
    this.display_piano = onOff;
    this.saveInLocalStorage();
  }

  public is_audio_visible() {
    return this.display_audio;
  }

  public is_chordstrip_visible() {
    return this.display_chordstrip;
  }

  public set_audio_visible(onOff: boolean) {
    this.display_audio = onOff;
    this.saveInLocalStorage();
  }

  public set_chordstrip_visible(onOff: boolean) {
    this.display_chordstrip = onOff;
    this.saveInLocalStorage();
  }

  public setEditor_visible(onOff: boolean) {
    this.editor_visible = onOff;
    this.saveInLocalStorage();
  }

  public setMetro_visible(onOff: boolean) {
    this.metro_visible = onOff;
    this.saveInLocalStorage();
  }

  public setCircle_visible(onOff: boolean) {
    this.circle_visible = onOff;
    this.saveInLocalStorage();
  }

  public setDico_visible(onOff: boolean) {
    this.dico_visible = onOff;
    this.saveInLocalStorage();
  }

  public setOptions_visible(onOff: boolean) {
    this.options_visible = onOff;
    this.saveInLocalStorage();
  }

  public setChordsBrowse_visible(onOff: boolean) {
    this.chordsBrowse_visible = onOff;
    this.saveInLocalStorage();
  }

  public setFifths_visible(onOff: boolean) {
    this.fifths_visible = onOff;
    this.saveInLocalStorage();
  }

  public setBraid_visible(onOff: boolean) {
    this.braid_visible = onOff;
    this.saveInLocalStorage();
  }

  public setMidi_highlight_visible(onOff: boolean) {
    this.midi_highlight_visible = onOff;
    this.saveInLocalStorage();
  }

  public setScore_highlight_visible(onOff: boolean) {
    this.score_highlight_visible = onOff;
    this.saveInLocalStorage();
  }

  public set_midi_guitar(midi_guitar) {
    this.midi_is_guitar = midi_guitar;
    this.saveInLocalStorage();
  }

  public set_browse_dislplay_list(onOff: boolean) {
    this.browse_dislplay_list = onOff;
    this.saveInLocalStorage();
  }

  public set_letters_Numbers_sync(onOff: boolean) {
    this.letters_Numbers_sync = onOff;
    this.letters_numbers_sync_subj.next(this.letters_Numbers_sync);
    this.saveInLocalStorage();
  }

  public get_letters_Numbers_sync() {
    return this.letters_Numbers_sync;
  }

  public toggleChordsBrowse_visible(): boolean {
    this.chordsBrowse_visible = !this.chordsBrowse_visible;
    this.saveInLocalStorage();
    return this.chordsBrowse_visible;
  }

  public toggleEditor_visible(): boolean {
    this.editor_visible = !this.editor_visible;
    this.saveInLocalStorage();
    return this.editor_visible;
  }

  public toggleMetro_visible(): boolean {
    this.metro_visible = !this.metro_visible;
    this.saveInLocalStorage();
    return this.metro_visible;
  }

  public toggleCircle_visible(): boolean {
    this.circle_visible = !this.circle_visible;
    this.saveInLocalStorage();
    return this.circle_visible;
  }

  public toggleDico_visible(): boolean {
    this.dico_visible = !this.dico_visible;
    this.saveInLocalStorage();
    return this.dico_visible;
  }

  public toggleOptions_visible(): boolean {
    this.options_visible = !this.options_visible;
    this.saveInLocalStorage();
    return this.options_visible;
  }

  public is_tutorial_skipped(): boolean {
    return this.tutorial_skipped;
  }

  public toggleFifths_visible(): boolean {
    this.fifths_visible = !this.fifths_visible;
    this.saveInLocalStorage();
    return this.fifths_visible;
  }

  public toggleBraid_visible(): boolean {
    this.braid_visible = !this.braid_visible;
    this.saveInLocalStorage();
    return this.braid_visible;
  }

  public toggleNovaxeNotation(): boolean {
    this.novaxe_notation_on = !this.novaxe_notation_on;
    this.saveInLocalStorage();
    return this.novaxe_notation_on;
  }

  public setChromakey(val: boolean): boolean {
    this.use_chromakey = val;
    this.saveInLocalStorage();
    return this.use_chromakey;
  }

  public sendUseChromakey() {
    this.use_chromakey_subj.next(this.use_chromakey);
  }

  public getOptionsAsObject(): Object {
    let obj = {};
    obj["autoScroll"] = this.autoScroll;
    obj["follow"] = this.follow;
    obj["minimalRendering"] = this.minimalRendering;
    obj["displayMode"] = this.displayMode;
    obj["tutorial_skipped"] = this.tutorial_skipped;
    obj["midiInput"] = this.midi_input_selected;
    obj["controlInput"] = this.control_input_selected;
    obj["midiIsGuitar"] = this.midi_is_guitar;
    obj["tonalityButtonAlwaysOpen"] = this.tonality_button_always_open;
    obj["assignedControls"] = this.assigned_controls;
    obj["display_piano"] = this.display_piano;
    obj["display_fretboard"] = this.display_fretboard;
    obj["novaxe_notation_on"] = this.novaxe_notation_on;
    obj["browse_dislplay_list"] = this.browse_dislplay_list;
    obj["letters_Numbers_sync"] = this.letters_Numbers_sync;
    obj["displayNotesMode"] = this.displayNotesMode;
    obj["global_show_score_chords"] = this.global_show_score_chords;
    obj["use_chromakey"] = this.use_chromakey;

    obj['editor_visible'] = this.editor_visible;
    obj['metro_visible'] = this.metro_visible;
    obj['metroVolume'] = this.metroVolume;
    obj['dico_visible'] = this.dico_visible;
    obj['options_visible'] = this.options_visible;
    obj['chordsBrowse_visible'] = this.chordsBrowse_visible;
    obj['circle_visible'] = this.circle_visible;
    obj['fifths_visible'] = this.fifths_visible;
    obj['braid_visible'] = this.braid_visible;
    obj['display_audio'] = this.display_audio;
    obj['display_chordstrip'] = this.display_chordstrip;
    obj['tonalityLockedToScore'] = this.tonalityLockedToScore;
    obj['braid_is_roman'] = this.braid_is_roman;
    obj['emphasis_chords'] = this.emphasis_chords;
    obj['emphasis_scale'] = this.emphasis_scale;
    obj['braid_simpler'] = this.braid_simpler;
    obj['braid_one_tona'] = this.braid_one_tona;
    obj['midi_highlight_visible'] = this.midi_highlight_visible;
    obj['score_highlight_visible'] = this.score_highlight_visible;
    obj['display_midi_chord'] = this.display_midi_chord;

    // console.log("getOptionsAsObject", obj);
    return obj;
  }

  public setOptionsFromObject(obj: Object): void {
    // console.log("setOptionsFromObject", obj);
    if (obj.hasOwnProperty("autoScroll")) this.setAutoScroll(obj["autoScroll"]);
    if (obj.hasOwnProperty("follow")) this.setFollow(obj["follow"]);
    if (obj.hasOwnProperty("minimalRendering"))
      this.minimalRendering = obj["minimalRendering"];
    if (obj.hasOwnProperty("displayMode"))
      this.displayMode = obj["displayMode"];
    if (obj.hasOwnProperty("tutorial_skipped"))
      this.tutorial_skipped = obj["tutorial_skipped"];
    if (obj.hasOwnProperty("midiInput"))
      this.midi_input_selected = obj["midiInput"];
    if (obj.hasOwnProperty("controlInput"))
      this.control_input_selected = obj["controlInput"];
    if (obj.hasOwnProperty("midiIsGuitar"))
      this.midi_is_guitar = obj["midiIsGuitar"];
    if (obj.hasOwnProperty("tonalityButtonAlwaysOpen"))
      this.tonality_button_always_open = obj["tonalityButtonAlwaysOpen"];
    if (obj.hasOwnProperty("assignedControls"))
      this.assigned_controls = obj["assignedControls"];
    if (obj.hasOwnProperty("display_piano"))
      this.display_piano = obj["display_piano"];
    if (obj.hasOwnProperty("display_fretboard"))
      this.display_fretboard = obj["display_fretboard"];
    if (obj.hasOwnProperty("novaxe_notation_on"))
      this.novaxe_notation_on = obj["novaxe_notation_on"];
    if (obj.hasOwnProperty("browse_dislplay_list"))
      this.browse_dislplay_list = obj["browse_dislplay_list"];
    if (obj.hasOwnProperty("letters_Number_sync"))
      this.letters_Numbers_sync = obj["letters_Number_sync"];
    if (obj.hasOwnProperty("displayNotesMode"))
      this.displayNotesMode = obj["displayNotesMode"];
    if (obj.hasOwnProperty("global_show_score_chords"))
      this.global_show_score_chords = obj["global_show_score_chords"];
    if (obj.hasOwnProperty("use_chromakey"))
      this.use_chromakey = obj["use_chromakey"];

    if (obj.hasOwnProperty('editor_visible')) this.editor_visible = obj['editor_visible'];
    if (obj.hasOwnProperty('metro_visible')) this.metro_visible = obj['metro_visible'];
    if (obj.hasOwnProperty('metroVolume')) this.metroVolume = obj['metroVolume'];
    if (obj.hasOwnProperty('dico_visible')) this.dico_visible = obj['dico_visible'];
    if (obj.hasOwnProperty('options_visible')) this.options_visible = obj['options_visible'];
    if (obj.hasOwnProperty('chordsBrowse_visible')) this.chordsBrowse_visible = obj['chordsBrowse_visible'];
    if (obj.hasOwnProperty('circle_visible')) this.circle_visible = obj['circle_visible'];
    if (obj.hasOwnProperty('fifths_visible')) this.fifths_visible = obj['fifths_visible'];
    if (obj.hasOwnProperty('braid_visible')) this.braid_visible = obj['braid_visible'];
    if (obj.hasOwnProperty('display_audio')) this.display_audio = obj['display_audio'];
    if (obj.hasOwnProperty('display_chordstrip')) this.display_chordstrip = obj['display_chordstrip'];
    if (obj.hasOwnProperty('tonalityLockedToScore')) this.tonalityLockedToScore = obj['tonalityLockedToScore'];
    if (obj.hasOwnProperty('braid_is_roman')) this.braid_is_roman = obj['braid_is_roman'];
    if (obj.hasOwnProperty('emphasis_chords')) this.emphasis_chords = obj['emphasis_chords'];
    if (obj.hasOwnProperty('emphasis_scale')) this.emphasis_scale = obj['emphasis_scale'];
    if (obj.hasOwnProperty('braid_simpler')) this.braid_simpler = obj['braid_simpler'];
    if (obj.hasOwnProperty('braid_one_tona')) this.braid_one_tona = obj['braid_one_tona'];
    if (obj.hasOwnProperty('midi_highlight_visible')) this.midi_highlight_visible = obj['midi_highlight_visible'];
    if (obj.hasOwnProperty('score_highlight_visible')) this.score_highlight_visible = obj['score_highlight_visible'];
    if (obj.hasOwnProperty('display_midi_chord')) this.display_midi_chord = obj['display_midi_chord'];
  }

  public hasLocalStorage(): boolean {
    if (!localStorage) {
      console.warn("Local Storage is not supported");
      return false;
    }

    if (!localStorage.getItem("options")) {
      // Check if migration from cookies is possible
      if (this.cookie.getObject("options")) {
        this.saveInLocalStorage();
        this.cookie.remove("options");
        console.log("Saved in local storage from cookie");
        return true;
      }
      console.warn("Local Storage is empty");
      return true;
    }
    return true;
  }

  public loadFromLocalStorage(): boolean {
    if (!localStorage.getItem("options")) {
      // console.warn("Local Storage is empty");
      return false;
    }
    // console.log("Loading from local storage");
    this.setOptionsFromObject(JSON.parse(localStorage.getItem("options")));
    return true;
  }

  public saveInLocalStorage(): void {
    localStorage.setItem("options", JSON.stringify(this.getOptionsAsObject()));
  }

  public isAutoScroll() {
    return this.autoScroll;
  }
  setAutoScroll(val: boolean) {
    this.autoScroll = val;
    this.saveInLocalStorage();
  }

  public isFollowOn() {
    return this.follow;
  }

  public setFollow(val: boolean) {
    this.follow = val;
    this.saveInLocalStorage();
  }

  public getDisplayNoteMode(): "numbers" | "letters" {
    // console.log("getDisplayNoteMode", this.displayNotesMode);
    return this.displayNotesMode;
  }

  public setDisplayNoteMode(mode: "numbers" | "letters") {
    // console.log("setDisplayNoteMode", mode);
    this.displayNotesMode = mode;
    this.saveInLocalStorage();
  }

  public getGlobalShowScoreChords(): boolean {
    return this.global_show_score_chords;
  }

  public setGlobalShowScoreChords(mode: boolean) {
    this.global_show_score_chords = mode;
    this.saveInLocalStorage();
  }

  public sendGlobalShowScoreChords() {
    this.global_show_score_chords_SUBJ.next(this.global_show_score_chords);
  }

  public sendDisplayNoteMode() {
    this.displayNotesMode_SUBJ.next(this.displayNotesMode);
  }

  public getDisplayMode(): "chords" | "analyse" | "both" {
    return this.displayMode;
  }
  public setDisplayMode(dm: "chords" | "analyse" | "both"): void {
    this.displayMode = dm;
    this.saveInLocalStorage();
  }

  public getMidiInputSelected(): string {
    return this.midi_input_selected;
  }

  public setMidiInputSelected(midiIn): void {
    this.midi_input_selected = midiIn;
    this.saveInLocalStorage();
  }

  public getControlInputSelected(): string {
    return this.control_input_selected;
  }

  public setControlInputSelected(midiIn): void {
    this.control_input_selected = midiIn;
    this.saveInLocalStorage();
  }

  public removeAssignedControl(midiNoteNb, midiControlNb) {
    try {
      this.assigned_controls[midiNoteNb].splice(midiControlNb, 1);
    } catch (e) {
      console.warn("Bad value in removeAssignedControl !");
      console.error(e);
    }
    this.saveInLocalStorage();
  }

  public addAssignedControl(
    midiControlNb: number,
    controlName: string,
    controlAction: string
  ) {
    // TODO CHECK FOR BAD ARGS !

    if (
      !this.assigned_controls[midiControlNb] ||
      !this.assigned_controls[midiControlNb].length
    )
      this.assigned_controls[midiControlNb] = [];

    this.assigned_controls[midiControlNb].push({
      number: midiControlNb,
      control_name: controlName,
      control_action: controlAction,
    });
    this.saveInLocalStorage();
  }

  public import_controls(controls) {
    this.assigned_controls = [];
    for (let tab of controls) {
      if (tab.length) {
        for (let c of tab) {
          if (
            c.hasOwnProperty("number") &&
            c.hasOwnProperty("control_name") &&
            c.hasOwnProperty("control_action")
          ) {
            if (!this.assigned_controls[c.number])
              this.assigned_controls[c.number] = [];
            this.assigned_controls[c.number].push(c);
          }
        }
      }
    }
    this.saveInLocalStorage();
  }

  public getAssignedControls(filtered: boolean = true) {
    if (filtered) return this.assigned_controls.filter((e) => e != null);
    else return this.assigned_controls;
  }

  public isTonalityLockedToScore(): boolean {
    return this.tonalityLockedToScore;
  }

  public setTonalityLockedToScore(val: boolean): void {
    this.tonalityLockedToScore = val;
    this.saveInLocalStorage();
  }

  public is_braid_roman(): boolean {
    return this.braid_is_roman;
  }

  public set_braid_roman(value: boolean): void {
    this.braid_is_roman = value;
    this.saveInLocalStorage();
  }

  public set_tonality_button_always_open(val: boolean): boolean {
    this.tonality_button_always_open = val;
    this.saveInLocalStorage();
    return val;
  }

  public get_tonality_button_always_open(): boolean {
    return this.tonality_button_always_open;
  }

  public set_novaxe_notation_on(val: boolean): boolean {
    this.novaxe_notation_on = val;
    this.saveInLocalStorage();
    return val;
  }

  public get_novaxe_notation_on(): boolean {
    return this.novaxe_notation_on;
  }

  public set_emphasis_chords(val: boolean): void {
    this.emphasis_chords = val;
    this.saveInLocalStorage();
  }

  public set_emphasis_scale(val: boolean): void {
    this.emphasis_scale = val;
    this.saveInLocalStorage();
  }

  public set_braid_simpler(val: boolean): void {
    this.braid_simpler = val;
    this.saveInLocalStorage();
  }

  public get_braid_simpler(val: boolean): boolean {
    return this.braid_simpler;
  }

  public get_emphasis_chords(): boolean {
    return this.emphasis_chords;
  }

  public get_emphasis_scale(): boolean {
    return this.emphasis_scale;
  }

  public getChromakey(): boolean {
    return this.use_chromakey;
  }
}
