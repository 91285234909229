import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserModel } from "@models/usermodel/usermodel";

import novaxeAPI from "src/app/utils/novaxeAPI";

@Component({
  selector: "app-validate",
  templateUrl: "./validate.component.html",
  styleUrls: ["./validate.component.scss"],
})
export class ValidateComponent implements OnInit {
  public modalInfos: any = {};
  public modalInfosApprove: any = {};

  public counter(i: number) {
    return new Array(i);
  }
  public list: Array<any> = [];

  constructor(public user: UserModel, public router: Router) {}

  ngOnInit(): void {
    if (!this.user.has_manage()) {
      this.router.navigate(["/"]);
      return;
    }

    this.modalInfos.hide = true;
    this.modalInfosApprove.hide = true;
    this.refreshScores();
  }

  async refreshScores() {
    const resp = await novaxeAPI<any[]>("GET", "apiList", {
      query: { unverified: "true" },
    });
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    this.list = resp?.data ?? [];
    console.log("this.list => ", this.list);
  }

  async approve(id: string) {
    const resp = await novaxeAPI<any>("POST", "apiApprove", { body: { id } });
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    this.refreshScores();
  }

  public openApproveModal(id: string) {
    this.modalInfosApprove.hide = false;
    const song = this.list[id];
    this.modalInfosApprove.id = song.id;
    this.modalInfosApprove.song = song;
    this.modalInfosApprove.title = song.title;
  }

  public closeApproveModal() {
    this.modalInfosApprove.hide = true;
  }

  public openDeleteModal(id: string) {
    this.modalInfos.hide = false;
    const song = this.list[id];
    this.modalInfos.id = song.id;
    this.modalInfos.song = song;
    this.modalInfos.title = song.title;
  }

  public closeDeleteModal() {
    this.modalInfos.hide = true;
  }

  public async delete(id: string) {
    const resp = await novaxeAPI<any>("POST", "apiDeleteSong", {
      body: { id },
    });
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    this.refreshScores();
  }

  public deleteScore() {
    this.delete(this.modalInfos.id);
    this.closeDeleteModal();
  }

  public approveScore() {
    this.approve(this.modalInfosApprove.id);
    this.closeApproveModal();
  }
}
