<section id="page-artist" class="d-flex flex-row">
	<div class="page page-artist col-sm-5">
		
		<div class="d-flex row w-100 align-items-center justify-content-center">
			<img [src]="img" [width]="img_w" [height]="img_h">
			<div class="d-flex flex-column p-5">
				<h2 class="title"><strong>{{artist}}</strong></h2>
				<h5 class="desc"></h5>
				<h6 [hidden]="sm.getListStyles().length==0"><i>Genre : <span *ngFor='let style of sm.getListStyles(); let i=index'>{{style}} </span></i></h6>
			</div>
		</div>

		<div class="extract"></div>
		<button class="btn btn-success btn-outline"><a [href]="'https://en.wikipedia.org/?curid='+artist_id" target="_blank">SEE FULL WIKIPEDIA PAGE</a></button>
	</div>

	<div class="d-flex flex-column w-100 col-sm-7">
		<app-browse [page]="'artist'" [param]="artist" [hidden]="loaded==false"></app-browse>

		<div class="top-tracks">
			<h5><span style="text-transform:capitalize;">{{artist}} </span>top tracks</h5>
			<table>
				<tr>
					<th></th>
					<th></th>
					<th>Title</th>
					<th>Album</th>
					<th>Duration</th> 
			    </tr>

				<tr *ngFor="let track of topTracks; let i=index">
					<td>{{i+1}}</td>
					<td>
						<img src="{{track.img}}" onerror="this.src='../../assets/img/empty_score.png'" alt="Album image" style="width:50px; height: 50px; object-fit: cover;">
					</td>
					<td>{{track.title}}</td>
					<td>{{track.album}}</td>
					<td>{{track.duration}}</td>
				</tr>
			</table>
		</div>
	</div>
	
</section>

