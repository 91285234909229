import { Component, OnInit } from '@angular/core';
import { FifthCircleComponent } from '@components/fifth-circle/fifth-circle.component';


@Component({
  selector: 'app-page-fifth-circle',
  templateUrl: './page-fifth-circle.component.html',
  styleUrls: ['./page-fifth-circle.component.scss']
})
export class PageFifthCircleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
