import {
  Component,
  OnInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ConfigModel } from "@models/configmodel/configModel";
import { Subscription } from "rxjs";
import { CHROMA_KEY_COLOR } from "src/app/utils/chromakey.constants";

declare var $: any;

@Component({
  selector: "svg[fretboardsvg]",
  templateUrl: "./fretboardsvg.component.svg",
})
export class FretboardSVGComponent implements OnInit {
  private contrast: boolean = false;
  private preChromaKeyColor: string;

  private use_chromakey_SUBJ_update$: Subscription;

  constructor(private elementRef: ElementRef, public cm: ConfigModel) {}

  ngOnInit() {
    // Get the first svg element in the component and replace the parent children with the svg children
    const svg = this.elementRef.nativeElement;
    const loadedSvg = svg.querySelector("svg");
    svg.innerHTML = loadedSvg.innerHTML;

    this.use_chromakey_SUBJ_update$ = this.cm.use_chromakey_update$.subscribe(
      (data) => {
        this.onContrast(data);
      }
    );
    this.onContrast(this.cm.use_chromakey);
  }

  onContrast(newValue: boolean) {
    this.contrast = newValue;
    console.log("Contrast: ", this.contrast);
    this.contrast ? this.enableContrast() : this.disableContrast();
  }

  enableContrast() {
    console.log("Enabling contrast");
    // Hide details
    const svg = this.elementRef.nativeElement;
    const details = svg.querySelector("#details");
    if (!details) return;
    details.style.display = "none";

    // Hide gradients
    const gradient1 = svg.querySelector("#color-gradient-1");
    const gradient2 = svg.querySelector("#color-gradient-2");
    if (!gradient1 || !gradient2) return;
    gradient1.style.display = "none";
    gradient2.style.display = "none";

    const rootColorPlane = svg.querySelector("#root-color-plane");
    if (!rootColorPlane) return;

    if (!this.preChromaKeyColor)
      this.preChromaKeyColor = rootColorPlane.style.fill;

    rootColorPlane.style.fill = CHROMA_KEY_COLOR;
  }

  disableContrast() {
    console.log("Disabling contrast");
    // Show details
    const svg = this.elementRef.nativeElement;
    const details = svg.querySelector("#details");
    if (!details) return;
    details.style.display = "";

    // Show gradients
    const gradient1 = svg.querySelector("#color-gradient-1");
    const gradient2 = svg.querySelector("#color-gradient-2");
    if (!gradient1 || !gradient2) return;
    gradient1.style.display = "";
    gradient2.style.display = "";

    const rootColorPlane = svg.querySelector("#root-color-plane");
    if (!rootColorPlane) return;

    rootColorPlane.style.fill = this.preChromaKeyColor;
  }
}
