import { Component, OnInit } from '@angular/core';

import novaxeAPI from 'src/app/utils/novaxeAPI';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  public counter(i: number) {return new Array(i);}
  public list:Array<any> = [];  

  constructor() { }

  ngOnInit(): void {
    this.refreshScores();
  }

  async refreshScores(){
    const resp = await novaxeAPI<any[]>('GET', 'apiList');
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    this.list = resp?.data ?? [];
    console.log("this.list => ",this.list);
  }

}
