import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import { Songmodel } from '@models/songmodel/songmodel';
import { SpotifyService } from '@services/spotify/spotify.service';
import { genericAPI } from 'src/app/utils/novaxeAPI';

@Component({
  selector: 'app-page-artist',
  templateUrl: './page-artist.component.html',
  styleUrls: ['./page-artist.component.scss']
})
export class PageArtistComponent implements OnInit {

  public artist:string = '';
  public artist_id:any;
  
  public img:string;
  public img_w:any;
  public img_h:any;

  private desc:string;
  private extract:any;

  public loaded:boolean = false;

  private artistId:string;
  public topTracks:Array<any> = [];

  constructor(private route:ActivatedRoute, public sm:Songmodel, private spot: SpotifyService) {

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      this.artist = paramMap.get('artist');
      this.loaded = true;
    })
    this.callWikiApiInfos();
    this.callWikiApiText();
    this.getSongItems();
  }

  ngOnDestroy(){
    this.loaded = false;
  }


  private async callWikiApiInfosUrl(url: string){
    const resp = await genericAPI<{
      pages: {
        key: string;
        description: string;
        thumbnail: {
          url: string;
          width: number;
          height: number;
        }
      }[]
    }>('GET', url);
    if (!resp.status || !resp.data) {
      console.error(resp.message);
      return;
    }

    return resp.data;
  }

  private async callWikiApiInfos(){
    let url2 = "https://en.wikipedia.org/w/rest.php/v1/search/page?q="+this.artist;
    let url1 = url2 + "_(band)" + "&limit=1";
    url2 += "&limit=1";


    let data = await this.callWikiApiInfosUrl(url1);
    if (!data || data.pages[0].key.toLowerCase() != (this.artist.replace(" ", "_")+'_(band)').toLowerCase()) data = await this.callWikiApiInfosUrl(url2);
    var result = data;
    if (!result) {
      console.error('Error getting infos');
      return;
    }

    this.desc = result.pages[0].description;
    this.img = result.pages[0].thumbnail.url;
    this.img_w = result.pages[0].thumbnail.width;
    this.img_h = result.pages[0].thumbnail.height;
    $(".desc").html(this.desc);
  }

  private async callWikiApiTextUrl(url: string){
    const resp = await genericAPI<{
        query: {
          pages: {
            extract: string;
            pageid: string;
          }
        }
      }>('GET', url);
    if (!resp) {
      console.error('Error getting text');
      return false;
    }

    return resp.data;
  }

  private async callWikiApiText(){

    const url2 = "https://en.wikipedia.org/w/api.php?format=json&action=query&origin=*&prop=extracts&exintro&explaintext&redirects=1&titles="+this.artist;
    const url1 = url2 + "_(band)";

    let data = await this.callWikiApiTextUrl(url1);
    if (!data || Object.keys(data?.query.pages)[0] == "-1") data = await this.callWikiApiTextUrl(url2);
    if (!data) {
      console.error('Error getting text');
      return;
    }
      this.extract = data?.query?.pages?.[0]?.extract;
      this.artist_id = data?.query?.pages?.[0]?.pageid;
      $(".extract").html('<p>'+this.extract.split('\n').join('</p><p>')+'</p>');
  }


  private async getSongItems(){

    var title = '';
    var album = '';
    var artist = this.artist;
    var comp = 'p-artist'

    const items = await this.spot.getItems({title:title, artist:artist, album:album, comp:comp});
    if (!items) {
      console.error('Error getting items');
      return;
    }

    this.artistId = items.artists.items[0].id;
    await this.getTopTracks();
  }


  private async getTopTracks(){
    const data = await this.spot.getTop({id:this.artistId});
    if (!data) {
      console.error('Error getting top tracks');
      return;
    }

    for (let i in data.tracks) {
      var obj = {
        title: data.tracks[i].name,
        album: data.tracks[i].album.name,
        img: data.tracks[i].album.images[0].url,
        duration: this.msToTime(data.tracks[i].duration_ms)
      };
      this.topTracks.push(obj);
    }
  }

  private msToTime(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0) as any;

    return minutes+':'+((seconds < 10) ? "0" : "")+seconds;
  }
    
}

