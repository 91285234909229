<div id="stats-page">
	<div class="wrapper">
		<div class="btns">
			<button type="button" class="btn btn-md btn-outline-success my-2 my-sm-2" (click)="analyse()" [hidden]="parts.length==0">Analyse</button>
			<button type="button" class="btn btn-md btn-outline-success my-2 my-sm-2" (click)="$event.stopPropagation()" [routerLink]="['/score/'+songId]" [hidden]="parts.length==0">View score</button>
		</div>

		<div class="d-flex row w-100 p-5 base align-items-center">
			<div class="col-md-4"><img [src]="this.sm.getInfos().getImage()" style="width:inherit;"></div>
			<div class="col-md-8">
				<h5 class="text-center">{{this.sm.getInfos().getArtist()}}</h5>
				<h2 class="text-center"><strong>{{this.sm.getInfos().getTitle()}}</strong></h2>

				<div class="row justify-content-center">

					<span class="text-center px-5 pt-5 pb-2 infos">Release Date: <strong>{{this.release_date}}</strong><br></span>
					<span class="text-center px-5 pt-5 pb-2 infos">Album: <strong>{{this.album_name}}</strong><br></span>
					<span class="text-center px-5 pt-5 pb-2 infos">Explicit: <strong>{{this.explicit==false?'No':'Yes'}}</strong><br></span>

				</div>

				<div class="row justify-content-center">
					<span class="d-flex flex-column">
						<span class="text-center px-5 pt-5 pb-2"><strong>{{(this.mode==1)?this.pitchClass[this.key]+' Major':this.pitchClass[this.key]+' Minor'}}</strong><br>KEY</span>
						<span class="text-center editorsInfos" [hidden]="this.matchingKeys">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							<strong>{{this.editorKey+' '+this.editorMode}}</strong>
							<br>EDITOR'S KEY 
						</span>
					</span>

					<span class="text-center px-5 pt-5 pb-2"><strong>{{this.tempo}} BPM</strong><br>TEMPO</span>

					<span class="text-center px-5 pt-5 pb-2"><strong>{{this.duration}}</strong><br>DURATION</span>

					<span class="d-flex flex-column">
						<span class="text-center px-5 pt-5 pb-2"><strong>{{this.signature}}/4</strong><br>SIGNATURE</span>
						<span class="text-center editorsInfos" [hidden]="this.matchingSignatures">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							<strong>{{this.sm.getInfos().getSignature()}}</strong>
							<br>EDITOR' SIGNATURE 
						</span>
					</span>
				</div>

			</div>
		</div>
		

		<div id="chartContainer_analysis" class="row pb-5">
			<div id="chartContainer_pop" class="col-sm-1 col-xs-2 doughnut" title="The popularity of the artist. The value will be between 0 and 100, with 100 being the most popular. The artist's popularity is calculated from the popularity of all the artist's tracks."></div>

			<div id="chartContainer_dance" class="col-sm-1 col-xs-2 doughnut" title="Danceability describes how suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity. A value of 0 is least danceable and 100 is most danceable."></div>

			<div id="chartContainer_energy" class="col-sm-1 col-xs-2 doughnut" title="Energy is a measure from 0 to 100 and represents a perceptual measure of intensity and activity. Typically, energetic tracks feel fast, loud, and noisy. For example, death metal has high energy, while a Bach prelude scores low on the scale. Perceptual features contributing to this attribute include dynamic range, perceived loudness, timbre, onset rate, and general entropy."></div>

			<div id="chartContainer_loud" class="col-sm-1 col-xs-2 doughnut" title="The overall loudness of a track in decibels (dB). Loudness values are averaged across the entire track and are useful for comparing relative loudness of tracks. Loudness is the quality of a sound that is the primary psychological correlate of physical strength (amplitude). Values typically range between -60 and 0 db."></div>

			<div id="chartContainer_speech" class="col-sm-1 col-xs-2 doughnut" title="Speechiness detects the presence of spoken words in a track. The more exclusively speech-like the recording (e.g. talk show, audio book, poetry), the closer to 100 the attribute value. Values above 66 describe tracks that are probably made entirely of spoken words. Values between 33 and 66 describe tracks that may contain both music and speech, either in sections or layered, including such cases as rap music. Values below 33 most likely represent music and other non-speech-like tracks."></div>

			<div id="chartContainer_acoustic" class="col-sm-1 col-xs-2 doughnut" title="A confidence measure from 0 to 100 of whether the track is acoustic. 100 represents high confidence the track is acoustic."></div>

			<div id="chartContainer_instru" class="col-sm-1 col-xs-2 doughnut" title="Predicts whether a track contains no vocals. 'Ooh' and 'aah'sounds are treated as instrumental in this context. Rap or spoken word tracks are clearly 'vocal'. The closer the instrumentalness value is to 100, the greater likelihood the track contains no vocal content. Values above 50 are intended to represent instrumental tracks, but confidence is higher as the value approaches 100."></div>

			<div id="chartContainer_live" class="col-sm-1 col-xs-2 doughnut" title="Detects the presence of an audience in the recording. Higher liveness values represent an increased probability that the track was performed live. A value above 80 provides strong likelihood that the track is live."></div>

			<div id="chartContainer_valence" class="col-sm-1 col-xs-2 doughnut" title="A measure from 0 to 100 describing the musical positiveness conveyed by a track. Tracks with high valence sound more positive (e.g. happy, cheerful, euphoric), while tracks with low valence sound more negative (e.g. sad, depressed, angry)."></div>
		</div>
		
		<div class="pt-5 px-5 w-100 text-left">
			<h2><strong>Statistics</strong></h2>
			<h5 class="mb-3">Chords played in '{{this.sm.getInfos().getTitle()}}' by {{this.sm.getInfos().getArtist()}}</h5>
			<div id="chartContainer_nbChords" class="general-chart" [hidden]="parts.length==0"></div>
			<div id="empty_stats" class="empty" [hidden]="parts.length!=0">There are no statistics for this song.</div>
			
			<div class="pchart-wrapper">
				<div *ngFor="let tona of partsTonas; let i=index" [hidden]="analysis[i].length==0" [attr.id]="'chartContainer_parts'+i" class="part-chart">
				</div>
			</div>
		</div>

		<div class="pt-5 px-5 w-100 text-left">
			<h2><strong>Recommended songs</strong></h2>
			<h5>The following tracks are close to {{this.sm.getInfos().getArtist()}} - '{{this.sm.getInfos().getTitle()}}' because they have similar tempos and keys.</h5>
			<table style="width: 100%; max-height: 300px; max-height: 75vh; overflow-y: auto; margin: 20px 0;">
				<tr>
					<th></th>
			     	<th></th> 
			      	<th class="text-center">Key</th>
			      	<th class="text-center">BPM</th>
			      	<th class="text-center">Duration</th>
			      	<th>Album</th>
			    </tr>
			    <tr *ngFor='let reco of songsRecomended; let i=index' class="reco">
			      <td>
			        <img src="{{reco.image}}" onerror="this.src='../../assets/img/empty_score.png'" alt="Album image" style="width:100px; height: 100px; object-fit: cover;">
			      </td>
			      <td><strong>{{reco.artist}}</strong><br>{{reco.title}}</td>
			      <td class="text-center">{{this.pitchClass[reco.key]}}<br>{{(reco.mode==1)?'Major':'Minor'}}</td>
			      <td class="text-center">{{reco.tempo}}</td>
			      <td class="text-center">{{reco.duration}}</td>
			      <td>{{reco.album}}</td>
			    </tr>
		    </table>
		</div>
	</div>
</div>
