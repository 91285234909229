import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfigModel } from '@models/configmodel/configModel';
import { Subscription } from 'rxjs';
import { CHROMA_KEY_COLOR } from 'src/app/utils/chromakey.constants';

declare var $: any;

@Component({
  selector: 'app-midi-chord-display',
  templateUrl: './midi-chord-display.component.html',
  styleUrls: ['./midi-chord-display.component.scss']
})
export class MidiChordDisplayComponent implements OnInit {

  @Input() set display( val:boolean ){
    this.visible = val;
  }

  @Input() set midi_chord( val:boolean ){
    this.cur_midi_chord = val;
  }

  @Input() set midi_abc( val:boolean ){
    this.cur_midi_abc = val;
  }

  @Output() visibleState = new EventEmitter<boolean>();

  public visible:boolean = false;

  public cur_midi_chord:any ;
  public cur_midi_abc:any = null;

  private use_chromakey_SUBJ_update$: Subscription;
  
  constructor( public cm:ConfigModel) { }

  ngAfterViewInit(){
    $("#midi-chord-display").draggable({
      handle: "#nvxMidi-chord-display"
    });
  }
  
  ngOnInit(): void {
    this.use_chromakey_SUBJ_update$ = this.cm.use_chromakey_update$.subscribe(
      (data) => {
        this.onContrast(data);
      }
    );
    this.onContrast(this.cm.use_chromakey);
  }

  private contrast: boolean = false;

  onContrast(newValue: boolean) {
    this.contrast = newValue;
    console.log("Contrast: ", this.contrast);
    this.contrast ? this.enableContrast() : this.disableContrast();
  }

  enableContrast() {
    const section = document.getElementById("midi-chord-display");
    if (!section) return;
    section.style.backgroundColor = CHROMA_KEY_COLOR;
  }

  disableContrast() {
    const section = document.getElementById("midi-chord-display");
    if (!section) return;
    section.style.backgroundColor = "";
  }

  public close(){
    this.visible = false;
    this.visibleState.emit(false)
  }

  public outputVisible(state:boolean){
    this.visibleState.emit(state);
  }

}
