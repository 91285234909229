import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { UserModel } from '@models/usermodel/usermodel';
import { ConfigModel } from '@models/configmodel/configModel';
import { Songmodel } from '@models/songmodel/songmodel';

import novaxeAPI from 'src/app/utils/novaxeAPI';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})

export class BrowseComponent implements OnInit {


	@Input()
	page: string;

	@Input()
	param: string;

	public list:Array<any> = [];	
	public analysisPatrn:string = '';

	public modalInfos:any={};

	public searchPattrn:any={};
	public isList:boolean;

  constructor(public user:UserModel, public cm:ConfigModel, private sm:Songmodel ) { }

  ngOnInit() {
		this.cleanSearch();
		this.setPageInputs();
		this.modalInfos.hide = true;
		this.isList = this.cm.browse_dislplay_list;
  }

  private async refreshScores(){
    const resp = await novaxeAPI<any[]>('GET', 'apiList');
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    this.list = resp?.data ?? [];
    console.log("this.list => ",this.list);
  }

  public async deleteScore(){
    const id:any = this.modalInfos['id'];
    if (!id) {
      console.error('No ID to delete');
      return;
    }

    const resp = await novaxeAPI('POST', 'apiDeleteSong', { body: { id } });
    if (!resp.status) {
      console.error(resp.message);
      await this.refreshScores();
      this.modalInfos.hide = true;
      return;
    }

    await this.refreshScores();
    this.modalInfos.hide = true;
    console.log('delete Complete');
  }


  public async search(){
    if(this.searchPattrn.title == '' && this.searchPattrn.artist == '' && this.searchPattrn.author == '' && this.searchPattrn.album == '' &&
        this.searchPattrn.style == '' && this.searchPattrn.chords == '' && this.searchPattrn.analysis == '' && this.searchPattrn.tonality == '' ) {
      await this.refreshScores();
      return;
    }

		let obj:object = {
			pattrnTitle:this.searchPattrn['title'],
			pattrnArtist:this.searchPattrn['artist'],
			pattrnAlbum:this.searchPattrn['album'],
			pattrnAuthor:this.searchPattrn['author'],
			pattrnKey:this.searchPattrn['tonality'],
			pattrnStyle:this.searchPattrn['style'],
			pattrnChords:this.searchPattrn['chords'],
		};

		console.log('obj => ',obj);

    const resp = await novaxeAPI<any>('POST', 'apiSearchAnalysis', { body: obj });
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    this.list = resp.data;
    this.listStyles();
  }

  public openDeleteModal(index:number){
    event.stopPropagation();
    this.modalInfos.id = this.list[index].id;
    this.modalInfos.title 	= this.list[index].title;	
    this.modalInfos.hide 	= false;
  }

  public cleanSearch(){
    this.searchPattrn.title='';
    this.searchPattrn.artist='';
    this.searchPattrn.author='';
    this.searchPattrn.album='';
    this.searchPattrn.style='';
    this.searchPattrn.chords='';
    this.searchPattrn.analysis='';
    this.searchPattrn.tonality='';

    this.refreshScores();
  }

  private setPageInputs(){
		if(this.page != 'home'){
			switch(this.page){
				case('title'):
					this.searchPattrn.title=this.param;
				break;
				case('artist'):
					this.searchPattrn.artist=this.param;
				break;
				case('author'):
					this.searchPattrn.author=this.param;
				break;
				case('album'):
					this.searchPattrn.album=this.param;
				break;
				case('style'):
					this.searchPattrn.style=this.param;
				break;
				case('key'):
					this.searchPattrn.tonality=this.param;
				break;
				// case('chords'):
				// 	this.searchPattrn.chords=this.param;
				// break;
				default:
				break;
			}
			this.search();
		}
		else
  		this.refreshScores();
  }


  private listStyles(){
  	var styles = [];
  	for (let i=0; i<this.list.length; i++){

  		var str = this.list[i]['style'].replaceAll(/\/| - /g,' ');
  		str = str.trim();
  		str = str.split(' ');

  		for(let j=0; j<str.length; j++){
  			styles.push(str[j]);
  		}
  	}
  	let uniqueStyles = [...new Set(styles)];
  	uniqueStyles = uniqueStyles.filter(e => e !== 'unknown');
  	uniqueStyles = uniqueStyles.filter(e => e !== '');

  	this.sm.setListStyles(uniqueStyles);
  }


}
