  <div id="abcCanvas"> </div>  

  <ng-container *ngIf="midi.MIDI_AVAILABLE == true">
    <p > pre : {{prevMidiNotes}}</p>
    <p > Ans : {{midiAnswer}}</p>
  </ng-container>

<ng-container *ngIf="midi.MIDI_AVAILABLE == false">
	<button class="btn btn-primary" (click)="setNote('C')"> C  </button>
	<button class="btn btn-primary" (click)="setNote('D')"> D  </button>
	<button class="btn btn-primary" (click)="setNote('E')"> E  </button>
	<button class="btn btn-primary" (click)="setNote('F')"> F  </button>
	<button class="btn btn-primary" (click)="setNote('G')"> G  </button>
	<button class="btn btn-primary" (click)="setNote('A')"> A  </button>
	<button class="btn btn-primary" (click)="setNote('B')"> B  </button>

	<br>

	<button class="btn btn-primary" (click)="setAlt('#')"> #  </button>
	<button class="btn btn-primary" (click)="setAlt('b')"> b  </button>
	<button class="btn btn-primary" (click)="setAlt('')"> ♮  </button>

	<br>

	<button class="btn btn-primary" (click)="setType('')"> Major  </button>
	<button class="btn btn-primary" (click)="setType('m')"> Minor  </button>
	<button class="btn btn-primary" (click)="setType('7')"> Dominant  </button>
	<button class="btn btn-primary" (click)="setType('m7')"> Minor 7  </button>
	<button class="btn btn-primary" (click)="setType('maj7')"> Maj7  </button>
	<button class="btn btn-primary" (click)="setType('o')"> Diminished  </button>
	<button class="btn btn-primary" (click)="setType('o7')"> Diminished seventh  </button>
	<button class="btn btn-primary" (click)="setType('m7b5')"> m7b5</button>
	<button class="btn btn-primary" (click)="setType('+')"> Augmented  </button>

	<br>
	<br>
</ng-container>

<button class="btn btn-primary" (click)="validate()"> OK  </button>


<pre> <code> {{receivedData}} </code> </pre>


