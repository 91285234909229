<div class="menus">

    <select class="w-auto form-control" [(ngModel)]="scale_tona" (change)="refresh_scale()" id="tona">
        <option [ngValue]="'C#'">C#</option>
        <option [ngValue]="'F#'">F#</option>
        <option [ngValue]="'B'">B</option>
        <option [ngValue]="'E'">E</option>
        <option [ngValue]="'A'">A</option>
        <option [ngValue]="'D'">D</option>
        <option [ngValue]="'G'">G</option>
        <option [ngValue]="'C'">C</option>
        <option [ngValue]="'F'">F</option>
        <option [ngValue]="'Bb'">Bb</option>
        <option [ngValue]="'Eb'">Eb</option>
        <option [ngValue]="'Ab'">Ab</option>
        <option [ngValue]="'Db'">Db</option>
        <option value="Gb">Gb</option>
    </select>

    <select class="w-auto form-control" style="text-transform: capitalize;" [(ngModel)]="scale_type" (change)="refresh_scale()" name="scales_selector" id="scales">
        
        <option [ngValue]="'major'">major</option>
        <option [ngValue]="'minor'">minor</option>

        <option disabled>_________</option>

        <option [ngValue]="'dorian'">dorian</option>
        <option [ngValue]="'phrygian'">phrygian</option>
        <option [ngValue]="'lydian'">lydian</option>
        <option [ngValue]="'mixolydian'">mixolydian</option>
        <option [ngValue]="'aeolian'">aeolian</option>
        <option [ngValue]="'locrian'">locrian</option>

        <option disabled>_________</option>

        <option [ngValue]="'major pentatonic'">major pentatonic</option>
        <option [ngValue]="'major blues'">major blues</option>
        <option [ngValue]="'minor pentatonic'">minor pentatonic</option>
        <option [ngValue]="'minor blues'">minor blues</option>

        <option disabled>_________</option>
        
        <option [ngValue]="'harmonic major'">harmonic major</option>
        <option [ngValue]="'harmonic minor'">harmonic minor</option>

        <option disabled>_________</option>

        <option [ngValue]="'diminished'">diminished</option>
        <option [ngValue]="'minor six diminished'">minor six diminished</option>
        <option [ngValue]="'half-whole diminished'">half-whole diminished</option>
        <option [ngValue]="'composite blues'">composite blues</option>

        <option disabled>_________</option>

        <option [ngValue]="'bebop'">bebop</option>
        <option [ngValue]="'bebop minor'">bebop minor</option>
        <option [ngValue]="'bebop major'">bebop major</option>
        <option [ngValue]="'bebop locrian'">bebop locrian</option>
        
        <option disabled>_________</option>
        
        <option [ngValue]="'ionian pentatonic'">ionian pentatonic</option>
        <option [ngValue]="'mixolydian pentatonic'">mixolydian pentatonic</option>
        <option [ngValue]="'ritusen'">ritusen</option>
        <option [ngValue]="'egyptian'">egyptian</option>
        <option [ngValue]="'neopolitan major pentatonic'">neopolitan major pentatonic</option>
        <option [ngValue]="'vietnamese 1'">vietnamese 1</option>
        <option [ngValue]="'pelog'">pelog</option>
        <option [ngValue]="'kumoijoshi'">kumoijoshi</option>
        <option [ngValue]="'hirajoshi'">hirajoshi</option>
        <option [ngValue]="'iwato'">iwato</option>
        <option [ngValue]="'in-sen'">in-sen</option>
        <option [ngValue]="'lydian pentatonic'">lydian pentatonic</option>
        <option [ngValue]="'malkos raga'">malkos raga</option>
        <option [ngValue]="'locrian pentatonic'">locrian pentatonic</option>
        <option [ngValue]="'minor six pentatonic'">minor six pentatonic</option>
        <option [ngValue]="'flat three pentatonic'">flat three pentatonic</option>
        <option [ngValue]="'flat six pentatonic'">flat six pentatonic</option>
        <option [ngValue]="'scriabin'">scriabin</option>
        <option [ngValue]="'whole tone pentatonic'">whole tone pentatonic</option>
        <option [ngValue]="'lydian #5P pentatonic'">lydian #5P pentatonic</option>
        <option [ngValue]="'lydian dominant pentatonic'">lydian dominant pentatonic</option>
        <option [ngValue]="'minor #7M pentatonic'">minor #7M pentatonic</option>
        <option [ngValue]="'super locrian pentatonic'">super locrian pentatonic</option>
        <option [ngValue]="'minor hexatonic'">minor hexatonic</option>
        <option [ngValue]="'augmented'">augmented</option>
        <option [ngValue]="'piongio'">piongio</option>
        <option [ngValue]="'prometheus neopolitan'">prometheus neopolitan</option>
        <option [ngValue]="'prometheus'">prometheus</option>
        <option [ngValue]="'mystery #1'">mystery #1</option>
        <option [ngValue]="'six tone symmetric'">six tone symmetric</option>
        <option [ngValue]="'whole tone'">whole tone</option>
        <option [ngValue]="'messiaen\'s mode #5'">messiaen's mode #5</option>
        <option [ngValue]="'locrian major'">locrian major</option>
        <option [ngValue]="'double harmonic lydian'">double harmonic lydian</option>
        <option [ngValue]="'altered'">altered</option>
        <option [ngValue]="'locrian #2'">locrian #2</option>
        <option [ngValue]="'mixolydian b6'">mixolydian b6</option>
        <option [ngValue]="'lydian dominant'">lydian dominant</option>
        <option [ngValue]="'lydian augmented'">lydian augmented</option>
        <option [ngValue]="'dorian b2'">dorian b2</option>
        <option [ngValue]="'melodic minor'">melodic minor</option>
        <option [ngValue]="'ultralocrian'">ultralocrian</option>
        <option [ngValue]="'locrian 6'">locrian 6</option>
        <option [ngValue]="'augmented heptatonic'">augmented heptatonic</option>
        <option [ngValue]="'dorian #4'">dorian #4</option>
        <option [ngValue]="'lydian diminished'">lydian diminished</option>
        <option [ngValue]="'leading whole tone'">leading whole tone</option>
        <option [ngValue]="'lydian minor'">lydian minor</option>
        <option [ngValue]="'phrygian dominant'">phrygian dominant</option>
        <option [ngValue]="'balinese'">balinese</option>
        <option [ngValue]="'neopolitan major'">neopolitan major</option>
        <option [ngValue]="'double harmonic major'">double harmonic major</option>
        <option [ngValue]="'hungarian minor'">hungarian minor</option>
        <option [ngValue]="'hungarian major'">hungarian major</option>
        <option [ngValue]="'oriental'">oriental</option>
        <option [ngValue]="'flamenco'">flamenco</option>
        <option [ngValue]="'todi raga'">todi raga</option>
        <option [ngValue]="'persian'">persian</option>
        <option [ngValue]="'enigmatic'">enigmatic</option>
        <option [ngValue]="'major augmented'">major augmented</option>
        <option [ngValue]="'lydian #9'">lydian #9</option>
        <option [ngValue]="'purvi raga'">purvi raga</option>
        <option [ngValue]="'spanish heptatonic'">spanish heptatonic</option>
        <option [ngValue]="'minor bebop'">minor bebop</option>
        <option [ngValue]="'ichikosucho'">ichikosucho</option>
        <option [ngValue]="'kafi raga'">kafi raga</option>
        <option [ngValue]="'messiaens mode #6'">messiaen's mode #6</option>
        <option [ngValue]="'messiaens mode #4'">messiaen's mode #4</option>
        <option [ngValue]="'messiaens mode #3'">messiaen's mode #3</option>
        <option [ngValue]="'messiaens mode #7'">messiaen's mode #7</option>
        <option [ngValue]="'chromatic'">chromatic</option>
    </select>

    <span class="caged">
        <div [class.active]="caged_position.indexOf('C') != -1" (click)="set_caged_position('C')" class="caged_btn"><span>C</span> </div> 
        <div [class.active]="caged_position.indexOf('A') != -1" (click)="set_caged_position('A')" class="caged_btn"><span>A</span> </div> 
        <div [class.active]="caged_position.indexOf('G') != -1" (click)="set_caged_position('G')" class="caged_btn"><span>G</span> </div> 
        <div [class.active]="caged_position.indexOf('E') != -1" (click)="set_caged_position('E')" class="caged_btn"><span>E</span> </div> 
        <div [class.active]="caged_position.indexOf('D') != -1" (click)="set_caged_position('D')" class="caged_btn"><span>D</span> </div> 
    </span>
