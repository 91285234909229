<div id="exercice-rythm">

  <div [class.hidden]="!debug_mode">
    <ng-container >
      <input type="checkbox" name="sound" [(ngModel)]="sound" (change)="set_mute()">
      <label for="sound">Sound</label>

      <div>
        <label for="#input_right">Right hand</label>
        <input id="input_right" class="text_input" type="text" [(ngModel)]="right_hand_string" (input)="update_score()" (change)="update_score()">
      </div> 
      <div>
        <label for="#input_left">Left hand</label>
        <input id="input_left" class="text_input" type="text" [(ngModel)]="left_hand_string" (input)="update_score()" (change)="update_score()">
      </div>  

      <label for="#input_transpose">Transpose </label>
       <input id="input_transpose" type="number" min="-6" max="6" [(ngModel)]="transpose" (change)="update_score()">

      <label for="#input_tempo">Tempo </label>
       <input id="input_tempo" type="number" min="50" max="160" [(ngModel)]="tempo" (change)="change_tempo()">
    </ng-container>
  </div>
 

  <div id="abcjs-wrapper">
    <div id="abcjs-paper" width="50vw"> </div>
  </div>

  <div [hidden]="!debug_mode" id="synthControl" ></div>

  <!-- <app-metro class="metronome"></app-metro> -->
  <!-- <app-fifth-circle [chord]="cur_chord" class="fifth_circle"></app-fifth-circle> -->

</div>
