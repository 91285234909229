import { Injectable } from '@angular/core';

import { Note, Chord, Scale, Key, AbcNotation, Interval, Progression, RomanNumeral } from "@tonaljs/tonal";
export const N = Note;
export const C = Chord;
export const S = Scale;
export const K = Key;
export const A = AbcNotation;
export const I = Interval;
export const P = Progression;
export const R = RomanNumeral;

@Injectable({
  providedIn: 'root'
})
export class ChordGenService {

  constructor() { }

  public get_rand_in_obj(obj){

    let tab = [];
    for(let k of Object.keys(obj))
      if( obj[k] == true ) tab.push(k);

    let n = Math.floor( Math.random()*tab.length );

    return tab[n];
  }

  public get_random_chord(tona, mode, clef:string="G",chords_gen_params){

    let inv     = this.get_rand_in_obj(chords_gen_params.inversions);
    // console.log("mode => ",mode);
    // let c_type = "major";
    // debugger
    let chord_types;
    switch( mode ){
      case 'major':
        chord_types = chords_gen_params.chord_types_major;
      break;

      case 'h_minor':
        chord_types = chords_gen_params.chord_types_h_minor;
      break;

      case 'n_minor':
        chord_types = chords_gen_params.chord_types_n_minor;
      break;

      case 'm_minor':
        chord_types = chords_gen_params.chord_types_m_minor;
      break;

      default : 
       chord_types = chords_gen_params.chord_types_major;
    }

    let c_type  = this.get_rand_in_obj(chord_types);

    // console.clear();
    // console.log('tona => ',tona, mode);
    // console.log('c_type => ',c_type);
    // let a = Key.majorKey(tona)
    // console.log("a => ",a);
    // debugger

    let key,chords, possible_chords, possible_degrees;
    if( mode == "major"){
      key   = Key.majorKey(tona);
      chords = key.chords;

      switch(c_type){
        case 'major':
          possible_chords = [ key.scale[0],key.scale[3],key.scale[4] ];
          possible_degrees = [ "I","IV", "V" ];
        break;

        case 'minor':
          possible_chords = [ key.scale[1]+'m', key.scale[2]+'m', key.scale[5]+'m' ];
          possible_degrees = [ "ii","iii", "vi" ];
        break;

        case 'dominant':
          possible_chords = [ chords[4], key.secondaryDominants[1], key.secondaryDominants[2], key.secondaryDominants[3], key.secondaryDominants[4], key.secondaryDominants[5] ];
          possible_degrees = [ "V7 of I","V7 of ii", "V7 of iii","V7 of IV","V7 of V","V7 of vi" ];
        break;

        case 'minor7':
          possible_chords = [ chords[1], chords[2], chords[5] ];
          possible_degrees = [ "ii","iii", "vi" ];
        break;

        case 'maj7':
          possible_chords = [chords[0], chords[3] ];
          possible_degrees = [ "I","IV"];
        break;

        case 'dim':
          possible_chords = [key.scale[6]+'o'];
          possible_degrees = [ "VII"];
        break;

        case 'dim7':
          possible_chords = [];
        break;


        case 'hdim':
          possible_chords = [key.scale[6]+'m7b5'];
          possible_degrees = [ "VII"];
        break;

        case 'aug':
          possible_chords = [];
        break;

        default :
          possible_chords = [ key.scale[0],key.scale[3],key.scale[4] ];
          possible_degrees = [ "I","IV", "V" ];
        break;

      }
    }else if( mode == "h_minor" ){

      key   = Key.minorKey(tona);
      let keyMajor = Key.majorKey(tona);

      chords = key['harmonic'].chords; 

      switch(c_type){
        case 'major':
          possible_chords = [ chords[4].replace('7',''), chords[5].replace('maj7','') ];
          possible_degrees = [ "V", "VI" ];
        break;

        case 'minor':
          possible_chords = [ chords[0].replace('Maj7',''), chords[3].replace('7','') ];
          possible_degrees = [ "i", "iv" ];
        break;

        case 'dominant':
          possible_chords = [ chords[4], keyMajor.secondaryDominants[1], keyMajor.secondaryDominants[2], keyMajor.secondaryDominants[3], keyMajor.secondaryDominants[4], keyMajor.secondaryDominants[5] ];
          possible_degrees = [ "V7 of I","V7 of ii", "V7 of iii","V7 of IV","V7 of V","V7 of vi" ];
        break;

        case 'minor7':
          possible_chords = [ chords[3] ];
          possible_degrees = [ "ivm7" ];
        break;

        case 'maj7':
          possible_chords = [ chords[5] ];
          possible_degrees = [ "VI" ];
        break;

        case 'dim':
          possible_chords = [ chords[1].replace('m7b5','o'), chords[6].replace('o7','o') ];
          possible_degrees = [ "iio", "viio" ];
        break;

        case 'dim7':
          possible_chords = [ chords[6] ];
          possible_degrees = [ "viio7" ];

        break;

        case 'hdim':
          possible_chords = [ chords[1] ];
          possible_degrees = [ "iim7b5" ];
          // possible_chords = [ key['harmonic'].scale[1]+'m7b5'];
        break;

        case 'aug':
          possible_chords = [ chords[2].replace('maj7','') ];
          possible_degrees = [ "III+" ];
          // possible_chords = [ chords[2] ];
        break;

        case 'mMaj7':
          possible_chords = [ chords[0] ];
          possible_degrees = [ "ImMaj7" ];
          // possible_chords = [ chords[2] ];
        break;

        case 'augMaj7':
          possible_chords = [ chords[2] ];
          possible_degrees = [ "III+Maj7" ];
          // possible_chords = [ chords[2] ];
        break;

        default :
          possible_chords = [ chords[4].replace('7',''), chords[5].replace('maj7','') ];
          possible_degrees = [ "V", "VI" ];
        break;

      }

    }else if( mode == "n_minor" ){

      key   = Key.minorKey(tona);
      let keyMajor = Key.majorKey(tona);

      chords = key['natural'].chords; 

      // debugger
      switch(c_type){
        case 'major':
          possible_chords = [ chords[2].replace('maj7',''), chords[5].replace('maj7',''), chords[6].replace('7','') ];
          possible_degrees = [ "III", "VI", "VII" ];
        break;

        case 'minor':
          possible_chords = [ chords[0].replace('7','') ,chords[3].replace('7',''), chords[4].replace('7','') ];
          possible_degrees = [ "i", "iv", "v" ];
        break;

        case 'dominant':
          possible_chords = [ chords[4], keyMajor.secondaryDominants[1], keyMajor.secondaryDominants[2], keyMajor.secondaryDominants[3], keyMajor.secondaryDominants[4], keyMajor.secondaryDominants[5] ];
          possible_degrees = [ "V7 of I","V7 of ii", "V7 of iii","V7 of IV","V7 of V","V7 of vi" ];
          // debugger
        break;

        case 'minor7':
          possible_chords = [ chords[0],chords[3], chords[4] ];
          possible_degrees = [ "i", "iv", "v" ];
        break;

        case 'maj7':
          possible_chords = [ chords[2].replace('maj7',''), chords[5].replace('maj7','') ];
          possible_degrees = [ "III", "VI" ];
        break;

        case 'dim':
          possible_chords = [ chords[1].replace('m7b5','dim') ];
          possible_degrees = [ "iio" ];
          // possible_chords = [key['harmonic'].scale[6]+'o'];
        break;

        case 'dim7':
          // possible_chords = [ chords[6] ];
          // possible_degrees = [ "viio7" ];

        break;

        case 'hdim':
          possible_chords = [ chords[1] ];
          possible_degrees = [ "iim7b5" ];
          // possible_chords = [ key['harmonic'].scale[1]+'m7b5'];
        break;

        case 'aug':
          // possible_chords = [ chords[2] ];
          // possible_degrees = [ "III+" ];
          // possible_chords = [ chords[2] ];
        break;

        case 'mMaj7':
          // possible_chords = [ chords[0] ];
          // possible_degrees = [ "ImMaj7" ];
          // possible_chords = [ chords[2] ];
        break;

        case 'augMaj7':
          possible_chords = [ chords[2] ];
          possible_degrees = [ "III+Maj7" ];
          // possible_chords = [ chords[2] ];
        break;

        default :
          possible_chords = [ chords[2].replace('maj7',''), chords[5].replace('maj7',''), chords[6].replace('7','') ];
          possible_degrees = [ "III", "VI", "VII" ];
        break;

      }
    }else if( mode == "m_minor" ){

      key   = Key.minorKey(tona);
      let keyMajor = Key.majorKey(tona);

      chords = key['melodic'].chords; 

      // debugger
      switch(c_type){
        case 'major':
          possible_chords = [ chords[3].replace('7',''), chords[4].replace('7','') ];
          possible_degrees = [ "IV", "V" ];
        break;

        case 'minor':
          possible_chords = [ chords[0].replace('6','') ,chords[1].replace('7','')  ];
          possible_degrees = [ "im", "iim" ];
        break;

        case 'dominant':
          possible_chords = [ chords[4], keyMajor.secondaryDominants[1], keyMajor.secondaryDominants[2], keyMajor.secondaryDominants[3], keyMajor.secondaryDominants[4], keyMajor.secondaryDominants[5] ];
          possible_degrees = [ "V7 of I","V7 of ii", "V7 of iii","V7 of IV","V7 of V","V7 of vi" ];
          // debugger
        break;

        case 'minor7':
          possible_chords = [ chords[0] ,chords[1] ];
          possible_degrees = [ "im7", "iim7" ];
        break;

        case 'maj7':
          possible_chords = [ chords[5] ];
          possible_degrees = [ "VI" ];
        break;

        case 'dim':
          possible_chords = [ chords[5].replace('m7b5','o'), chords[6].replace('m7b5','o') ];
          possible_degrees = [ "vio", "viio" ];
        break;

        case 'dim7':
          possible_chords = [ chords[6] ];
          possible_degrees = [ "viio7" ];

        break;

        case 'hdim':
          possible_chords = [ chords[5], chords[6] ];
          possible_degrees = [ "vim7b5", "viiom7b5" ];

        break;

        case 'aug':
          possible_chords = [ chords[2].replace('maj7','') ];
          possible_degrees = [ "III+" ];
        break;

        case 'mMaj7':
          possible_chords = [ chords[0] ];
          possible_degrees = [ "ImMaj7" ];
        break;

        case 'augMaj7':
          possible_chords = [ chords[2] ];
          possible_degrees = [ "III+Maj7" ];
          // possible_chords = [ chords[2] ];
        break;

        default :
          possible_chords = [ chords[3].replace('7',''), chords[4].replace('7','') ];
          possible_degrees = [ "IV", "V" ];
        break;

      }
    }

    let r = Math.floor( Math.random()*possible_chords.length );

    let final_chord = possible_chords[r];
    let final_degree = possible_degrees[r];

    let c = Chord.get(final_chord);

    // debugger

    let root = '';
    switch (inv) {
      case "_one":
        root = c.notes[0];
        break;
      case "_two":
        root = c.notes[1];
        break;
      case "_three":
        root = c.notes[2];
        break;
      case "_four":
        if(c.notes.length>3) root = c.notes[3];
        else root = c.notes[0];
        break;
      default:
        root = c.notes[0];
        break;
    }

    if(clef=="G")c = Chord.getChord(c.aliases[0], c.tonic+"4", root+"4");
    else if(clef=="F")c = Chord.getChord(c.aliases[0], c.tonic+"2", root+"2");

    // Transform notes into abc notation.
    // AbcNotation.scientificToAbcNotation
    let abc_string = '';
    let notes_array = [];

    for(let n of c.notes){ // for each note

      let abc_notation = AbcNotation.scientificToAbcNotation(n);

      //if the note has a # or a b and it is in the armor : we remove # or b 
        let pitch = n.match(/([ABCDEFG][#b]?)/g);

        let sc;
        if(key.hasOwnProperty('harmonic'))
          sc = key['natural'].scale;
        else sc = key.scale;

        if( sc.indexOf(pitch[0]) >= 0 ) { //if pitch of note is in the scale

          abc_notation = abc_notation.replace("^","").replace("_",""); //remove # or b

        }else if( sc.indexOf( pitch[0]) == -1 ){

          if( sc.indexOf(pitch[0][0]+"b" )>-1 || sc.indexOf(pitch[0][0]+"#" )>-1 ){
            abc_notation='='+abc_notation;
          }
        }
      //-----------

      notes_array.push(n);
      abc_string+= abc_notation;
    }
    // let b = Chord.getChord(final_chord,"Bb",root);
    // console.log('Chord => ',Chord);
      // debugger
    let out = { 
      entity_type:"chord",
      tonality:tona,
      inv: inv, 
      mode:mode, 
      c_type:c_type, 
      notes_array:notes_array, 
      chord_name:final_chord, 
      chord_degree:final_degree, 
    };

    // console.log('final_chord => ',final_chord);
    // console.log('out => ',out);
    // debugger
    // console.log('out => ',out);
    return out;
  }


  public get_chord(chord, inv:string=null, tona:string="C", mode:string="major", clef:string="G", chords_gen_params){

    if(!inv) inv     = this.get_rand_in_obj(chords_gen_params.inversions);

    let c = C.get(chord);

    let root = '';
    switch (inv) {
      case "_one":
        root = c.notes[0];
        break;
      case "_two":
        root = c.notes[1];
        break;
      case "_three":
        root = c.notes[2];
        break;
      case "_four":
        if(c.notes.length>3) root = c.notes[3];
        else root = c.notes[0];
        break;
      default:
        root = c.notes[0];
        break;
    }

    if(clef=="G")c = Chord.getChord(c.aliases[0], c.tonic+"4", root+"4");
    else if(clef=="F")c = Chord.getChord(c.aliases[0], c.tonic+"2", root+"2");

    // Transform notes into abc notation.
    // AbcNotation.scientificToAbcNotation
      let key;
      if( mode == "major"){
        key   = Key.majorKey(tona);
      }else if( mode == "m_minor" ){
        key   = Key.minorKey(tona).melodic;
        let keyMajor = Key.majorKey(tona);
      }else if( mode == "n_minor" ){
        key   = Key.minorKey(tona).natural;
        let keyMajor = Key.majorKey(tona);
      }else if( mode == "h_minor" ){
        key   = Key.minorKey(tona).harmonic;
        let keyMajor = Key.majorKey(tona);
      }

      let abc_string = '';
      let notes_array = [];

      for(let n of c.notes){ // for each note

        let abc_notation = AbcNotation.scientificToAbcNotation(n);

        //if the note has a # or a b and it is in the armor : we remove # or b 
          let pitch = n.match(/([ABCDEFG][#b]*)/g);

          let sc;
          if(key.hasOwnProperty('harmonic'))
            sc = key['natural'].scale;
          else sc = key.scale;

          if( sc.indexOf(pitch[0]) >= 0 ) { //if pitch of note is in the scale

            abc_notation = abc_notation.replace("^","").replace("_",""); //remove # or b

          }else if( sc.indexOf( pitch[0]) == -1 ){

            if( sc.indexOf(pitch[0][0]+"b" )>-1 || sc.indexOf(pitch[0][0]+"#" )>-1 ){
              abc_notation='='+abc_notation;
            }
          }
        //-----------

        notes_array.push(n);
        abc_string+= abc_notation;
      }


    let out = { 
      entity_type:"chord",
      tonality:tona,
      inv: inv, 
      mode:mode, 
      c_type:c.type, 
      notes_array:notes_array, 
      chord_name:c.symbol, 
      // chord_degree:, 
    };
    return out;

  }


}
