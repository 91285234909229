<section id="piano" class="modal nofly" tabindex="-1" role="dialog" [hidden]="visible==false" (contextmenu)="close_piano();$event.preventDefault();">

	<div id="nvxPiano" class="modal-dialog" role="document">

		<div class="menus">

			<!-- <span class="chord-name"
			[style.font-family]="cm.novaxe_notation_on?'nvxChord':''"
			[style.font-size]="cm.novaxe_notation_on?'30px':'35px'">
			{{_chordName}}
			</span> -->

			<div class="d-flex scaleandchords">

				<!--*********************-->
				<!--******* SCALE *******-->
				<!--*********************-->
				<div class="d-flex scale">
					<span class="option-title"><img src="assets/img/chordEditor/diamond.png" title="Scale"/></span>
					
					<div class="floored">
						<input id="piano_scale_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" [(ngModel)]="activeLosanges" type="checkbox"  >
						<label class="tgl-btn" for="piano_scale_toggle"></label>
					</div>

					<div class="apply-btns  score-pos-related">
				        <div class="switch-titled">
				          <label class="switch">
				            <input type="checkbox" [(ngModel)]="scale_follow_score" (change)="toggle_scale_follow_score()"/>
				            <span class="slider classic">
				              <span class="number">Score</span>
				              <span class="letter">Preset</span>
				            </span>
				          </label>
				        </div>
				        <span data-tooltip="Scale follows..." data-tooltip-position="bottom" style="right: 0px;padding:10px;"></span>		
					</div>

					<select class="w-auto form-control" [(ngModel)]="scale_tona" (change)="scale_clicked()" id="tona" [disabled]="scale_follow_score" >
						<option [ngValue]="'C#'">C#</option>
						<option [ngValue]="'F#'">F#</option>
						<option [ngValue]="'B'">B</option>
						<option [ngValue]="'E'">E</option>
						<option [ngValue]="'A'">A</option>
						<option [ngValue]="'D'">D</option>
						<option [ngValue]="'G'">G</option>
						<option [ngValue]="'C'">C</option>
						<option [ngValue]="'F'">F</option>
						<option [ngValue]="'Bb'">Bb</option>
						<option [ngValue]="'Eb'">Eb</option>
						<option [ngValue]="'Ab'">Ab</option>
						<option [ngValue]="'Db'">Db</option>
						<option [ngValue]="'Gb'">Gb</option>
					</select>
		
					<select class="w-auto form-control select-type" style="text-transform: capitalize;" [(ngModel)]="scale_type" (change)="scale_clicked()" name="scales_selector" id="scales" [disabled]="scale_follow_score" >
						
						<option [ngValue]="'major'">major</option>
						<option [ngValue]="'aeolian'">minor</option>
			
						<option disabled>_________</option>
			
						<option [ngValue]="'dorian'">dorian</option>
						<option [ngValue]="'phrygian'">phrygian</option>
						<option [ngValue]="'lydian'">lydian</option>
						<option [ngValue]="'mixolydian'">mixolydian</option>
						<option [ngValue]="'aeolian'">aeolian</option>
						<option [ngValue]="'locrian'">locrian</option>
			
						<option disabled>_________</option>
			
						<option [ngValue]="'major pentatonic'">major pentatonic</option>
						<option [ngValue]="'major blues'">major blues</option>
						<option [ngValue]="'minor pentatonic'">minor pentatonic</option>
						<option [ngValue]="'minor blues'">minor blues</option>
			
						<option disabled>_________</option>
						
						<option [ngValue]="'harmonic major'">harmonic major</option>
						<option [ngValue]="'harmonic minor'">harmonic minor</option>
			
						<option disabled>_________</option>
			
						<option [ngValue]="'diminished'">diminished</option>
						<option [ngValue]="'minor six diminished'">minor six diminished</option>
						<option [ngValue]="'half-whole diminished'">half-whole diminished</option>
						<option [ngValue]="'composite blues'">composite blues</option>
			
						<option disabled>_________</option>
			
						<option [ngValue]="'bebop'">bebop</option>
						<option [ngValue]="'bebop minor'">bebop minor</option>
						<option [ngValue]="'bebop major'">bebop major</option>
						<option [ngValue]="'bebop locrian'">bebop locrian</option>
						
						<option disabled>_________</option>
						
						<option [ngValue]="'ionian pentatonic'">ionian pentatonic</option>
						<option [ngValue]="'mixolydian pentatonic'">mixolydian pentatonic</option>
						<option [ngValue]="'ritusen'">ritusen</option>
						<option [ngValue]="'egyptian'">egyptian</option>
						<option [ngValue]="'neopolitan major pentatonic'">neopolitan major pentatonic</option>
						<option [ngValue]="'vietnamese 1'">vietnamese 1</option>
						<option [ngValue]="'pelog'">pelog</option>
						<option [ngValue]="'kumoijoshi'">kumoijoshi</option>
						<option [ngValue]="'hirajoshi'">hirajoshi</option>
						<option [ngValue]="'iwato'">iwato</option>
						<option [ngValue]="'in-sen'">in-sen</option>
						<option [ngValue]="'lydian pentatonic'">lydian pentatonic</option>
						<option [ngValue]="'malkos raga'">malkos raga</option>
						<option [ngValue]="'locrian pentatonic'">locrian pentatonic</option>
						<option [ngValue]="'minor six pentatonic'">minor six pentatonic</option>
						<option [ngValue]="'flat three pentatonic'">flat three pentatonic</option>
						<option [ngValue]="'flat six pentatonic'">flat six pentatonic</option>
						<option [ngValue]="'scriabin'">scriabin</option>
						<option [ngValue]="'whole tone pentatonic'">whole tone pentatonic</option>
						<option [ngValue]="'lydian #5P pentatonic'">lydian #5P pentatonic</option>
						<option [ngValue]="'lydian dominant pentatonic'">lydian dominant pentatonic</option>
						<option [ngValue]="'minor #7M pentatonic'">minor #7M pentatonic</option>
						<option [ngValue]="'super locrian pentatonic'">super locrian pentatonic</option>
						<option [ngValue]="'minor hexatonic'">minor hexatonic</option>
						<option [ngValue]="'augmented'">augmented</option>
						<option [ngValue]="'piongio'">piongio</option>
						<option [ngValue]="'prometheus neopolitan'">prometheus neopolitan</option>
						<option [ngValue]="'prometheus'">prometheus</option>
						<option [ngValue]="'mystery #1'">mystery #1</option>
						<option [ngValue]="'six tone symmetric'">six tone symmetric</option>
						<option [ngValue]="'whole tone'">whole tone</option>
						<option [ngValue]="'messiaen\'s mode #5'">messiaen's mode #5</option>
						<option [ngValue]="'locrian major'">locrian major</option>
						<option [ngValue]="'double harmonic lydian'">double harmonic lydian</option>
						<option [ngValue]="'altered'">altered</option>
						<option [ngValue]="'locrian #2'">locrian #2</option>
						<option [ngValue]="'mixolydian b6'">mixolydian b6</option>
						<option [ngValue]="'lydian dominant'">lydian dominant</option>
						<option [ngValue]="'lydian augmented'">lydian augmented</option>
						<option [ngValue]="'dorian b2'">dorian b2</option>
						<option [ngValue]="'melodic minor'">melodic minor</option>
						<option [ngValue]="'ultralocrian'">ultralocrian</option>
						<option [ngValue]="'locrian 6'">locrian 6</option>
						<option [ngValue]="'augmented heptatonic'">augmented heptatonic</option>
						<option [ngValue]="'dorian #4'">dorian #4</option>
						<option [ngValue]="'lydian diminished'">lydian diminished</option>
						<option [ngValue]="'leading whole tone'">leading whole tone</option>
						<option [ngValue]="'lydian minor'">lydian minor</option>
						<option [ngValue]="'phrygian dominant'">phrygian dominant</option>
						<option [ngValue]="'balinese'">balinese</option>
						<option [ngValue]="'neopolitan major'">neopolitan major</option>
						<option [ngValue]="'double harmonic major'">double harmonic major</option>
						<option [ngValue]="'hungarian minor'">hungarian minor</option>
						<option [ngValue]="'hungarian major'">hungarian major</option>
						<option [ngValue]="'oriental'">oriental</option>
						<option [ngValue]="'flamenco'">flamenco</option>
						<option [ngValue]="'todi raga'">todi raga</option>
						<option [ngValue]="'persian'">persian</option>
						<option [ngValue]="'enigmatic'">enigmatic</option>
						<option [ngValue]="'major augmented'">major augmented</option>
						<option [ngValue]="'lydian #9'">lydian #9</option>
						<option [ngValue]="'purvi raga'">purvi raga</option>
						<option [ngValue]="'spanish heptatonic'">spanish heptatonic</option>
						<option [ngValue]="'minor bebop'">minor bebop</option>
						<option [ngValue]="'ichikosucho'">ichikosucho</option>
						<option [ngValue]="'kafi raga'">kafi raga</option>
						<option [ngValue]="'messiaens mode #6'">messiaen's mode #6</option>
						<option [ngValue]="'messiaens mode #4'">messiaen's mode #4</option>
						<option [ngValue]="'messiaens mode #3'">messiaen's mode #3</option>
						<option [ngValue]="'messiaens mode #7'">messiaen's mode #7</option>
						<option [ngValue]="'chromatic'">chromatic</option>
					</select>
				</div>

				<!--*********************-->
				<!--******* CHORD *******-->
				<!--*********************-->
				<div class="d-flex chord">
					<span class="option-title"><img src="assets/img/chordEditor/1P.png" title="Chord"/></span>

					<div class="floored">
						<input id="piano_chords_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" [(ngModel)]="activeBubbles">
						<label class="tgl-btn" for="piano_chords_toggle"></label>
					</div>

					<div class="apply-btns  chord-pos-related">
				        <div class="switch-titled">
				          <label class="switch">
				            <input type="checkbox" [(ngModel)]="chord_follow_score" (change)="toggle_chord_follow_score()" [disabled]="!score_follow"/>
				            <span class="slider classic">
				              <span class="number">Score</span>
				              <span class="letter">Preset</span>
				            </span>
				          </label>
				        </div>
				        <span data-tooltip="Chord tones follows..." data-tooltip-position="bottom" style="right: 0px;padding:10px;"></span>
			     	</div>
		     

					<select class="w-auto form-control" [(ngModel)]="chord_tona" id="tona2" (change)="chord_clicked();" [disabled]="chord_follow_score || !score_follow">
						<option [ngValue]="'C#'">C#</option>
						<option [ngValue]="'F#'">Gb</option>
						<option [ngValue]="'F#'">F#</option>
						<option [ngValue]="'B'">B</option>
						<option [ngValue]="'E'">E</option>
						<option [ngValue]="'A'">A</option>
						<option [ngValue]="'D'">D</option>
						<option [ngValue]="'G'">G</option>
						<option [ngValue]="'C'">C</option>
						<option [ngValue]="'F'">F</option>
						<option [ngValue]="'Bb'">Bb</option>
						<option [ngValue]="'Bb'">A#</option>
						<option [ngValue]="'Eb'">Eb</option>
						<option [ngValue]="'Eb'">D#</option>
						<option [ngValue]="'Ab'">Ab</option>
						<option [ngValue]="'G#'">G#</option>
						<option [ngValue]="'Db'">Db</option>
					<option value="Gb">Gb</option>
					</select>

					<select class="w-auto form-control select-type" style="text-transform:capitalize;" 
					[disabled]="chord_follow_score || !score_follow" name="scales_selector2" id="scales2"
					[(ngModel)]="chord_type"
					(change)="chord_clicked();"
					>
						<option *ngFor="let chord of chords_list" [value]="chord[2].split(' ')[0]">{{chord[1]||chord[2].split(' ')[0]}}</option>
					</select>
				</div>
			</div>

			<div class="btns">
				<div class="double display" [ngClass]="{disabled:cm.letters_Numbers_sync}" >
					<span data-tooltip="Display degrees" data-tooltip-position="bottom" class="display-num">1</span>
					<input id="display_piano" [(ngModel)]="display_mode"  [disabled]="cm.letters_Numbers_sync" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox">
					<label class="tgl-btn"   for="display_piano"></label>
					<span data-tooltip="Display letters" data-tooltip-position="bottom" class="display-let">A</span>
				</div>	

				<div class="double">
					<img src="assets/img/fretboard/midi.png"/>
					<span data-tooltip="Midi" data-tooltip-position="bottom"></span>
					<input id="follow_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" [(ngModel)]="score_follow" (change)="toggle_midi()" type="checkbox">
					<label class="tgl-btn" for="follow_toggle"></label>
				</div>
				<div>
					<img src="assets/img/fretboard/score_icon.png"/>
					<span data-tooltip="Score" data-tooltip-position="bottom"></span>
				</div>
			</div>

			<button type="button" class="close" data-dismiss="modal" [ngClass]="{show:visible==true}" (contextmenu)="close_piano(true);$event.preventDefault();" (click)="close_piano(true)" aria-label="Close"><span aria-hidden="true" class="fa fa-times"></span></button>
		</div>

			
		<div style="height: 70%;width: 100%;overflow-x: scroll; overflow-y: hidden; white-space: nowrap;">
			<svg width="120%" height="100%" version="1.1" style="background:#343a40 !important;" viewBox="100 60 1190 1" xmlns="http://www.w3.org/2000/svg">

				<defs> 
					<filter id="f2_piano" color-interpolation-filters="sRGB">
						<feGaussianBlur in="SourceAlpha" stdDeviation="4" result="imgBlur" />
						<feSpecularLighting in="imgBlur" surfaceScale="2" specularConstant=".75"
						  specularExponent="20" lighting-color="#bbbbbb" result="imgSpecular">
						 <fePointLight x="-5" y="-100" z="100" />
						</feSpecularLighting>
						<!-- Restrict the specular highlight to the object; this is necessary. -->
						<feComposite in="imgSpecular" in2="SourceAlpha" operator="in" result="imgMasked" />
						<feComposite in="SourceGraphic" in2="imgMasked"
						  operator="arithmetic" k1="0" k2="1" k3="2" k4="0"/>
					</filter>
				</defs>

				<g transform="scale(0.9)">
					<!-- WHITE KEYS -->
					<g transform="translate(7.41)">
						<path (click)="play($event)" [ngClass]="{active:notes[21].greyed}" id="note_21" d="m114 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[23].greyed}" id="note_23" d="m139 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[24].greyed}" id="note_24" d="m164 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[26].greyed}" id="note_26" d="m189 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[28].greyed}" id="note_28" d="m214 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[29].greyed}" id="note_29" d="m239 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[31].greyed}" id="note_31" d="m264 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[33].greyed}" id="note_33" d="m289 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[35].greyed}" id="note_35" d="m314 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[36].greyed}" id="note_36" d="m339 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[38].greyed}" id="note_38" d="m364 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[40].greyed}" id="note_40" d="m389 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[41].greyed}" id="note_41" d="m414 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[43].greyed}" id="note_43" d="m439 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[45].greyed}" id="note_45" d="m464 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[47].greyed}" id="note_47" d="m489 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[48].greyed}" id="note_48" d="m514 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[50].greyed}" id="note_50" d="m539 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[52].greyed}" id="note_52" d="m564 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[53].greyed}" id="note_53" d="m589 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[55].greyed}" id="note_55" d="m614 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[57].greyed}" id="note_57" d="m639 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[59].greyed}" id="note_59" d="m664 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[60].greyed}" id="note_60" d="m689 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[62].greyed}" id="note_62" d="m714 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[64].greyed}" id="note_64" d="m739 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[65].greyed}" id="note_65" d="m764 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[67].greyed}" id="note_67" d="m789 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[69].greyed}" id="note_69" d="m814 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[71].greyed}" id="note_71" d="m839 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[72].greyed}" id="note_72" d="m864 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[74].greyed}" id="note_74" d="m889 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[76].greyed}" id="note_76" d="m914 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[77].greyed}" id="note_77" d="m939 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[79].greyed}" id="note_79" d="m964 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[81].greyed}" id="note_81" d="m989 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[83].greyed}" id="note_83" d="m1014 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[84].greyed}" id="note_84" d="m1039 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[86].greyed}" id="note_86" d="m1064 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[88].greyed}" id="note_88" d="m1089 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[89].greyed}" id="note_89" d="m1114 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[91].greyed}" id="note_91" d="m1139 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[93].greyed}" id="note_93" d="m1164 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[95].greyed}" id="note_95" d="m1189 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[96].greyed}" id="note_96" d="m1214 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[98].greyed}" id="note_98" d="m1239 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[100].greyed}" id="note_100" d="m1264 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[101].greyed}" id="note_101" d="m1289 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[103].greyed}" id="note_103" d="m1314 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[105].greyed}" id="note_105" d="m1339 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[107].greyed}" id="note_107" d="m1364 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[108].greyed}" id="note_108" d="m1389 10.5h24.5v96.7c-3.01 6.11-19.9 5.94-24.5 0z" class="white_key"/>
					</g>

					<!-- BLACK KEYS -->
					<g transform="matrix(1 0 0 1 -4.24 .000722)">
						<path (click)="play($event)" [ngClass]="{active:notes[22].greyed}" id="note_22" d="m142 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[25].greyed}" id="note_25" d="m193 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[27].greyed}" id="note_27" d="m218 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[30].greyed}" id="note_30" d="m267 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[32].greyed}" id="note_32" d="m292 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[34].greyed}" id="note_34" d="m317 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[37].greyed}" id="note_37" d="m368 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[39].greyed}" id="note_39" d="m393 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[42].greyed}" id="note_42" d="m442 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[44].greyed}" id="note_44" d="m467 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[46].greyed}" id="note_46" d="m492 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[49].greyed}" id="note_49" d="m543 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[51].greyed}" id="note_51" d="m568 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[54].greyed}" id="note_54" d="m618 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[56].greyed}" id="note_56" d="m642 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[58].greyed}" id="note_58" d="m667 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[61].greyed}" id="note_61" d="m719 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[63].greyed}" id="note_63" d="m744 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[66].greyed}" id="note_66" d="m793 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[68].greyed}" id="note_68" d="m818 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[70].greyed}" id="note_70" d="m842 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[73].greyed}" id="note_73" d="m894 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[75].greyed}" id="note_75" d="m919 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[78].greyed}" id="note_78" d="m968 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[80].greyed}" id="note_80" d="m993 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[82].greyed}" id="note_82" d="m1018 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[85].greyed}" id="note_85" d="m1069 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[87].greyed}" id="note_87" d="m1094 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[90].greyed}" id="note_90" d="m1143 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[92].greyed}" id="note_92" d="m1168 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[94].greyed}" id="note_94" d="m1193 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[97].greyed}" id="note_97" d="m1244 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[99].greyed}" id="note_99" d="m1269 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[102].greyed}" id="note_102" d="m1318 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[104].greyed}" id="note_104" d="m1343 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
						<path (click)="play($event)" [ngClass]="{active:notes[106].greyed}" id="note_106" d="m1368 10.2h17.4v58c-2.14 3.66-14.2 3.56-17.4 0z" class="black_key"/>
					</g>
					
					<!-- [ngClass]="{active:get_class_active(j+21)}"	 -->
					
					<!-- [style.display]="get_class_glow(j+21) && !notes[midi_note].display && activeBubbles && this.score_follow? 'block' : 'none'"  -->

					<!-- [ngClass]="{active:get_class_active(j+21)}" -->

					<!-- [attr.note]="notes_active[i][j]"  -->


					<!-- YELLOW FINGERS -->
					<svg 
					*ngFor="let n of piano_midi; index as j" 
					width="25" height="25" viewBox="0 0 40 40" 
					class="finger"
					[attr.x]="piano_x[j]+8" 
					[attr.y]="piano_y[j]-1" 
					[attr.id]="'note_'+n+'_Glow'" 
					[style.display]="get_class_glow(j+21) ? 'block' : 'none'" >
						
						<path 
						[style.filter]="selected_anim=='Misty' || selected_anim=='Misty-time'?'url(#f2_piano) blur(12px)':''" 
						style="transform:scale(1);transform-origin:50%;" 
						d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z"/>

						<!-- FOR FINGERS ANIMATION -->
						<path id="pulse_piano" 
						[ngClass]="{'p-misty': (selected_anim=='Misty' || selected_anim=='Misty-time'), 'p-summer': selected_anim=='Summertime', 'p-fever': selected_anim=='Fever'}" 
						[attr.style]="selected_anim=='Summertime'?'animation-duration:'+animationTime+'s;':''" 
						d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z"/>
						
						<path 
						[ngClass]="{'p-summer': selected_anim=='Misty-time'}" 
						[attr.style]="selected_anim=='Misty-time'?'animation-duration:'+animationTime+'s;':''" 
						d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z"/>

						<text class="finger-diamond">{{(display_mode)?notes_active[j]:active_intervals[active_scale.indexOf(notes_active[j])]}}</text>
					</svg>
					
					<!-- BUBBLES -->
					<image 
					*ngFor="let midi_note of piano_midi; index as j" 
					[style.display]="get_class_glow(j+21) && ((notes[midi_note].display ||!score_follow) && activeBubbles) ? 'block' : 'none'" 
					[attr.id]="'piano_glow_'+j" 
					[attr.x]="piano_x[j]+9.25" 
					[attr.y]="piano_y[j]" 
					width="20" height="20" viewBox="0 0 40 40" 
					class="bubble_p glow"
					[attr.xlink:href]="'assets/img/chordEditor/'+notes[midi_note].image+'.png'">
					</image>					
				
					<image 
					*ngFor="let midi_note of piano_midi; index as j" 
					[attr.x]="piano_x[j]+9.25" 
					[style.display]="(notes[midi_note].display && activeBubbles) ? 'block' : 'none'" 
					[attr.y]="piano_y[j]" 
					width="20" height="20" viewBox="0 0 40 40" 
					[attr.xlink:href]="'assets/img/chordEditor/'+notes[midi_note].image+'.png'" 
					alt="assets/img/chordEditor/none.png" 
					class="bubble_p">
					</image>

					<!-- DIAMONDS -->
					<svg piano-diamond 
					*ngFor="let n of piano_midi; index as j" 
					width="25" height="25" viewBox="0 0 40 40" 
					class="diamond"
					[attr.x]="piano_x[j]+8" 
					[attr.y]="piano_y[j]-1" 
					[attr.id]="'note_'+n+'_Diamond'" 
					[style.display]="( active_scale.indexOf(notes_active[j])>-1 && activeLosanges) ? 'block' : 'none'" 
					[text]="(display_mode)?notes_active[j]:active_intervals[active_scale.indexOf(notes_active[j])]">
					</svg>
							
				</g>
			</svg>
		</div>
	</div>
</section>
