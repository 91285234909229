import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import novaxeAPI, { APIResponse } from 'src/app/utils/novaxeAPI';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {

  constructor() { }

  public async getItems(song:object): Promise<{
    artists: {
      items: {
        id: string;
      }[];
    };
    tracks: {
      items: {
        id: string;
      }[];
    }
  } | undefined> {

    const resp = await novaxeAPI<{
      artists: {
        items: {
          id: string;
        }[];
      };
      tracks: {
        items: {
          id: string;
        }[];
      }
    }>('POST', 'apiSpotify', {body: song});

    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    return resp.data;
  }


  public async getFeatures(id:object){
    const resp = await novaxeAPI<{
      key: string,
      mode: string,
      tempo: number,
      duration_ms: number
    }>('POST', 'apiSpotifyAnalysis', {body: id});
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    return resp.data;
  }


  public async getInfos(id:object){
    const resp = await novaxeAPI('POST', 'apiSpotifyContents', {body: id});
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    return resp.data;
  }


  public async getReco(infos:object){
    const resp = await novaxeAPI('POST', 'apiSpotifyReco', {body: infos});
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    return resp.data;
  }


  public async getTop(id:object): Promise<{
    tracks: {
      name: string;
      album: {
        name: string;
        images: {
          url: string;
        }[];
      };
      duration_ms: number;
    }[];
  } | undefined> {
    const resp = await novaxeAPI<
      {
        tracks: {
          name: string;
          album: {
            name: string;
            images: {
              url: string;
            }[];
          };
          duration_ms: number;
        }[];
      }
    >('POST', 'apiSpotifyTop', {body: id});
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    return resp.data;
  }


}
