import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Measure } from '@models/songmodel/measure';
import { Scale } from "@tonaljs/tonal";
import { SelectionModel } from '@models/selectionmodel/selectionmodel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scale-selector',
  templateUrl: './scale-selector.component.html',
  styleUrls: ['./scale-selector.component.scss']
})
export class ScaleSelectorComponent implements OnInit {

  private scaleUpdate$:Subscription;

  @Input() scale_tona:'C#'| 'F#'| 'B'| 'E'| 'A'| 'D'| 'G'| 'C'| 'F'| 'Bb'| 'Eb'| 'Ab'| 'Db' = 'C';
  @Input() scale_type:'major'|'minor'|'augmented'|'diminished'|'unknown' = 'major';
  @Input() caged_position:Array<'C'|'A'|'G'|'E'|'D'> = [];
  @Input() measure:Measure;

  
  constructor(private sel:SelectionModel) {
    this.scaleUpdate$ = this.sel.getUpdateScale().subscribe(()=>{
      this.refresh_scale();
    });
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.scaleUpdate$.unsubscribe();
  }
  
  public refresh_scale(): void{
    let scale = Scale.get(this.scale_tona+' '+(this.scale_type||'major').toLowerCase());
    
    let s = {
      caged_position:[...this.caged_position],
      scale_tona:this.scale_tona,
      scale_type:this.scale_type,
      full_scale:scale
    }

    this.measure.addScale(s)

  }

  public set_caged_position(p):void{
    let idx = this.caged_position.indexOf(p);
    if(idx > -1)
      this.caged_position.splice(idx,1);
    else this.caged_position.push(p);
  }

}
