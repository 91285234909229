import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import novaxeAPI from 'src/app/utils/novaxeAPI';


@Injectable({
  providedIn: 'root'
})
export class DiscogsService {

  constructor() { }

  async getItemsEditor(song:object): Promise<{
    results: {
      genre: string;
      style: string;
    }[]
  }> {

    const resp = await novaxeAPI<{
      results: {
        genre: string;
        style: string;
      }[]
    }>('POST', 'apiDiscogs', {body: song});
    if (!resp.status) {
      console.error(resp.message);
      return;
    }
    return resp.data;
  }

}