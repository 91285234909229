<section id="store">

	<div class="container">
	    <div class="row" >
	        <ng-container class="col-md-6 col-lg-4 col-12" *ngFor="let score of list; index as i">
	            <div class="card-deck" *ngIf="score.price > 0">
	                <div class="card mb-4">
	                    <img class="card-img-top img-fluid" src="{{score.img}}" onerror="this.src='../assets/img/music-placeholder-image.jpg'" alt="Card image cap" style="max-width: 350px; max-height: 350px;">
	                    <div class="card-body">
	                    	<h5 class="card-title">{{score.title}}</h5>
			    			<p class="card-text">{{score.artist}}</p>
			    			<p class="card-text">{{score.album}}</p>
	                    	<p class="card-text"><small class="text-muted">Transcription by {{score.author}}</small></p>
	                    </div>
	                    <ul class="list-group list-group-flush">
						    <li class="list-group-item">Price : {{score.price}} CAD</li>
						</ul>
						<div class="card-body">
							<a href="#" class="card-link">Buy score</a>
						</div>
	                </div>
	            </div>
	        </ng-container>
	    </div>
	</div>

</section>
