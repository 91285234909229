<svg #minipiano width="100%" height="100%" version="1.1" viewBox="0 0 350.7 137.5" xmlns="http://www.w3.org/2000/svg">
  <g id="layer1" [attr.transform]="'translate('+coordinates[root]+' -10.15)'">
    <g id="g943" transform="translate(123.6)">
      <path id="C" d="m4.338 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="D" d="m29.37 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="E" d="m54.39 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="F" d="m79.42 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="G" d="m104.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="A" d="m129.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="B" d="m154.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="Cs_Db" d="m20.42 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Ds_Eb" d="m45.29 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Fs_Gb" d="m94.51 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Gs_Ab" d="m119.4 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="As_Bb" d="m144.2 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
    </g>
    <g id="g969" transform="translate(298.8)">
      <path id="C" d="m4.338 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="D" d="m29.37 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="E" d="m54.39 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="F" d="m79.42 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="G" d="m104.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="A" d="m129.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="B" d="m154.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="Cs_Db" d="m20.42 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Ds_Eb" d="m45.29 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Fs_Gb" d="m94.51 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Gs_Ab" d="m119.4 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="As_Bb" d="m144.2 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
    </g>
    <g id="g970" transform="translate(474)">
      <path id="C" d="m4.338 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="D" d="m29.37 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="E" d="m54.39 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="F" d="m79.42 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="G" d="m104.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="A" d="m129.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="B" d="m154.5 10.57h24.46v130.5c-3.005 8.245-19.88 8.017-24.46 0z" style="fill:#ffffff;"/>
      <path id="Cs_Db" d="m20.42 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Ds_Eb" d="m45.29 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Fs_Gb" d="m94.51 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="Gs_Ab" d="m119.4 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
      <path id="As_Bb" d="m144.2 10.84h17.44v90.18c-2.143 5.696-14.18 5.538-17.44 0z" style="fill:#000000;"/>
    </g>
  </g>
</svg>
