<section id="tonality-btn">
    
    <svg [attr.height]="hide?'0%':'100%'" [attr.width]="hide?'0%':'100%'" version="1.1" viewBox="-120 -120 615 615" xmlns="http://www.w3.org/2000/svg" 
        style="position: absolute;transform: scale(2) translate(-50px, -60px);"
        (mouseleave)="hideWheel()"
        (contextmenu)="$event.preventDefault();toggle_leave_open()" >

        <defs>
            <g id='triangle' style="transform: translate(96px, 60px) scale(1.5);">
                <path d="M 89.048118,125.60966 140.66796,-64.182915 c 0,0 -48.656768,-14.347508 -101.992068,-0.311902 9.980874,36.18067 50.372226,190.104477 50.372226,190.104477 z"></path>
            </g>
            <g id='cutTriangle' style="transform: translate(96px, 60px);">
                <path d="m 113.42817,35.268614 27.11212,-99.377094 c 0,0 -48.525828,-14.308899 -101.717598,-0.311062 9.954022,36.083305 26.422962,99.599306 26.422962,99.599306 0,0 23.813716,-7.330131 48.182516,0.08886 z"></path>
            </g>
        </defs>

        <circle [attr.style]="hide?'display:none;':'display:block;'"  class="back-circle" cx="185" cy="185" r="300" (mouseleave)="hideWheel()"/>
        <circle [attr.style]="hide?'display:none;':'display:block;'"  class="back-circle-maj" cx="185" cy="185" r="250"/>
        <circle [attr.style]="hide?'display:none;':'display:block;'"  class="back-circle-min" cx="185" cy="185" r="190"/>
        <circle [attr.style]="hide?'display:none;':'display:block;'"  class="back-circle-dim" cx="185" cy="185" r="140"/>

        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 195px;'" class="selection_background" [ngClass]="{'active':(selected_green=='C' || selected_green=='Am')}" transform="scale(1.48)"/>

        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 190px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='G' || selected_green=='Em')}" transform="rotate(30) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='D' || selected_green=='Bm')}" transform="rotate(60) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='A' || selected_green=='F#m')}" transform="rotate(90) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='E' || selected_green=='C#m')}" transform="rotate(119) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='B' || selected_green=='G#m')}" transform="rotate(149) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='F#' || selected_green=='D#m' || selected_green=='Gb' || selected_green=='Ebm')}" transform="rotate(180) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='Db' || selected_green=='Bbm')}" transform="rotate(210) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='Ab' || selected_green=='Fm')}" transform="rotate(241.5) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='Eb' || selected_green=='Cm')}" transform="rotate(271.5) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'active':(selected_green=='Bb' || selected_green=='Gm')}" transform="rotate(300) scale(1.48)"/>
 
        <use xlink:href="#triangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 178px 189px;'" class="selection_background" [ngClass]="{'active':(selected_green=='F' || selected_green=='Dm')}" transform="rotate(329) scale(1.48)"/>


        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 195px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='F' || selected_green=='Dm' || selected_green=='G' || selected_green=='Em') }" transform="scale(1.48)"/>
         
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 190px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='C' || selected_green=='Am' || selected_green=='D' || selected_green=='Bm') }" transform="rotate(30) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='G' || selected_green=='Em' || selected_green=='A' || selected_green=='F#m') }" transform="rotate(60) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='D' || selected_green=='Bm' || selected_green=='E' || selected_green=='C#m') }" transform="rotate(90) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='A' || selected_green=='F#m' || selected_green=='B' || selected_green=='G#m' ) }" transform="rotate(119) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='E' || selected_green=='C#m' || selected_green=='F#' || selected_green=='D#m' || selected_green=='Gb' || selected_green=='Ebm') }" transform="rotate(149) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='B' || selected_green=='G#m' || selected_green=='Db' || selected_green=='Bbm') }" transform="rotate(180) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='F#' || selected_green=='D#m' || selected_green=='Gb' || selected_green=='Ebm' || selected_green=='Ab' || selected_green=='Fm') }" transform="rotate(210) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='Db' || selected_green=='Bbm' || selected_green=='Eb' || selected_green=='Cm') }" transform="rotate(241.5) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='Ab' || selected_green=='Fm' || selected_green=='Bb' || selected_green=='Gm') }" transform="rotate(271.5) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 185px 188px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='Eb' || selected_green=='Cm' || selected_green=='F' || selected_green=='Dm') }" transform="rotate(300) scale(1.48)"/>
 
        <use xlink:href="#cutTriangle" [attr.style]="hide?'display:none;':'display:block;transform-origin: 178px 189px;'" class="selection_background" [ngClass]="{'side-active':(selected_green=='C' || selected_green=='Am' || selected_green=='Bb' || selected_green=='Gm') }" transform="rotate(329) scale(1.48)"/>
        
        <g transform="translate(0 -165)">

            <g id="roman">
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[0]:'130'" [attr.y]="!hide?y_roman[0]:'320'" class="roman">IV</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[0]:'130'" [attr.y]="!hide?y_roman[0]:'320'" class="roman">IV</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[1]:'130'" [attr.y]="!hide?y_roman[1]:'320'" class="roman">I</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[1]:'130'" [attr.y]="!hide?y_roman[1]:'320'" class="roman">I</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[2]:'130'" [attr.y]="!hide?y_roman[2]:'320'" class="roman">V</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[2]:'130'" [attr.y]="!hide?y_roman[2]:'320'" class="roman">V</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[3]:'130'" [attr.y]="!hide?y_roman[3]:'320'" class="roman">II</text>
                 <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[3]:'130'" [attr.y]="!hide?y_roman[3]:'320'" class="roman">II</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[4]:'130'" [attr.y]="!hide?y_roman[4]:'320'" class="roman">VI</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[4]:'130'" [attr.y]="!hide?y_roman[4]:'320'" class="roman">VI</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[5]:'130'" [attr.y]="!hide?y_roman[5]:'320'" class="roman">III</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[5]:'130'" [attr.y]="!hide?y_roman[5]:'320'" class="roman">III</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[6]:'130'" [attr.y]="!hide?y_roman[6]:'320'" class="roman">VII</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[6]:'130'" [attr.y]="!hide?y_roman[6]:'320'" class="roman">VII</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[7]:'130'" [attr.y]="!hide?y_roman[7]:'320'" class="roman">Tri</text>  
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[7]:'130'" [attr.y]="!hide?y_roman[7]:'320'" class="roman">Tri</text>  

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[9]:'130'" [attr.y]="!hide?y_roman[9]:'320'" class="roman">bVI</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[9]:'130'" [attr.y]="!hide?y_roman[9]:'320'" class="roman">bVI</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[10]:'130'" [attr.y]="!hide?y_roman[10]:'320'" class="roman">bIII</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[10]:'130'" [attr.y]="!hide?y_roman[10]:'320'" class="roman">bIII</text>

                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[11]:'130'" [attr.y]="!hide?y_roman[11]:'320'" class="roman">bVII</text>
                <text [attr.style]="hide?'display:none;':'display:block;'" [attr.x]="!hide?x_roman[11]:'130'" [attr.y]="!hide?y_roman[11]:'320'" class="roman">bVII</text>
            </g>  

            <g id="major">
                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='C', 'active-chord':current_score_chord=='C', 'active-midi-chord':current_midi_chord=='C'}" [attr.cx]="!hide?'168':'130'" [attr.cy]="!hide?'148.36215':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='C'}" [attr.x]="!hide?'168':'130'" [attr.y]="!hide?'148.36215':'320'" class="fifths">C</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='G', 'active-chord':current_score_chord=='G', 'active-midi-chord':current_midi_chord=='G'}" [attr.cx]="!hide?'275':'130'" [attr.cy]="!hide?'175.36215':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='G'}" [attr.x]="!hide?'275':'130'" [attr.y]="!hide?'175.36215':'320'" class="fifths">G</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='D', 'active-chord':current_score_chord=='D', 'active-midi-chord':current_midi_chord=='D'}" [attr.cx]="!hide?'360':'130'" [attr.cy]="!hide?'261.36218':'320'" class="dot"/>
                 <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='D'}" [attr.x]="!hide?'360':'130'" [attr.y]="!hide?'260.36218':'320'" class="fifths">D</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='A', 'active-chord':current_score_chord=='A', 'active-midi-chord':current_midi_chord=='A'}" [attr.cx]="!hide?'383':'130'" [attr.cy]="!hide?'368.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='A'}" [attr.x]="!hide?'383':'130'" [attr.y]="!hide?'368.36218':'320'" class="fifths">A</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='E', 'active-chord':current_score_chord=='E', 'active-midi-chord':current_midi_chord=='E'}" [attr.cx]="!hide?'360':'130'" [attr.cy]="!hide?'475.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='E'}" [attr.x]="!hide?'360':'130'" [attr.y]="!hide?'475.36218':'320'" class="fifths">E</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='B', 'active-chord':current_score_chord=='B', 'active-midi-chord':current_midi_chord=='B'}" [attr.cx]="!hide?'280':'130'" [attr.cy]="!hide?'560.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='B'}" [attr.x]="!hide?'280':'130'" [attr.y]="!hide?'560.36218':'320'" class="fifths">B</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='F#'|| selected_green=='Gb', 'active-chord':current_score_chord=='F#'||current_score_chord=='Gb', 'active-midi-chord':current_midi_chord=='F#'|| current_midi_chord=='Gb'}" [attr.cx]="!hide?'165':'130'" [attr.cy]="!hide?'588.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='F#'|| selected_green=='Gb'}" [attr.x]="!hide?'138':'130'" [attr.y]="!hide?'588.36218':'320'" class="fifths">Gb|F#</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Db', 'active-chord':current_score_chord=='Db', 'active-midi-chord':current_midi_chord=='Db'}" [attr.cx]="!hide?'55':'130'" [attr.cy]="!hide?'555.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Db'}" [attr.x]="!hide?'55':'130'" [attr.y]="!hide?'555.36218':'320'" class="fifths">Db</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Ab', 'active-chord':current_score_chord=='Ab', 'active-midi-chord':current_midi_chord=='Ab'}" [attr.cx]="!hide?'-25':'130'" [attr.cy]="!hide?'478.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Ab'}" [attr.x]="!hide?'-28':'130'" [attr.y]="!hide?'475.36218':'320'" class="fifths">Ab</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Eb', 'active-chord':current_score_chord=='Eb', 'active-midi-chord':current_midi_chord=='Eb'}" [attr.cx]="!hide?'-55':'130'" [attr.cy]="!hide?'368.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Eb'}" [attr.x]="!hide?'-55':'130'" [attr.y]="!hide?'368.36218':'320'" class="fifths">Eb</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Bb', 'active-chord':current_score_chord=='Bb', 'active-midi-chord':current_midi_chord=='Bb'}" [attr.cx]="!hide?'-25':'130'" [attr.cy]="!hide?'260.36218':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='Bb'}" [attr.x]="!hide?'-25':'130'" [attr.y]="!hide?'260.36218':'320'" class="fifths">Bb</text>

                <circle r="30" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='F', 'active-chord':current_score_chord=='F', 'active-midi-chord':current_midi_chord=='F'}" [attr.cx]="!hide?'58':'130'" [attr.cy]="!hide?'178.36215':'320'" class="dot"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' [ngClass]="{'active-tonality':selected_green=='F'}" [attr.x]="!hide?'62':'130'" [attr.y]="!hide?'180.36215':'320'" class="fifths">F</text>

            </g>

            <g id="minor">
                
                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Am', 'active-chord':current_score_chord=='Am', 'active-midi-chord':current_midi_chord=='Am'}" [attr.cx]="!hide?'168.41632':'130'" [attr.cy]="!hide?'203.34468':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Am'}"><tspan [attr.x]="!hide?'165.41632':'130'" [attr.y]="!hide?'195.34468':'320'">Am</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Dm', 'active-chord':current_score_chord=='Dm', 'active-midi-chord':current_midi_chord=='Dm'}" [attr.cx]="!hide?'87.00448':'130'" [attr.cy]="!hide?'225.70085':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Dm'}"><tspan [attr.x]="!hide?'85.00448':'130'" [attr.y]="!hide?'220.70085':'320'">Dm</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Gm', 'active-chord':current_score_chord=='Gm', 'active-midi-chord':current_midi_chord=='Gm'}" [attr.cx]="!hide?'23.500919':'130'" [attr.cy]="!hide?'285.64285':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Gm'}"><tspan [attr.x]="!hide?'20.500919':'130'" [attr.y]="!hide?'280.64285':'320'">Gm</tspan></text>


                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Cm', 'active-chord':current_score_chord=='Cm', 'active-midi-chord':current_midi_chord=='Cm'}" [attr.cx]="!hide?'0.585354':'130'" [attr.cy]="!hide?'370.73105':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Cm'}"><tspan [attr.x]="!hide?'0.585354':'130'" [attr.y]="!hide?'365.73105':'320'">Cm</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Fm', 'active-chord':current_score_chord=='Fm', 'active-midi-chord':current_midi_chord=='Fm'}" [attr.cx]="!hide?'25.601101':'125'" [attr.cy]="!hide?'450.13083':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Fm'}"><tspan [attr.x]="!hide?'25.601101':'125'" [attr.y]="!hide?'445.13083':'320'">Fm</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Bbm', 'active-chord':current_score_chord=='Bbm', 'active-midi-chord':current_midi_chord=='Bbm'}" [attr.cx]="!hide?'85.86298':'130'" [attr.cy]="!hide?'510.49033':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Bbm'}"><tspan [attr.x]="!hide?'80.86298':'130'" [attr.y]="!hide?'505.49033':'320'">Bbm</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot double" [ngClass]="{'active-tonality':selected_green=='D#m' || selected_green=='Ebm', 'active-chord':current_score_chord=='D#m'|| current_score_chord=='Ebm', 'active-midi-chord':current_midi_chord=='D#m' || current_midi_chord=='Ebm'}" [attr.cx]="!hide?'162.39803':'130'" [attr.cy]="!hide?'532.32037':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m double" [ngClass]="{'active-tonality':selected_green=='D#m' || selected_green=='Ebm'}"><tspan [attr.x]="!hide?'155.39803':'130'" [attr.y]="!hide?'518.32037':'320'">Ebm|D#m</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='G#m'||selected_green=='Abm', 'active-chord':current_score_chord=='G#m'||current_score_chord=='Abm', 'active-midi-chord':current_midi_chord=='G#m'||current_midi_chord=='Abm'}" [attr.cx]="!hide?'248.19267':'130'" [attr.cy]="!hide?'510.47498':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='G#m'||selected_green=='Abm'}"><tspan [attr.x]="!hide?'245.19267':'130'" [attr.y]="!hide?'505.47498':'320'">G#m</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='C#m'||selected_green=='Dbm', 'active-chord':current_score_chord=='C#m'||current_score_chord=='Dbm', 'active-midi-chord':current_midi_chord=='C#m'||current_midi_chord=='Dbm'}" [attr.cx]="!hide?'310.24832':'130'" [attr.cy]="!hide?'450.80945':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='C#m'||selected_green=='Dbm'}"><tspan [attr.x]="!hide?'305.24832':'130'" [attr.y]="!hide?'445.80945':'320'">C#m</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='F#m'||selected_green=='Gbm', 'active-chord':current_score_chord=='F#m'||current_score_chord=='Gbm', 'active-midi-chord':current_midi_chord=='F#m'||current_midi_chord=='Gbm'}" [attr.cx]="!hide?'330.58072':'130'" [attr.cy]="!hide?'370.58005':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='F#m'||selected_green=='Gbm'}"><tspan [attr.x]="!hide?'330.58072':'130'" [attr.y]="!hide?'365.58005':'320'">F#m</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Bm', 'active-chord':current_score_chord=='Bm', 'active-midi-chord':current_midi_chord=='Bm'}" [attr.cx]="!hide?'310.17484':'130'" [attr.cy]="!hide?'285.56714':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Bm'}"><tspan [attr.x]="!hide?'310.17484':'130'" [attr.y]="!hide?'280.56714':'320'">Bm</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="dot" [ngClass]="{'active-tonality':selected_green=='Em', 'active-chord':current_score_chord=='Em', 'active-midi-chord':current_midi_chord=='Em'}" [attr.cx]="!hide?'250.66835':'130'" [attr.cy]="!hide?'225.64746':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="fifths-m" [ngClass]="{'active-tonality':selected_green=='Em'}"><tspan [attr.x]="!hide?'253.66835':'130'" [attr.y]="!hide?'215.64746':'320'">Em</tspan></text>
            </g>  

            <g id="dim">
                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='Bo', 
                        'active-chord':current_score_chord=='Bdim' || current_score_chord=='Bdim7'||current_score_chord=='Fdim7'||current_score_chord=='Ddim7'||current_score_chord=='G#dim7'||current_score_chord=='Abdim7', 
                        'active-midi-chord':current_midi_chord=='Bdim' || current_midi_chord=='Bdim7'||current_midi_chord=='Fdim7'||current_midi_chord=='Ddim7'||current_midi_chord=='G#dim7'||current_midi_chord=='Abdim7'}" [attr.cx]="!hide?'170.41632':'130'" [attr.cy]="!hide?'243.34468':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='Bo', 'active-midi-chord':current_midi_chord=='Bo'}" class="diminished"><tspan [attr.x]="!hide?'170.41632':'130'" [attr.y]="!hide?'243.34468':'320'">Bo</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='Eo', 
                        'active-chord':current_score_chord=='Edim' || current_score_chord=='Edim7'||current_score_chord=='A#dim7'||current_score_chord=='C#dim7'||current_score_chord=='Gdim7'||current_score_chord=='Dbdim7'||current_score_chord=='Bbdim7', 
                        'active-midi-chord':current_midi_chord=='Edim' || current_midi_chord=='Edim7'||current_midi_chord=='A#dim7'||current_midi_chord=='C#dim7'||current_midi_chord=='Gdim7'||current_midi_chord=='Dbdim7'||current_midi_chord=='Bbdim7'}" [attr.cx]="!hide?'110.00448':'130'" [attr.cy]="!hide?'260.70085':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='Eo', 'active-midi-chord':current_midi_chord=='Eo'}" class="diminished"><tspan [attr.x]="!hide?'110.00448':'130'" [attr.y]="!hide?'260.70085':'320'">Eo</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='Ao', 
                        'active-chord':current_score_chord=='Adim' || current_score_chord=='Adim7'||current_score_chord=='Cdim7'||current_score_chord=='Ebdim7'||current_score_chord=='Gbdim7', 
                        'active-midi-chord':current_midi_chord=='Adim' || current_midi_chord=='Adim7'||current_midi_chord=='Cdim7'||current_midi_chord=='Ebdim7'||current_midi_chord=='Gbdim7'}" [attr.cx]="!hide?'65.500919':'130'" [attr.cy]="!hide?'300.64285':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='Ao', 'active-midi-chord':current_midi_chord=='Ao'}" class="diminished"><tspan [attr.x]="!hide?'65.500919':'130'" [attr.y]="!hide?'300.64285':'320'">Ao</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='Do', 
                        'active-chord':current_score_chord=='Ddim' || current_score_chord=='Ddim7'||current_score_chord=='G#dim7'||current_score_chord=='Bdim7'||current_score_chord=='Fdim7'||current_score_chord=='E#dim7'||current_score_chord=='Abdim7', 
                        'active-midi-chord':current_midi_chord=='Ddim' || current_midi_chord=='Ddim7'||current_midi_chord=='G#dim7'||current_midi_chord=='Bdim7'||current_midi_chord=='Fdim7'||current_midi_chord=='E#dim7'||current_midi_chord=='Abdim7'}" [attr.cx]="!hide?'55.585354':'130'" [attr.cy]="!hide?'360.73105':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='Do', 'active-midi-chord':current_midi_chord=='Do'}" class="diminished"><tspan [attr.x]="!hide?'55.585354':'130'" [attr.y]="!hide?'360.73105':'320'">Do</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='Go', 
                        'active-chord':current_score_chord=='Gdim' || current_score_chord=='Gdim7'||current_score_chord=='A#dim7'||current_score_chord=='Bbdim7'||current_score_chord=='C#dim7'||current_score_chord=='Edim7'||current_score_chord=='Dbdim7', 
                        'active-midi-chord':current_midi_chord=='Gdim' || current_midi_chord=='Gdim7'||current_midi_chord=='A#dim7'||current_midi_chord=='Bbdim7'||current_midi_chord=='C#dim7'||current_midi_chord=='Edim7'||current_midi_chord=='Dbdim7'}" [attr.cx]="!hide?'65.601101':'125'" [attr.cy]="!hide?'420.13083':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='Go', 'active-midi-chord':current_midi_chord=='Go'}" class="diminished"><tspan [attr.x]="!hide?'65.601101':'125'" [attr.y]="!hide?'420.13083':'320'">Go</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='Co', 
                        'active-chord':current_score_chord=='Cdim' || current_score_chord=='Cdim7'||current_score_chord=='Adim7'||current_score_chord=='Ebdim7'||current_score_chord=='Gbdim7', 
                        'active-midi-chord':current_midi_chord=='Cdim' || current_midi_chord=='Cdim7'||current_midi_chord=='Adim7'||current_midi_chord=='Ebdim7'||current_midi_chord=='Gbdim7'}" [attr.cx]="!hide?'110.86298':'130'" [attr.cy]="!hide?'465.49033':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='Co', 'active-midi-chord':current_midi_chord=='Co'}" class="diminished"><tspan [attr.x]="!hide?'110.86298':'130'" [attr.y]="!hide?'465.49033':'320'">Co</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='E#o'||selected_green=='Fo', 
                        'active-chord':current_score_chord=='Fdim' || current_score_chord=='E#dim' || current_score_chord=='Fdim7'||current_score_chord=='G#dim7'||current_score_chord=='Bdim7'||current_score_chord=='Ddim7'||current_score_chord=='E#dim7'||current_score_chord=='Abdim7', 
                        'active-midi-chord':current_midi_chord=='Fdim' || current_midi_chord=='E#dim' || current_midi_chord=='Fdim7'||current_midi_chord=='G#dim7'||current_midi_chord=='Bdim7'||current_midi_chord=='Ddim7'||current_midi_chord=='E#dim7'||current_midi_chord=='Abdim7'}" [attr.cx]="!hide?'160.39803':'130'" [attr.cy]="!hide?'475.32037':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='E#o'||current_score_chord=='Fo', 'active-midi-chord':current_midi_chord=='E#o'||current_midi_chord=='Fo'}" class="diminished double"><tspan [attr.x]="!hide?'160.39803':'130'" [attr.y]="!hide?'475.32037':'320'">Fo|E#o</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='A#o'||selected_green=='Bbo', 
                        'active-chord':current_score_chord=='A#dim' || current_score_chord=='Bbdim' || current_score_chord=='A#dim7'||current_score_chord=='Gdim7'||current_score_chord=='Bbdim7'||current_score_chord=='C#dim7'||current_score_chord=='Edim7'||current_score_chord=='Dbdim7', 
                        'active-midi-chord':current_midi_chord=='A#dim' || current_midi_chord=='Bbdim' || current_midi_chord=='A#dim7'||current_midi_chord=='Gdim7'||current_midi_chord=='Bbdim7'||current_midi_chord=='C#dim7'||current_midi_chord=='Edim7'||current_midi_chord=='Dbdim7'}" [attr.cx]="!hide?'230.19267':'130'" [attr.cy]="!hide?'465.47498':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='A#o', 'active-midi-chord':current_midi_chord=='A#o'||current_midi_chord=='Bbo'}" class="diminished"><tspan [attr.x]="!hide?'230.19267':'130'" [attr.y]="!hide?'465.47498':'320'">A#o</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='D#o'||selected_green=='Ebo', 
                        'active-chord':current_score_chord=='D#dim' || current_score_chord=='Ebdim' || current_score_chord=='Cdim7'||current_score_chord=='Adim7'||current_score_chord=='Ebdim7'||current_score_chord=='Gbdim7', 
                        'active-midi-chord':current_midi_chord=='D#dim' || current_midi_chord=='Ebdim' || current_midi_chord=='Cdim7'||current_midi_chord=='Adim7'||current_midi_chord=='Ebdim7'||current_midi_chord=='Gbdim7'}" [attr.cx]="!hide?'272.24832':'130'" [attr.cy]="!hide?'425.80945':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='D#o', 'active-midi-chord':current_midi_chord=='D#o'}" class="diminished"><tspan [attr.x]="!hide?'272.24832':'130'" [attr.y]="!hide?'425.80945':'320'">D#o</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='G#o' || selected_green=='Abo', 
                        'active-chord':current_score_chord=='G#dim' || current_score_chord=='Abdim' || current_score_chord=='Ddim7'||current_score_chord=='G#dim7'||current_score_chord=='Abdim7'||current_score_chord=='Bdim7'||current_score_chord=='Fdim7'||current_score_chord=='E#dim7', 
                        'active-midi-chord':current_midi_chord=='G#dim' || current_midi_chord=='Abdim' || current_midi_chord=='Ddim7'||current_midi_chord=='G#dim7'||current_midi_chord=='Abdim7'||current_midi_chord=='Bdim7'||current_midi_chord=='Fdim7'||current_midi_chord=='E#dim7'}" [attr.cx]="!hide?'285.58072':'130'" [attr.cy]="!hide?'365.58005':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='G#o', 'active-midi-chord':current_midi_chord=='G#o'}" class="diminished"><tspan [attr.x]="!hide?'285.58072':'130'" [attr.y]="!hide?'365.58005':'320'">G#o</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='C#o'||selected_green=='Dbo', 
                        'active-chord':current_score_chord=='C#dim' || current_score_chord=='Dbdim' || current_score_chord=='Gdim7'||current_score_chord=='A#dim7'||current_score_chord=='Bbdim7'||current_score_chord=='C#dim7'||current_score_chord=='Edim7'||current_score_chord=='Dbdim7', 
                        'active-midi-chord':current_midi_chord=='C#dim' || current_midi_chord=='Dbdim' || current_midi_chord=='Gdim7'||current_midi_chord=='A#dim7'||current_midi_chord=='Bbdim7'||current_midi_chord=='C#dim7'||current_midi_chord=='Edim7'||current_midi_chord=='Dbdim7'}" [attr.cx]="!hide?'270.17484':'130'" [attr.cy]="!hide?'305.56714':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='C#o', 'active-midi-chord':current_midi_chord=='C#o'}" class="diminished"><tspan [attr.x]="!hide?'270.17484':'130'" [attr.y]="!hide?'305.56714':'320'">C#o</tspan></text>

                <circle r="25" [attr.style]="hide?'display:none;':'display:block;'" (click)='clickMe($event)' class="d dot" 
                    [ngClass]="{'active-tonality':selected_green=='F#o'||selected_green=='Gbo', 
                        'active-chord':current_score_chord=='F#dim' || current_score_chord=='Gbdim' || current_score_chord=='Cdim7'||current_score_chord=='Adim7'||current_score_chord=='Ebdim7'||current_score_chord=='Gbdim7'||current_score_chord=='D#dim7'||current_score_chord=='F#dim7', 
                        'active-midi-chord':current_midi_chord=='F#dim' || current_midi_chord=='Gbdim' || current_midi_chord=='Cdim7'||current_midi_chord=='Adim7'||current_midi_chord=='Ebdim7'||current_midi_chord=='Gbdim7'||current_midi_chord=='D#dim7'||current_midi_chord=='F#dim7'}" [attr.cx]="!hide?'230.66835':'130'" [attr.cy]="!hide?'260.64746':'320'"/>
                <text [attr.style]="hide?'display:none;':'display:block;'" [ngClass]="{'active-chord':current_score_chord=='F#o', 'active-midi-chord':current_midi_chord=='F#o'}" class="diminished"><tspan [attr.x]="!hide?'230.66835':'130'" [attr.y]="!hide?'260.64746':'320'">F#o</tspan></text>

            </g>  
        </g>
        <g class="slices" [attr.style]="hide?'display:none;':'display:block;'">
            <path class="cut" d="M 99.054047,88.547724 78.974763,162.61375"/>
            <path class="cut" d="M 116.26571,98.56954 61.763102,152.59193"/>
            <path class="cut" d="m 126.3965,116.93104 -74.764192,17.2994"/>
            <path class="cut" d="M 126.04743,135.62037 51.981389,115.54111"/>
            <path class="cut" d="M 116.64861,152.20009 61.380206,98.961394"/>
            <path class="cut" d="M 98.67108,162.71546 79.357734,88.446022"/>
        </g>
    </svg>

    <div class="main-button pulse" (contextmenu)="$event.preventDefault();toggle_leave_open()" (click)="$event.preventDefault();toggle_lock_to_score()" (mouseover)="showWheel()" [ngClass]="{'bg-opacity':!hide}">
        <span [hidden]="!is_locked" class="tonality-letter">{{score_tonality}}<i class="fas fa-lock"></i></span>
        <span [hidden]="is_locked" class="tonality-letter">{{selected_tonality}}<i class="fas fa-lock-open"></i></span>
        <span class="mode"> {{mode}} </span>
    </div>
</section>