import { Component, NgZone, OnInit, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ChordDetectService } from '@services/chord-detect/chord-detect.service';
import { Subscription } from 'rxjs/Subscription';
import { ConfigModel } from '@models/configmodel/configModel';
import { MusicUtilsService } from '@services/music-utils-service/music-utils.service';
import { SelectionModel } from '@models/selectionmodel/selectionmodel';
import { Chord, Scale, Note } from "@tonaljs/tonal";
import { Songmodel } from '@models/songmodel/songmodel';
import { MidiService } from '@services/midi/midi.service';
import { TransportService } from '@services/transport/transport.service';

import { SoundfontService } from '@services/soundfont/soundfont.service';
import { CHORDS } from "@assets/chords/chords.js"

declare var $: any;

@Component({
  selector: 'app-piano',
  templateUrl: './piano.component.html',
  styleUrls: ['./piano.component.scss']
})
export class PianoComponent implements OnInit , AfterViewInit{

  
  @Input() set display(val){
    this.visible = val;
  }

  @Output() displayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // if(this.score_follow || !this.cm.display_piano || !this.visible)return;
    // this.lightsOff();
    // this._chordName = chord.chords[0];
    // this.light(chord.full_chord.midi_notes, chord.full_chord.intervals);


  @Input() set cur_chord(chord){
    if(!this.cm.display_piano )return;
    
    if( this.score_follow ){
      
      this.unlight_all_glow();
      for(let n of chord.full_chord.midi_notes){
        this.notes[n].glow = true;
        this.notes[n].greyed = true;
      }

    }else{

      this.lightsOff();
      this._chordName = chord.chords[0];
      this.light(chord.full_chord.midi_notes, chord.full_chord.intervals);
      for(let n of chord.full_chord.midi_notes){
        this.notes[n].glow = true;
        this.notes[n].greyed = true;
      }

      if(!chord.chords.length){
        this.chord_tona = '';
        this.chord_type = '';
      } else {
       this.chord_tona = chord.full_chord.tonic.replace(/[0-9]/g, '');
       this.chord_type = chord.full_chord.aliases[0];
      }

    }
    // this._chordName = chord.chords[0];
    // this.light(chord.full_chord.midi_notes, chord.full_chord.intervals);

    // FRETBOARD EXAMPLE
    // if( this.score_follow ){
    //   // this.light_chord(chord);

    //   this.unlight_all_glow(); 
    
    //   this.unlight_finger_on_fretboard();
    //   this.light_chord(chord);
    //   this.lit_chord = chord;
    //   // this.light_finger(2, 2);

    // }else{
    //   this.unlight_all_bub(); 
    //   this.unlight_finger_on_fretboard();
    //   this.light_chord(chord);
    //   this.lit_chord = chord;
    // }

  }
  
	public visible:boolean=false;
	public notes:Array<any>=[];

  private no_score_selected_scale:any = {  scale_tona:'E', scale_type:'major',full_scale:Scale.get("e major")};
  private no_score_selected_chord:any = {  chord_tona:'D', chord_type:'minor',full_chord:Chord.get("D minor")};

  private cur_scale_full:any = {empty:true};
  private cur_chord_full:any = {empty:true};

  // private no_score_selected_scale:any = {  scale_tona:'C', scale_type:'major',full_scale:Scale.get("c major")};

  public piano_x = [ 114, 128, 139, 164, 179, 189, 204, 214, 239, 253, 264, 278, 289, 303, 314, 339, 354, 364, 379, 389, 414, 428, 439, 453, 464, 478, 489, 514, 529, 539, 554, 564, 589, 604, 614, 628, 639, 653, 664, 689, 705, 714, 730, 739, 764, 779, 789, 804, 814, 828, 839, 864, 880, 889, 905, 914, 939, 954, 964, 979, 989, 1004, 1014, 1039, 1055, 1064, 1080, 1089, 1114, 1129, 1139, 1154, 1164, 1179, 1189, 1214, 1230, 1239, 1255, 1264, 1289, 1304, 1314, 1329, 1339, 1354, 1364, 1389];
  public piano_y = [ 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 80, 50, 80, 50, 80, 50, 80, 80 ];
  public piano_midi = [ 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108 ];

  public notes_sharp  = ['A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C','C#','D','D#','E','F','F#','G','G#','A','A#','B','C'];
  public notes_flat   = ['A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C','Db','D','Eb','E','F','Gb','G','Ab','A','Bb','B','C'];

  public notes_active = this.notes_flat;

  public active_scale = ['C','D','E','F','G','A','B'];
  public active_scale_quality = 'major';
  public _chordName:string = '';

  public active_intervals = ['1','2','3','4','5','6','7'];

  public activeBubbles:boolean=true;
  public activeLosanges:boolean=false;

  public score_follow:boolean = true;

  private selectionUpdate$:Subscription;
  private beat_selectionUpdate$:Subscription; 
  private scaleUpdate$:Subscription;
  private midiControlUpdate$:Subscription;
  private cur_selection_measure:any=null;
  private cur_selection_beat:any=null;

  

  private displayNotesMode_SUBJ_update$:Subscription;
  private show_score_chords_SUBJ_update$:Subscription;

  public animationTime:number = 5; // for ANIMATION #2
  public anim:Array<string> = ['Misty', 'Summertime', 'Fever', 'Misty-time', 'Off'];
  public selected_anim:string = 'Misty';

  public glowingBubbles:boolean = true;

  
  public display_letters:boolean = true;
  // public display_mode:'letters'|'degrees' = 'letters';
  public display_mode:boolean = true;

  public scale_tona:'C#'| 'F#'| 'B'| 'E'| 'A'| 'D'| 'G'| 'C'| 'F'| 'Bb'| 'Eb'| 'Ab'| 'Db' | '' = 'C';
  public scale_type:string = 'major';
  public chord_tona:'C#'| 'F#'| 'B'| 'E'| 'A'| 'D'| 'G'| 'C'| 'F'| 'Bb'| 'Eb'| 'Ab'| 'Db' | '' = 'C';
  public chord_type:string = 'major';
  public chord_follow_score:boolean=true;
  public scale_follow_score:boolean=true;

  public chords_list = CHORDS;

  constructor( 
    private zone:NgZone, 
    public elRef:ElementRef, 
    public sf:SoundfontService, 
    private mu:MusicUtilsService, 
    private sel:SelectionModel, 
    public cm:ConfigModel,
    private cd:ChordDetectService, 
    private sm:Songmodel, 
    private midi:MidiService, 
    private transport:TransportService 
    ) { 
    
      this.display_letters = (this.cm.getDisplayNoteMode() == 'letters')?true:false;
      
      this.displayNotesMode_SUBJ_update$ = this.cm.displayNotesMode_SUBJ_update$.subscribe(data=>{
        if(data == 'letters'){
          this.display_mode = false; 
        }else{
          this.display_mode = true; 
        }
      });


      this.show_score_chords_SUBJ_update$ = this.cm.global_show_score_chords_SUBJ$.subscribe(data=>{

        this.score_follow = data;

        this.lightsOff();
        if(this.cur_selection_measure) this.toggle_midi();
        
        if(this.score_follow == true){
          // this.light_chord_from_score(this.cur_selection_measure,this.cur_selection_beat);
          this.chord_tona = this.cur_chord_full.tonic;
          this.chord_type = this.cur_chord_full.aliases ? this.cur_chord_full.aliases[0] : null;
        }
      });

    this.beat_selectionUpdate$ = this.transport.beatChange.subscribe(data=>{

      let m = this.sm.getMeasureByIdx(data.measure);
      if(!m )return;
      this.cur_selection_measure = m; 
      let b = data.beat;
      // return
      
      // let m = data[0]; // 'm' est la mesure sélectionnée
      
      
      if(!m || m.getType() == 'part'){ 
        // debugger
        return; 
      }
      
      this.cur_selection_measure = m; 
      this.cur_selection_beat = b; 

      if(this.scale_follow_score)this.refresh_from_selected_score_scale(this.cur_selection_measure);

      if(!this.score_follow)return;

      if(this.chord_follow_score) {
        // this.refresh_from_selected_score_chord(data);
        this.light_chord_from_score(m,b);
        this.chord_tona = this.cur_chord_full.tonic;
        this.chord_type = this.cur_chord_full.aliases ? this.cur_chord_full.aliases[0] : null;

      }else{

        this.no_score_selected_chord.chord_tona = this.chord_tona; 
        this.no_score_selected_chord.chord_type = this.chord_type; 
        
        this.no_score_selected_chord.full_chord = Chord.get(this.chord_tona+' '+this.chord_type);
        this.no_score_selected_chord.full_chord.midi_tab = [];
        this.no_score_selected_chord.full_chord.unfiltered_midi = [];
        this.light_chord(this.no_score_selected_chord.full_chord);

      }
    });
    
    // this.selectionUpdate$ = this.sel.selected_Update$.subscribe(data=>{

    //   this.cur_selection_measure = data[0]; 
    //   return
      
    //   let m = data[0]; // 'm' est la mesure sélectionnée
      
      
    //   if(!m || m.getType() == 'part'){ 
    //     // debugger
    //     return; 
    //   }
      
    //   this.cur_selection_measure = m; 

    //   if(this.scale_follow_score)this.refresh_from_selected_score_scale(this.cur_selection_measure);

    //   if(!this.score_follow)return;

    //   if(this.chord_follow_score) {
    //     // this.refresh_from_selected_score_chord(data);
    //     this.light_chord_from_score(m);
    //   }else{

    //     this.no_score_selected_chord.chord_tona = this.chord_tona; 
    //     this.no_score_selected_chord.chord_type = this.chord_type; 
        
    //     this.no_score_selected_chord.full_chord = Chord.get(this.chord_tona+' '+this.chord_type);
    //     this.no_score_selected_chord.full_chord.midi_tab = [];
    //     this.no_score_selected_chord.full_chord.unfiltered_midi = [];
    //     this.light_chord(this.no_score_selected_chord.full_chord);

    //   }
    // });

    this.scaleUpdate$ = this.sel.getUpdateScale().subscribe(()=>{
      this.refresh_scale();
    })
    
    this.midiControlUpdate$ = this.midi.controlTabSubject.subscribe(data=>{

      let ctrl = this.cm.getAssignedControls(false);
      let o = ctrl[""+data];
      let number;
      let control_name;
      let control_action;

      if(o && o.length){
        for(let obj of o){
          number = obj.number;
          control_name = obj.control_name;
          control_action = obj.control_action;
          this.zone.run(()=>{
            if(eval("this."+control_name)) eval("this."+control_name+"(\""+control_action+"\")");
          })
        }
      } else return
    })
  }

  ngOnDestroy(){ 
    this.scaleUpdate$.unsubscribe();
    this.midiControlUpdate$.unsubscribe();
  }

  ngOnInit() {
    this.lightsOff();
  }

  ngAfterViewInit() {
    $("#piano").draggable({
      handle: "#nvxPiano"
    });
  }

  public change_animation_time( measure ){ // for ANIMATION #2
    let p = this.sm.getMeasures_hash()[measure.getIdx()].part;
    let meter = this.sm.getPart(p).getMeter();
    let m = Number(meter.split('/')[0]);
    this.animationTime = (measure.getAudioRegion().end - measure.getAudioRegion().start)/m;
  }

  public onChange_animation(newValue) {
    this.selected_anim = newValue;
  }

  public set_piano_key(key){
    this.scale_tona = key;

    this.scale_clicked();
  }

  public set_piano_scale(scale){
    this.scale_type = scale.toLowerCase();
    if(this.scale_type == 'minor')this.scale_type = 'aeolian';

    this.scale_clicked();
  }

  private set_scale_for_selected_measure(){
    
    if(this.cur_selection_measure && this.cur_selection_measure.length){
      // debugger
      let s = {
        scale_tona:this.scale_tona,
        scale_type:this.scale_type,
        full_scale: Scale.get(this.scale_tona+' '+this.scale_type.toLowerCase())
      }
      this.cur_selection_measure.addScale(s);
      // this.sel.sendUpdateScale();
      console.log("adding scale =>", s, 'in measure : ',this.cur_selection_measure);
    } 
    
  }

  public refresh_from_selected_score_scale(m:any){

    
    if(!this.scale_follow_score )return;

    this.cur_selection_measure = m; 
    
    if(!m || m.getType() == 'part'){ return; }
    
    this.zone.run(()=>{
      
      this.change_animation_time(m); // set la durée de l'animation

      let sc = m.getScale();
      if(!sc.full_scale.empty){
        
        this.scale_tona = sc.scale_tona;
        this.scale_type = sc.scale_type;

        
        this.refresh_scale();
      }

    })
  }
  
  public lightsOff(){
    this.notes = new Array(108);
    for(let i = 21; i <= 108; i++) 
    this.notes[i] = {
      display:false,
      greyed:false,
      image:"none",
      glow:false
    };
  }

  public toggle_chord_follow_score(){

    this.lightsOff();

    if(!this.chord_follow_score ){ 
      this.chord_tona = this.no_score_selected_chord.chord_tona ; 
      this.chord_type = this.no_score_selected_chord.chord_type ; 
      
      this.no_score_selected_chord.full_chord = Chord.get(this.chord_tona+' '+this.chord_type);
      this.no_score_selected_chord.full_chord.midi_tab = [];
      this.no_score_selected_chord.full_chord.unfiltered_midi = [];
      this.light_chord(this.no_score_selected_chord.full_chord);
      
    }else{
      this.light_chord_from_score(this.cur_selection_measure);
      this.chord_tona = this.cur_chord_full.tonic;
      this.chord_type = this.cur_chord_full.type;
    }
  }

  public toggle_scale_follow_score(){
    if(!this.scale_follow_score ){ 
      this.scale_tona = this.no_score_selected_scale.scale_tona;
      this.scale_type = this.no_score_selected_scale.scale_type;
      // this.set_scale( this.no_score_selected_scale.full_scale );
      this.refresh_scale();
    }else{
      this.refresh_from_selected_score_scale(this.cur_selection_measure);
      // this.set_scale_for_selected_measure();
    }
  }

  public toggle_midi(){

    this.lightsOff();

    if(!this.score_follow ){ 
      // this.light_chord(this.lit_chord);
      this.chord_tona = '';
      this.chord_type = '';
      // this.set_scale( this.no_score_selected_scale.full_scale );
      // console.log("this.lit_chord =>", this.lit_chord)
    }else{
      let m = this.sel.getSelection()[0];
      if(!m || m.getType() == 'part'){ return; }
      // this.set_scale_from_measure(m);
      this.light_chord_from_score(this.cur_selection_measure,this.cur_selection_beat);
    }
  }
  
  get_bulb_image(interval: string) {
    if (interval.endsWith('m')) return `minor/${interval}`;
    if (interval.endsWith('M')) return `major/${interval}`;
    if (interval.endsWith('d')) return `diminished/${interval}`;
    return interval;
  }

  public light(notes, intervals){
    
    // if(intervals.length > 5 )debugger
    intervals = intervals.map( (e)=>{ 
        let regexp = /([0-9]{1,2})(.*)/g;
        let res = regexp.exec(e);
        if(!res){
          return;
          debugger //////////////////////////REMOVE WHEN SCORE/MIDI DONE!!!
        }
        let i = Number(res[1]);
        let letter = res[2];
        while(i > 15) i -= 7;
        // console.log("PIANOOOOOO = > ",i)
        return i+letter;
     } )

    for(const [i,n] of notes.entries()){
      this.notes[n].display = true;
      this.notes[n].image = this.get_bulb_image(intervals[i]);

    } 
  }

  public light_chord_from_score( measure, beat?:number ){

    this.lightsOff();

    let chord = null;

    
    // If the measure has no chord, return
    if(!measure.chords.replace(/\s/g, '').length){
      this.chord_tona = '';
      this.chord_type = '';
      return
    }

    let chords = measure.chords.split(' ');
    if(chords.length) {
      if(beat!=undefined){

        if(beat<chords.length){

          chord=chords[beat].split('/')[0];

        }else{ 
          chord = '';
        }

      }else{
        
        chord = chords[0].split('/')[0];
      }

    }
    this._chordName = (chord!='')?chord:this._chordName;

    let c;
    if(this._chordName) c = Chord.get(this._chordName);
    if(c==undefined)return;
    this.cur_chord_full = c;
    this.zone.run(()=>{
      this.light_chord(c);
    });

  }

  public light_chord(c){
    
    let notes = c.notes
    let full_notes = [];
    for(let i = 2; i < 7; i++){
      full_notes = full_notes.concat( notes.map((e)=>{return e+i.toString()}) );
    }
    
    full_notes = full_notes.map(Note.midi);

    let detected_chord = this.cd.detectMidi_as_chord(full_notes);
    
    let midi_notes = (detected_chord.full_chord as any).midi_notes;
    let intervals = (detected_chord.full_chord as any).intervals;
    
    this.chord_tona = c.tonic;
    this.chord_type = c.type;

    this.lightsOff();
    this.light(midi_notes, intervals);
    
  }

  /*
  play the note clicked
   */
  public play(e){
    let id = e.target.attributes.id.nodeValue;
    let midiNote = Number(id.replace('note_',''));

    this.sf.playMidiNote(midiNote);

    let note_bkup = JSON.parse(JSON.stringify(this.notes[midiNote]));

    this.light([midiNote],['R']);
    
    setTimeout(()=>{
      this.notes[midiNote] = note_bkup;
    },800)
  }

  // *************************************
  // *************************************
  // !!!! SEB : FINISH THIS PLS.... !!!!!
  // *************************************
  // *************************************

  // *************ORIGINAL FUNCTION******************
  // public scale_clicked(){ 
    
  //   this.refresh_scale();

  //   if(this.score_follow){
  //     this.set_scale_for_selected_measure();
  //     this.send_refresh_scale();
  //   } else{
  //     this.no_score_selected_scale.scale_tona = this.scale_tona; 
  //     this.no_score_selected_scale.scale_type = this.scale_type; 
  //   }

  // }

  public scale_clicked(){
    
    
    // only if in score follow mode (not in midi mode)
    if(this.scale_follow_score) {
      // this.set_scale_caged_for_selected_measure();
      // this.send_refresh_scale();
      this.no_score_selected_scale.scale_tona = this.scale_tona; 
      this.no_score_selected_scale.scale_type = this.scale_type; 
      // this.set_scale_for_selected_measure();
      // this.no_score_selected_scale.full_scale = this.cur_scale_full;
    } else{
      this.no_score_selected_scale.scale_tona = this.scale_tona;
      this.no_score_selected_scale.scale_type = this.scale_type;
      // this.cur_scale_full = this.no_score_selected_scale.full_scale;
    }
    this.refresh_scale();

  }


  public chord_clicked(){
    
    
    // only if in score follow mode (not in midi mode)
    if(!this.chord_follow_score) {
      
      this.no_score_selected_chord.chord_tona = this.chord_tona; 
      this.no_score_selected_chord.chord_type = this.chord_type; 
      this.no_score_selected_chord.full_chord = Chord.get(this.chord_tona+" "+this.chord_type);
      this.no_score_selected_chord.full_chord.midi_tab = [];
      this.no_score_selected_chord.full_chord.unfiltered_midi = [];
      this.light_chord(this.no_score_selected_chord.full_chord);
    }

  }
  // *************************************
  // *************************************
  // !!!! THANNNNNNNNNNNNNNNNXXXXXXX !!!!!
  // *************************************
  // *************************************


  public send_refresh_scale(){
    this.sel.updateSelection();
  }

  public refresh_scale(){

    let s = Scale.get(this.scale_tona+' '+this.scale_type.toLowerCase());
    if(s.empty)console.warn('refresh_scale() : NO SCALE FOUND !')

    this.active_scale = [... s.notes]; //active_scale is a copy of notes found in the new scale
    
    // ----------------Modification affichage des #/b/##/bb-------------------------------------------
    
    //Par défault on choisis d'afficher les notes avec des bémols
    this.notes_active = [... this.notes_flat];
    
    // si dans la nouvelle gamme choisie il y a des # alors on remplace les bémols affichés sur le fretboard par des #
    for( let n of this.active_scale)
      if(n.indexOf('#')!=-1){
        this.notes_active = [... this.notes_sharp]; // Copy of this.notes_sharp
        break;
      }
      
    // Si la nouvelle gamme contient des doubles altérations, alors on remplace les textes du fretboard par ces doubles altérations.
    for( let n of this.active_scale){
      if(n.indexOf('bb')!=-1 || n=='Cb' || n == 'Fb'){
        this.notes_active = this.mu.replace_double_alterations_piano(n,this.notes_active);
      }
    }

    for( let n of this.active_scale ){
      if(n.indexOf('##')!=-1 || n=='B#' || n == 'E#'){
        this.notes_active = this.mu.replace_double_alterations_piano(n, this.notes_active);
      }
    }

    // -----------------------On compose les textes affichés en mode degrees--------------------------
    
    this.active_intervals = [... s.intervals];
    
    this.active_intervals = this.active_intervals.map((e)=>{
      let r =  e.replace('M','').replace('m','b').replace('P','').replace('d','b').replace('A','#')
      return this.reverse(r);
    })
    // -----------------------------------------------------------------------------------------------

    this.active_scale_quality = this.mu.get_scale_quality(s.type);
    
  }//END of refresh_scale


  // function to reverse a string ('abcde' ==> 'edcba')
  public reverse = s => [].reduceRight.call(s, (a, b) => a + b);


  public close_piano(toggle:boolean = false){
    if(toggle)
      this.visible = !this.visible;
    else this.visible = false;
      this.displayChange.emit(this.visible);
  }

  public get_class_active(idx:number){
    return this.notes[idx].display;
  }

  public get_class_glow(idx:number){
    return this.notes[idx].glow;
  }

  public unlight_all_glow(){
    for(let i = 21; i <= 108; i++) 
      if(this.notes[i]){
        this.notes[i].glow = false; 
        this.notes[i].greyed = false;
      }

  }

  public toggle_piano_score_follow(){
    this.score_follow = !this.score_follow;
    // this.update_score_follow();
  }

  public toggle_piano_losanges(){
    this.activeLosanges = !this.activeLosanges;
  }

  public toggle_piano_chordtones(){
    this.activeBubbles = !this.activeBubbles;
  }

}






@Component({
  selector: '[piano-diamond]',
  styleUrls: ['./piano.component.scss'],
  template: `<svg:path d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z" /><svg:text x="45%" y="60%" class="chords_notes_labels piano">{{text}}</svg:text>`
})


export class PianoDiamondComponent {
  @Input() x: number = 0;
  @Input() y: number = 0;
  @Input() text: string = '';
}