<section id="donate">
	<div class="txt">
		<p><b>DONATE :</b> Support us with the site development</p>
	</div>
	<div class="btns">
		<button (click)="show_payment_modal('pick')" name="don-pick" id="don-pick" value="5"><img src="assets/img/donate/pic.svg" title="Buy me guitar picks! (5$)"></button>
		<button (click)="show_payment_modal('strings')" name="don-strings" id="don-strings" value="10"><img src="assets/img/donate/str.svg" title="Buy me guitar strings! (10$)"></button>
		<button (click)="show_payment_modal('metronome')" name="don-metronome" id="don-metronome" value="20"><img src="assets/img/donate/met.svg" title="Buy me a metronome! (20$)"></button>
		<button (click)="show_payment_modal('ukulele')" name="don-ukulele" id="don-ukulele" value="50"><img src="assets/img/donate/uku.svg" title="Buy me a ukulele! (50$)"></button>
		<button (click)="show_payment_modal('pedal')" name="don-pedal" id="don-pedal" value="100"><img src="assets/img/donate/ped.svg" title="Buy me a guitar pedal! (100$)"></button>
		<button (click)="show_payment_modal('other')" name="don-other" id="don-other" value="0"><img src="assets/img/donate/dot.svg" title="Buy me anything you want! (other amount)"></button>
	</div>
	
  <!-- Modal -->
  <div class="fakebook-modal modal" id="paymentModal" [hidden]="modalPayment.hide" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="paymentModalLabel">DONATE: <span>{{symbol}}</span></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalPayment.hide=true;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div id="paymentModal-body" class="modal-body">
					<div [hidden]="!modalPayment.loading" class="spinner3">
						<div class="double-bounce1"><img src="assets/img/music-placeholder-glow.png"></div>
						<div class="double-bounce2"><img src="assets/img/music-placeholder-glow.png"></div>
					</div>

					<div [hidden]="modalPayment.loading">
						<div> Amount : <input type="number" id="amount_box" [(ngModel)]="amount"/> </div>
						<div id="paypal-button-container" style="overflow: auto;max-height:600px;"></div>
					</div>
        </div>

      </div>
    </div>
  </div> <!-- Modal -->



  <div class="fakebook-modal modal" id="paymentSuccessModal" [hidden]="modalPaymentSuccess.hide" tabindex="-1" role="dialog" aria-labelledby="paymentModalSuccessLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="paymentSuccessModalLabel">Success</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalPaymentSuccess.hide=true;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

			    <div> Thank you for your help :) </div>
				 	<div> Paid : {{amount}} </div>
				 	<div> Your transaction number : {{transaction_number}} </div>


        </div>
        <div class="modal-footer">
			<button id="mySubmit" class="btn btn-primary" (click)="modalPaymentSuccess.hide = true">Done</button>
        </div>
      </div>
    </div>
  </div> <!-- Modal -->
	
</section>