<div id="exercice-rythm">

  <ng-container *ngIf="debug_mode">

    <div>
      <label for="#input_right">Right hand</label>
      <input id="input_right" class="text_input" type="text" [(ngModel)]="right_hand_string" (input)="update_score()" (change)="update_score()">
    </div> 
    <div>
      <label for="#input_left">Left hand</label>
      <input id="input_left" class="text_input" type="text" [(ngModel)]="left_hand_string" (input)="update_score()" (change)="update_score()">
    </div>  

    <label for="#input_transpose">Transpose </label>
     <input id="input_transpose" type="number" min="-6" max="6" [(ngModel)]="transpose" (change)="update_score()">

  </ng-container>
 

  <div id="abcjs-wrapper">
    <div id="abcjs-paper" width="200px"> </div>
  </div>

  <div [hidden]="!debug_mode" id="synthControl" ></div>

  <app-metro class="metronome"></app-metro>
  <app-fifth-circle [score_chord]="cur_chord" class="fifth_circle"></app-fifth-circle>

  <button (click)="debug_fct([58],[60])"> debug </button>
</div>
