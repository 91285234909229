import { Injectable } from '@angular/core';

import { Note, Chord, Scale, Key, AbcNotation, Interval, Progression, RomanNumeral } from "@tonaljs/tonal";

import { Global_gen_params, Tonalities, Modes, Chords_gen_params, Notes_gen_params } from "./exo-gen-data_structures";

import { Default } from "./params_templates/exo-gen-data_structures_default";
import { ContrepointService } from "@services/exercises/exercise_generator/contrepoint.service";
import { RhythmGenerationService } from "@services/exercises/exercise_generator/rhythm-generation.service";
import { ChordGenService } from "@services/exercises/exercise_generator/chord-gen.service";
import { ProgressionService } from "@services/exercises/exercise_generator/progression.service";
import { MelodyGenService } from "@services/exercises/exercise_generator/melody-gen.service";
import { MeasureSplitService } from "@services/exercises/exercise_generator/measure-split.service";

import '../../music-utils-service/number.extensions';
import novaxeAPI, { genericAPI } from 'src/app/utils/novaxeAPI';

export const N = Note;
export const C = Chord;
export const S = Scale;
export const K = Key;
export const A = AbcNotation;
export const I = Interval;
export const P = Progression;
export const R = RomanNumeral;


@Injectable({
  providedIn: 'root'
})
export class ExoGenService {

  public json_generated = {
    templates:[],
    exo_parameters:{}
  };

  //in here we load params from templates.
  public loaded_exo_params:any; //copy of exercise parameters


  constructor(private contrepoint:ContrepointService, 
              private rhythmService:RhythmGenerationService, 
              private chord:ChordGenService, 
              private progressionService:ProgressionService, 
              private melodyService:MelodyGenService,
              private measureSplitService:MeasureSplitService
              ) { 

    this.loaded_exo_params = new Default();
    this.load_parameters_from_template();
  }












  

  public async load_parameters_from_template(template:string="DEFAULT", callback=null){

    let exo:any;

    // If we are about to load the same template just dont do it. Call callback direct
    if(template == this.loaded_exo_params.template && callback){
        callback();
        return;
      }


    
    // HTTP REQUEST TEMPLATE=================================
    let file_name = template;
    // console.log('template => ',template);
    const resp = await genericAPI<any>('GET', '/assets/exercises/levels/'+file_name+'.json');
    if (!resp.status) {
      console.error(resp.message);
      return;
    }

    console.log('%c loaded params from template : '+template,'background-color:green');
    this.loaded_exo_params = resp.data;
    if (callback != null) callback();
  }












  


  //generates JSON form of succeding 
  //templates with params, depending on params.
  public generate(options:any={}){


    this.json_generated = { templates:[], exo_parameters:this.loaded_exo_params };




    // Generating successive templates.
    for( let t=0; t < this.loaded_exo_params.global_gen_params.duration; t++){

      var template_type = this.loaded_exo_params.exercise_types_selected.get_rand_in_obj();
      // console.log('template_type => ',template_type);
      if(this.loaded_exo_params.TEMPLATE == "FULL_SCORE")template_type = "full_score_template"

      let template;
      if(template_type == 'full_score_template')

         template = {template_type:"full_score", abc_file:this.loaded_exo_params.FILE, abc_string:"abc"}; 

      else if( template_type == 'reading_untimed' || template_type == 'reading_timed' || template_type == 'hearing')

          template = this.generate_music_template2(template_type);

      else debugger


      this.json_generated.templates.push( template );
    }

    // Add instruction template
    if(
    this.loaded_exo_params.TEMPLATE.match(/LEVEL_*/) 
    && !this.loaded_exo_params.DEBUG_MODE
    && !(options.hasOwnProperty('add_instructions') && options['add_instructions'] == false) ) 
    this.json_generated.templates.unshift({template_type:"template_instr_lvl1", version:"timed"});

    // Adding countdown template
    // if(this.loaded_exo_params.DEBUG_MODE == false) this.json_generated.templates.unshift({template_type:"template_countdown", count:3});




    // this.json_generated.exo_parameters = this.loaded_exo_params;
    // console.log('PARAMS GENERATED =========\n\n')
    // console.log(JSON.stringify(this.loaded_exo_params))

    return this.json_generated;
  }












  

  public get_rand_clef():any{

    var G = this.loaded_exo_params.global_gen_params.clef_G;
    var F = this.loaded_exo_params.global_gen_params.clef_F;

    if(this.loaded_exo_params.global_gen_params.clef_OR){
      var clef    = ({clef_G:true,clef_F:true}).get_rand_in_obj(); //TODO RANDOM GET THAT !
      if(clef == "clef_G"){
        G = true;
        F = false;
      }else{
        G = false;
        F = true;
      }
    }

    return {voice_G:G, voice_F:F}
  }














  //Generates data (json formatted) that is consumed in chord template. 
  public generate_music_template2(template_type):any{

    let template_data        = {};
    let template_entities    = [];
    let template_entities_lh = [];


    var meter      = this.loaded_exo_params.global_gen_params.meter.split('/'); 
    var nb_measures   = this.loaded_exo_params.global_gen_params.measures;
    var tona       = this.loaded_exo_params.tonalities_selected.get_rand_in_obj(); //TODO RANDOM GET THAT !
    var mode       = this.loaded_exo_params.modes_selected.get_rand_in_obj(); //TODO RANDOM GET THAT !

    var clef       = this.get_rand_clef(); 
    var r_parameters_l = this.json_generated['exo_parameters']['rhythm_params']['left_hand'];
    var r_parameters_r = this.json_generated['exo_parameters']['rhythm_params']['right_hand'];


  // PROGRESSION GENERATION===================================================

    let possible_chords = this.json_generated['exo_parameters']['chords_gen_params']['chord_types_'+mode].get_all_true_in_obj() ;

    let progression = this.progressionService.generate_progression("C", mode, possible_chords, nb_measures,this.loaded_exo_params.chords_gen_params);
    console.log('progression => ',progression);

    // progression = this.contrepoint.generate_contrepoint_lines( progression, r_parameters_l, r_parameters_r, 3840,this.loaded_exo_params.global_gen_params.meter ,this.loaded_exo_params.chords_gen_params);


  // =========================================================================

  let rhythm_r = this.rhythmService.generate_measure_rythm7(r_parameters_r,3840*nb_measures);
  let rhythm_l = this.rhythmService.generate_measure_rythm7(r_parameters_l,3840*nb_measures);

  rhythm_r = this.measureSplitService.split_measures(rhythm_r, 3840, progression);
  rhythm_l = this.measureSplitService.split_measures(rhythm_l, 3840, progression);

  let rhythm_PARAMS = {left:rhythm_l, right:rhythm_r, };
  let voices = this.melodyService.generate_voice_from_rhythm(rhythm_PARAMS, nb_measures, progression, this.loaded_exo_params.voices_params);





  // MODIFICATIONS OF THE RESULT ===========================================



  // CHANGE LAST MEASURE TO WHOLE C
  let idx_last_measure = voices.right[ voices.right.length-1 ].measure_idx;
    
  for (var idx = voices.right.length-1; idx > 0; idx--) {
    if(voices.right[idx].measure_idx !- idx_last_measure){
      idx_last_measure = idx+1;
      break;
    }
  }

  voices.right = voices.right.splice(0,idx_last_measure+1);
  voices.right[voices.right.length-1].duration = 3840;
  voices.right[voices.right.length-1].duration_name = "whole";
  voices.right[voices.right.length-1].notes_names = 'C3';

  idx_last_measure = voices.left[ voices.left.length-1 ].measure_idx;
  for (var idx = voices.left.length-1; idx > 0; idx--) {
    if(voices.left[idx].measure_idx !- idx_last_measure){
      idx_last_measure = idx+1;
      break;
    }
  }


  voices.left = voices.left.splice(0,idx_last_measure+1)
  voices.left[voices.left.length-1].duration = 3840;
  voices.left[voices.left.length-1].duration_name = "whole";
  voices.left[voices.left.length-1].notes_names = 'C3';








  // REMOVE ONE HAND IF OPTION IS SELECTED
  var measure_type   = this.loaded_exo_params.measure_types_selected.get_rand_in_obj();

  if(measure_type == "simple_notes_right_hand")
    for(let e = 0; e < voices.left.length; e++){
      voices.left[e].is_rest = true;
      voices.left[e].is_drew = false;
    }
  else if(measure_type == "simple_notes_left_hand")
    for(let e = 0; e < voices.right.length; e++){
      voices.right.is_rest = true;
      voices.right.is_drew = false;
    }
  else if( measure_type == "chords_first_beat_lh_and_notes_rh"){
    throw "Error : chords_first_beat_lh_and_notes_rh is not done yet ;)";
  }



    console.log('%c rhythm_PARAMS => ','background-color:red;',rhythm_PARAMS);

    // ABC TRANSFORMATION=======================================================

    let header = '';
    header = '%%stretchlast .7' + '\n';
    if(clef.voice_G && clef.voice_F){

      // header += "%%staves {(PianoRightHand) (PianoLeftHand)}"+"\n";
      header += "V:PianoRightHand clef=treble down"+"\n";
      header += "V:PianoLeftHand clef=bass"+"\n";
    } else if (clef.voice_G){
      header += "%%staves {(PianoRightHand) }"+"\n";
      header += "V:PianoRightHand clef=treble down"+"\n";
    } else if(clef.voice_F){

      header += "%%staves {(PianoLeftHand)}"+"\n";
      header += "V:PianoLeftHand clef=bass"+"\n";
    }

    header += "M:"+this.loaded_exo_params.global_gen_params.meter+"\n";

    header += "Q:"+this.loaded_exo_params.global_gen_params.tempo+"\n";
    // header += "K:"+template_entities[0].tonality+"\n";
    let minor = (mode=="n_minor"||mode=="h_minor"||mode=="m_minor")?"m":"";
    header += "K:C"+minor; //HARD CODED FOR NOW




    let right = this.get_abc_voice(rhythm_PARAMS.right, 'G');

    let left = this.get_abc_voice(rhythm_PARAMS.left, 'F');

    left = "[V: PianoLeftHand]  "+left;
    right = "[V: PianoRightHand] "+right;



    let transpo = ['Ab','A','Bb','B','C', 'Db', 'D', 'Eb', 'E', 'F', 'F#','G'].indexOf(tona)-4;



    template_data = {
      header:header,
      right_hand:right,
      left_hand:left,
      template_type:template_type,
      timed:this.loaded_exo_params.global_gen_params.timed,
      one_by_one:this.loaded_exo_params.notes_gen_params.one_by_one,
      hide_function:this.loaded_exo_params.HIDE_FUNCTION,
      hide_chord:this.loaded_exo_params.HIDE_CHORD,
      voice_G:clef.voice_G,
      voice_F:clef.voice_F,
      tempo:this.loaded_exo_params.global_gen_params.tempo,
      sound:this.loaded_exo_params.global_gen_params.sound,
      tona:{transpo:transpo, tonality:tona},
      transpo:transpo,
      debug:this.loaded_exo_params.DEBUG_MODE
    }

    return template_data;
  } 















  private get_abc_voice(rhythm, voice:string='G'):string{

    let abc_value = {
      whole : '8',
      half : '4',
      quarter : '2',
      eights : '',
      sixteenth : '/2',
      d_whole : '12',
      d_half : '6',
      d_quarter : '3',
      d_eights : '3/2',
      d_sixteenth : '3/4',
    }

    let beat_duration = 960;
    let beat_count = 1;
    let duration_ttl = 0;

    let ttl_abc_string = "|";

    // ===========================================================
    // 
    //                       WRITE VOICE
    // 
    // ===========================================================

    for(let i = 0; i < rhythm.length; i++){

      let chunk = rhythm[i];
      let chunk_note = rhythm[i].note;
      let new_measure = ( duration_ttl%3840 == 0);



      // WRITE CHORD NAMES =====================================================
      if( new_measure )
      if(i < rhythm.length && !this.loaded_exo_params.HIDE_CHORD )
        if(voice == 'F')
          ttl_abc_string += "\"_"+ rhythm[i].harmony.chord_degree + "\"";
        else
          ttl_abc_string += "\""+ rhythm[i].harmony.chord_name + "\"";







      // WRITE CHORDS ===========================================================
      if( new_measure== true && voice == 'F' ){// chunk_note.entity_type == "chord"

       let abc_string = '';
       let notes_array = [];

       for(let n of chunk.harmony.notes_array){ // for each note

        let abc_notation = AbcNotation.scientificToAbcNotation(n);

        //if the note has a # or a b and it is in the armor : we remove # or b 
          let pitch = n.match(/([ABCDEFG][#b]*)/g);

          notes_array.push(n);
          abc_string+= abc_notation;
        }
        let dur = abc_value[ chunk.duration_name ];

        if(chunk.is_rest) 
          if(chunk.is_drew) ttl_abc_string +="z"+dur;
          else ttl_abc_string +="x"+dur;

        else ttl_abc_string +=("["+abc_string+"]"+dur);


        duration_ttl += chunk.duration;

        if( duration_ttl >= (beat_count+1)*beat_duration ){
          ttl_abc_string += " ";
          beat_count=duration_ttl/960;
        }

        if(duration_ttl%3840 == 0) ttl_abc_string+="|";



      // WRITE NOTES ===========================================================
      } else if(chunk_note.entity_type == "note" && this.loaded_exo_params.global_gen_params.clef_G){



        if(chunk.is_rest) 
          if(chunk.is_drew) ttl_abc_string +=("z"+ chunk.abc_string);
          else ttl_abc_string +=("x"+ chunk.abc_string);
        else{

          if( chunk.hasOwnProperty('is_tie') && chunk['is_tie'] == 'start' ){

           ttl_abc_string +="(";
           ttl_abc_string += A.scientificToAbcNotation(chunk_note.notes_names) + abc_value[ chunk.duration_name ];

          }else if( chunk.hasOwnProperty('is_tie') && chunk['is_tie'] == 'end' ){

            ttl_abc_string += A.scientificToAbcNotation(rhythm[i-1].note.notes_names) + abc_value[ chunk.duration_name ];
            ttl_abc_string +=")";

          }else{

           ttl_abc_string += A.scientificToAbcNotation(chunk_note.notes_names) + abc_value[ chunk.duration_name ];
          }
        }

        duration_ttl += chunk.duration;

        if( duration_ttl >= (beat_count+1)*beat_duration ){
          ttl_abc_string += " ";
          beat_count=duration_ttl/960;
        }

        if(duration_ttl%3840 == 0) ttl_abc_string+="|";
      }


    }

    return ttl_abc_string;
  }



}
