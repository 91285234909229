import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '@models/usermodel/usermodel';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

private key:string='';
private reset:string='';
private valid_link:boolean=false;

public user_email:string;
public user_pass:string;
public passverif:string;
public user_nick:string;

public show_pass:boolean = false;
public show_infos:boolean = false;

private getusrRes$:any;

  constructor(private route:ActivatedRoute, public user:UserModel, private router: Router) { 

    this.route.queryParamMap.subscribe((params) => { //*****Apparently NO NEED to UNSUBSCRIBE*****//
      this.key = params.get('key');
      this.reset = params.get('reset');
      this.get_user();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.getusrRes$.unsubscribe();
  }

  

  public async change_pass(){
    if(this.passverif != this.user_pass || this.valid_link != true) return;

    const resp = await this.user.update_user_pass(this.user_pass);
    if(resp.status) {
      $('#rst_infos').text('Password updated successfully');
      $('#rst_infos').css('color', 'var(--green_nvx)');
      setTimeout(()=>{this.router.navigate(['home']);}, 3000);
      return;
    }

    $('#rst_infos').text('A problem occurred while updating password. Please try again later.');
    this.show_infos=true;
  }

  private async get_user(){
    const resp = await this.user.get_user_infos(this.key, this.reset)

    if (!resp.status) {
      $('#rst_infos').text('This link is not valid, or has expired. Please try again later.');
      this.show_infos=true;
      return;
    }

    this.user_nick = this.user.get_nick();
    this.user_email = this.user.get_user_email();
    this.valid_link = true;
  }

  public showPass():void{
    this.show_pass = !this.show_pass;
  }

}
