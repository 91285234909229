<div class="font1 app-page" style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
	<h1>Level one</h1>
	<div>
		<h2>Instructions :</h2>
		<ul>
		<li>
			<h4>First lets be assured that your keyboard is plugged correctly.
			<br> If you see it in the menu here select it : 
			 <app-midi-selector ></app-midi-selector>
			<br>
			Otherwise you can try to plug it and refresh the page.
		</h4>
		</li>
		<li>
			<h4>Now you are going to be shown several series of notes on the score. <br>Try to play them right on your keyboard ! <br></h4>
		</li>
		<li>
			<h4>Timings are not measured in this exercise. So focus only on pitches.<br></h4>
		</li>
		</ul>
	</div>
	<div style="border-radius: 10px; border:2px solid black;" >
		<img class="preload" height="220px" src="/assets/exercises/instructions/notes2.gif">
		<img class="preload" height="220px" src="/assets/exercises/instructions/piano.gif">
	</div>
		<h2>Good luck :)</h2>
		<button id="start_button" class="btn btn-primary green" (click)="start()">Start</button>
</div>
