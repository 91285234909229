<section id="midi-chord-display" class="modal nofly" tabindex="-1" role="dialog" [hidden]="visible==false" (contextmenu)="close();$event.preventDefault();" >

    <div class="rightbuttons">
        <button type="button" class="close" data-dismiss="modal" (contextmenu)="close();$event.preventDefault();" (click)="close()" aria-label="Close"> 
            <span aria-hidden="true" class="fa fa-times"></span> 
        </button>
    </div>

    <div id="nvxMidi-chord-display" class="modal-dialog" role="document"> </div>

    
    <div id="midi_chord_schema"><app-midi-chord-detect-abc [abc_obj]="cur_midi_abc" [color]="'white'" [transpose]="(cm.is_midi_guitar())?12:0"></app-midi-chord-detect-abc></div>
    <div class="fifths_infos">
        <div id="midi_chord_name" app-midi-chord-detect-simple [midi_chord]="cur_midi_chord" [color]="'white'"></div>
    </div>

</section>  
