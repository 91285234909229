<div class="app-page" >

  <div [hidden]="!display_preview" id="templateContainer" style="width:100%; overflow-x: hidden;" >

    <i class="fa fa-times" style="position:fixed; right:30px; font-size:3em;z-index: 100;cursor: pointer;" (click)="display_preview=false" ></i>

    <div style="height: 655px;" width="100%">
      <ng-container #viewContainer > </ng-container>
    </div>
    
  </div>


  <div id="start_preview_row"> 
    <button id="start_button" class="btn btn-primary mx-3" (click)="start()" autofocus>Start</button> 
    <button id="preview_button" class="btn btn-primary mx-3" (click)="preview()" autofocus>Preview</button> 
  </div>

<br>

<div class="row">
  <div class="p-2 card col-6">
    <table>
       <tr>
         <td><label for="midi_selector" >MIDI input</label></td>
         <td><app-midi-selector id="midi_selector"></app-midi-selector></td>
       </tr>
        <tr>
          <td><label for="template_selector" >Load template</label></td>
          <td>
            <select style="width:186px" id="template_selector" (change)=load_template(template_selected) [(ngModel)]="template_selected">
              <option *ngFor="let t of template_options" [value]="t" >{{t}}</option>
            </select>
            <button class="btn btn-sm btn-primary mb-1 mx-3" (click)="log_template()">LOG</button>
        </td>
        </tr>

       <tr>
       <td><label for="debug_mode" >Debug mode</label></td>
       <td><input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.DEBUG_MODE" id="debug_mode" ></td>
       </tr>

       <tr>
       <td><label for="hidechord" >Hide chords</label></td>
       <td><input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.HIDE_CHORD" id="hidechord" name="hidechord"></td>
       </tr>

       <tr>
       <td><label for="hidefunc" >Hide functions</label></td>
       <td><input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.HIDE_FUNCTION" id="hidefunc" name="hidefunc"></td>
       </tr>

       <tr>
       <td><label for="sound" >Sound</label></td>
       <td><input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.global_gen_params.sound" id="sound" name="sound"></td>
       </tr>
    </table>  

 </div>

  <div class="col-6 card">
   <p class="h5" >Duration</p> 
   <table >
    <tr>
       <td>
        <label for="duration"  >Number of slides</label> 
       </td>
       <td>
        <input [(ngModel)]="gen.loaded_exo_params.global_gen_params.duration" type="number" min="1" max="10" value="1" id="duration">
       </td>
    </tr>
    <tr>
       <td>
          <label for="measures" >Measures per slides</label> 
       </td>
       <td>
         <input [(ngModel)]="gen.loaded_exo_params.global_gen_params.measures" type="number" min="1" max="10" value="1" id="measures">
       </td>
    </tr>
      <tr>
       <td>
        <label for="tempo" >Tempo</label> 
       </td>
       <td>
         <input [(ngModel)]="gen.loaded_exo_params.global_gen_params.tempo" type="number" min="50" max="160" value="90" id="tempo">
       </td>
    </tr>
    <tr>
       <td>
         <label for="meter" >Meter</label> 
       </td>
       <td>
         <input [(ngModel)]="gen.loaded_exo_params.global_gen_params.meter" id="meter" disabled="disabled" >
       </td>
    </tr>
    <tr>
       <td>
         <label for="timed" >Timed</label> 
       </td>
       <td>
       <input class="big-checkbox" [(ngModel)]="gen.loaded_exo_params.global_gen_params.timed" type="checkbox" id="timed">
       </td>
    </tr>

   </table>  

   </div>
  </div> <!-- row -->


 <div class="row d-flex">
 <div class="col-6 card">
  <p class="h5">Tonalities</p>
  <div class="tonalities p-2">
     <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.tonalities_selected.Bb" name="tona_Bb" id="tona_Bb">
     <label for="tona_Bb" >Bb</label>
   
     <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.tonalities_selected.F" name="tona_F" id="tona_F">
     <label for="tona_F" >F</label>

     <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.tonalities_selected.C" name="tona_C" id="tona_C">
     <label for="tona_C" >C</label>

     <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.tonalities_selected.G" name="tona_G" id="tona_G">
     <label for="tona_G" >G</label>

     <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.tonalities_selected.D" name="tona_D" id="tona_D">
     <label for="tona_D">D</label>

     <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.tonalities_selected.E" name="tona_E" id="tona_E">
     <label for="tona_E">E</label>
  </div>
 </div>

<div class="col-6 card">

  <p class="h5 ">Modes</p>
  <table>
    <tr>
      <td> <label for="tona_major" >Major</label> </td>
      <td> <input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.modes_selected.major" id="tona_major"> </td>
    </tr>

    <tr>
      <td> <label for="tona_h_minor" >Harmonic minor</label> </td>
      <td> <input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.modes_selected.h_minor" id="tona_h_minor"> </td>
    </tr>

    <tr>
      <td> <label for="tona_n_minor" >Natural minor</label> </td>
      <td> <input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.modes_selected.n_minor" id="tona_n_minor"> </td>
    </tr>

    <tr>
      <td> <label for="tona_m_minor" >Melodic minor</label> </td>
      <td> <input class="big-checkbox" type="checkbox" [(ngModel)]="gen.loaded_exo_params.modes_selected.m_minor" id="tona_m_minor"> </td>
    </tr>
  </table>
 </div>
 </div>



<div>
</div>

<div class="row">
  <div class="col-6 card">
  <p class="h5" >Measure types</p> 

  <table>
    <tr>
      <td><input class="big-checkbox" [(ngModel)]="gen.loaded_exo_params.measure_types_selected.simple_notes_left_hand" type="checkbox" id="sp_n_l"></td>
      <td><label for="sp_n_l" class="p-2">Simple notes left hand</label></td>
    </tr>
    <tr>
      <td><input class="big-checkbox" [(ngModel)]="gen.loaded_exo_params.measure_types_selected.simple_notes_right_hand" type="checkbox" id="sp_n_r"></td>
      <td><label for="sp_n_r" class="p-2">Simple notes right hand</label></td>
    </tr>
    <tr>
      <td><input class="big-checkbox" [(ngModel)]="gen.loaded_exo_params.measure_types_selected.simple_notes_both_hands" type="checkbox" id="b_hds"></td>
      <td><label for="b_hds" class="p-2">Simple both hands</label></td>
    </tr>
    <tr>
      <td><input class="big-checkbox" [(ngModel)]="gen.loaded_exo_params.measure_types_selected.chords_first_beat_lh_and_notes_rh" type="checkbox" id="chrds_lh_rh"></td>
      <td><label class="p-2" for="chrds_lh_rh">Chords on first beat left hand with arpeggio</label></td>
    </tr>
    <tr>
      <td><input class="big-checkbox" type="checkbox" id="intervals"></td>
      <td><label for="intervals" class="p-2">Intervals</label></td>
    </tr>
  </table>
</div>

<div class="card col-6">
  <p class="h5" >Exercice type</p> 

  <table>
    <tr>
      <td><label class="p-2" for="chords">Reading untimed</label></td>
      <td><input [(ngModel)]="gen.loaded_exo_params.exercise_types_selected.reading_untimed" class="big-checkbox" type="checkbox" id="chords"></td>
    </tr>

    <tr>
      <td><label class="p-2" for="chords">Reading timed</label></td>
      <td><input [(ngModel)]="gen.loaded_exo_params.exercise_types_selected.reading_timed" class="big-checkbox" type="checkbox" id="chords"></td>
    </tr>

    <tr>
      <td><label class="p-2" for="chords">Hearing</label></td>
      <td><input [(ngModel)]="gen.loaded_exo_params.exercise_types_selected.hearing" class="big-checkbox" type="checkbox" id="chords"></td>
    </tr>
  </table>

</div> <!-- end of exo type -->
</div> <!-- end of this row -->

<div class="col-12 card">

  <p class="h5" >Rhythm types</p> 
  <div class="row">
    <div class="col-6 card " [class.active]="rhythm_section_to_show === 'lh'" (click)="rhythm_section_to_show='lh'">Left Hand</div>
    <div class="col-6 card " [class.active]="rhythm_section_to_show === 'rh'" (click)="rhythm_section_to_show='rh'">Right Hand</div>
  </div>

  <div [hidden]="rhythm_section_to_show=='lh'" class="row border-top-0 card">

    <table>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-01.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][0].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-09.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][8].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-17.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][16].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-02.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][1].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-10.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][9].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-18.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][17].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-03.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][2].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-11.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][10].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-19.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][18].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-04.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][3].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-12.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][11].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-20.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][19].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-05.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][4].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-13.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][12].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-21.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][20].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-06.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][5].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-14.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][13].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-22.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][21].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-07.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][6].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-15.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][14].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-23.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][22].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-08.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][7].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-16.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][15].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-24.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['right_hand'][23].proba"> </div> </td>
    </tr>
  </table>  
</div>

  <!-- <div class="border-bottom-0 card"> -->
  <div [hidden]="rhythm_section_to_show=='rh'" class="row border-top-0 card">
        <table>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-01.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][0].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-09.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][8].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-17.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][16].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-02.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][1].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-10.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][9].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-18.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][17].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-03.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][2].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-11.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][10].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-19.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][18].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-04.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][3].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-12.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][11].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-20.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][19].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-05.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][4].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-13.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][12].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-21.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][20].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-06.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][5].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-14.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][13].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-22.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][21].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-07.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][6].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-15.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][14].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-23.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][22].proba"> </div> </td>
    </tr>
      <tr>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-08.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][7].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-16.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][15].proba"> </div> </td>
        <td> <div class="slidecontainer"> <input type="range" min="0" max="100" class="slider" id="myRange" style="background-image: url('../../../../assets/img/rythms/Rhythm-4-1-24.png')" [(ngModel)]="gen.loaded_exo_params.rhythm_params['left_hand'][23].proba"> </div> </td>
    </tr>
  </table>  
  </div>

</div>

<div class="row card">

  <p class="h5 col-12" >Chords parameters</p> 

  <div class="col-12">
    <span class="p-2">Inversions:</span> 
    <span>
      <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.inversions._one" name="inversion_1">
      <label class="px-2" for="#inversion_1">1</label>

      <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.inversions._two" name="inversion_2">
      <label class="px-2" for="#inversion_2">2</label>

      <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.inversions._three" name="inversion_3">
      <label class="px-2" for="#inversion_3">3</label>

      <input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.inversions._four" name="inversion_4">
      <label class="px-2" for="#inversion_4">4</label>
    </span>
  </div>

  <div class="row">
  <div class='card col-3'>
    <span class="p-2">Major types:</span> 
    <table>
      <tr>
        <td><label class="px-2" for="maj_c_type_maj">Major</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.major" id="maj_c_type_maj"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="maj_c_type_min">Minor</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.minor" id="maj_c_type_min"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="maj_c_type_dom">Dominant</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.dominant" id="maj_c_type_dom"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="maj_c_type_7">Minor 7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.minor7" id="maj_c_type_7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="maj_c_type_Maj7">Maj7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.maj7" id="maj_c_type_Maj7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="maj_c_type_dim">Diminished</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.dim" id="maj_c_type_dim"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="maj_c_type_hdim">Half diminished</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_major.hdim" id="maj_c_type_hdim"></td>
      </tr>
    </table>
  </div>

  <div class="card col-3">
    <span class="p-2">Harmonic minor types:</span> 
    <table>
      <tr>
        <td><label class="px-2" for="harm_c_type_maj">Major</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.major" id="harm_c_type_maj"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_min">Minor</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.minor" id="harm_c_type_min"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_dom">Dominant</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.dominant" id="harm_c_type_dom"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_7">Minor 7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.minor7" id="harm_c_type_7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_Maj7">Maj7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.maj7" id="harm_c_type_Maj7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_dim">Diminished</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.dim" id="harm_c_type_dim"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_dim7">Diminished seventh</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.dim7" id="harm_c_type_dim7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_hdim">Half diminished</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.hdim" id="harm_c_type_hdim"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_aug">Augmented</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.aug" id="harm_c_type_aug"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="harm_c_type_minmaj7">Minor major 7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.mMaj7" id="harm_c_type_minmaj7"></td>
      </tr>
      <tr>
        <td><label class="px-2" for="harm_c_type_augmaj7">Augmented major 7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_h_minor.augMaj7" id="harm_c_type_augmaj7"></td>
      </tr>
    </table>

  </div>

  <div class="card col-3">
    <span class="p-2">Natural minor types:</span> 
    <table>
      <tr>
        <td><label class="px-2" for="nat_c_type_maj">Major</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_n_minor.major" id="nat_c_type_maj"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="nat_c_type_min">Minor</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_n_minor.minor" id="nat_c_type_min"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="nat_c_type_dom">Dominant</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_n_minor.dominant" id="nat_c_type_dom"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="nat_c_type_7">Minor 7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_n_minor.minor7" id="nat_c_type_7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="nat_c_type_Maj7">Maj7</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_n_minor.maj7" id="nat_c_type_Maj7"></td>
      </tr>

      <tr>
        <td><label class="px-2" for="nat_c_type_dim">Diminished</label></td>
        <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_n_minor.dim" id="nat_c_type_dim"></td>
      </tr>
    </table>

  </div>
    <div class="card d-inline-flex col-3">
      <span class="p-2">Melodic minor types:</span> 
      <table>
        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.major" id="mel_c_type_maj"></td>
          <td><label class="px-2" for="mel_c_type_maj">Major</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.minor" id="mel_c_type_min"></td>
          <td><label class="px-2" for="mel_c_type_min">Minor</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.dominant" id="mel_c_type_dom"></td>
          <td><label class="px-2" for="mel_c_type_dom">Dominant</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.minor7" id="mel_c_type_7"></td>
          <td><label class="px-2" for="mel_c_type_7">Minor 7</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.dim" id="mel_c_type_dim"></td>
          <td><label class="px-2" for="mel_c_type_dim">Diminished</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.hdim" id="mel_c_type_hdim"></td>
          <td><label class="px-2" for="mel_c_type_hdim">Half diminished</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.aug" id="mel_c_type_aug"></td>
          <td><label class="px-2" for="mel_c_type_aug">Augmented</label></td>
        </tr>

        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.mMaj7" id="mel_c_type_minmaj7"></td>
          <td><label class="px-2" for="mel_c_type_minmaj7">Minor major 7</label></td>
        </tr>
        <tr>
          <td><input type="checkbox" [(ngModel)]="gen.loaded_exo_params.chords_gen_params.chord_types_m_minor.augMaj7" id="mel_c_type_augmaj7"></td>
          <td><label class="px-2" for="mel_c_type_augmaj7">Augmented major 7</label></td>
        </tr>
  
      </table>
    </div>
    </div>
</div>




<div class="exercise_type">
  <div>
    <label for="arpeggios" class="p-2">Arpeggios : </label>
    <input class="big-checkbox" type="checkbox" name="arpeggios" [(ngModel)]="gen.loaded_exo_params.notes_gen_params.arpeggios"> 

    <label for="one_by_one" class="p-2">One by one notes : </label>
    <input class="big-checkbox" type="checkbox" name="one_by_one" [(ngModel)]="gen.loaded_exo_params.notes_gen_params.one_by_one"> 

  </div>
</div>

<div class="exercise_type">
</div>

</div>
