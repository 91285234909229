import { Measure } from '@models/songmodel/measure';
import { METERS } from '@models/songmodel/Meters';

import { Key } from "@tonaljs/tonal";

export class Part {

	private idx:number=-1;
  private type:string='part';

	private title:string;
	private tonality:string;
	private meter:string;

	private measures:Array<Measure>;

	private measures_hash:any;
	private measures_max_lines:number;

	public collapse:true;

	constructor(){
		this.title = 'New part';
		this.tonality='';
		this.meter='4/4';
		this.measures = [];
	}


	//--------getters/setters
	public getIdx(){
		return this.idx;
	}
	public setIdx(x:number){
		this.idx = x;
	}
	
  public getType(){
    return this.type;
  }

	public getTitle(){
		return this.title;
	}
	public setTitle(x:string){
		this.title = x;
	}

	public getMeasures(){
		return this.measures;
	}
	public getMeasure(x:number){
		return this.measures[x];
	}
	public setMeasures(x:Array<Measure>){
		this.measures = x;
	}
	public addMeasure(x:Measure){
		this.measures.push(x);
	}
	public addMeasureAfter(idx:number,x:Measure, hash?:any){
		x.setIdx(this.measures[idx].getIdx()+1);
		this.measures.splice(idx+1,0,x);
		if(hash){
			this.measures_hash[hash.id] = hash;
		}
	}
	public addNewMeasure(){
		let m = new Measure();
    m.setTonality(this.tonality);
		this.measures.push(m);
		return m;
	}
	public deleteMeasure(m){
		this.measures.splice(m,1);
	}
	public deleteMeasureById(id){

		for(let i = 0; i < this.measures.length; i++){
			if(this.measures[i].getIdx() == id){
				this.deleteMeasure(i);	
			}
		}
	}
	public getLastMeasure(){
		return this.measures.slice(-1)[0];	
	}

	public getTonality(){
		return this.tonality;
	}
	public setTonality(x:string){
		this.tonality = x;
	}


	public getMeter(){
		return this.meter;
	}
	public setMeter(x:string){
		if(METERS.indexOf(x)<0)x = '4/4';
		this.meter = x;
	}
	public setBeatsFromMeter(){
		for(let m of this.measures){
			m.setMeter(this.meter);

			m.setBeatsFromMeter();
		}
	}

	public check_meter(){
		
		//if not a valid meter, we change it to 4/4 and return the bad one.
		if(METERS.indexOf(this.meter)<0){
			let out = this.meter;
			this.meter = '4/4';
			return out;
		}
		
		return 0;
	}

	public init_missing_measures_meters(){
		for(let m of this.measures){
			if(m.getMeter() == null){
				m.setMeter(this.meter);
				m.setBeatsFromMeter();	
			}	
		}
	}

	public getMeasures_hash(){
		return this.measures_hash;
	}

	public setMeasures_hash(hash){
		this.measures_hash = hash;
	}

	public getMeasures_max_lines(){
		return this.measures_max_lines;
	}

	public renderWithLyrics(){
    // console.log('renderWithLyrics');
		let str = "";
		let lyc = "";
    let tab = {};
		for(let i = 0; i < this.measures.length; i++){
      // console.log('renderWithLyrics',i);
      // console.log('renderWithLyrics',this.measures[i]);
			str += this.measures[i].render();
			lyc += this.measures[i].renderLyrics();
			tab[this.measures[i].getId()] = {id:this.measures[i].getId(),idx:i, part:this.idx, meas:i};
		}

		let count = 0;
		// if(this.measures.length)count = this.measures[0].getIdx();
		
    let str_lyc 	= "|:";
		//render and glue score/lyrics together.

    let str_lines = str.split('\n');
    // console.log('str_lines',str_lines);
    let lyc_lines = lyc.split('\n');
    //FOR each line in part
    for(var line = 0; line < str_lines.length; line++){
    	let score = str_lines[line];
    	let lyrics = lyc_lines[line];

	  	//replace final bar with :|
    	if(line == str_lines.length-1){
	    	score=score.slice(0,-1);
    		score+=":|";	
    	}

      // console.log('score',score);

    	// str_lyc += score +"\n"+"w:"+lyrics+"\n"; //temporarily remove lyrics.
      str_lyc += score +"\n";


      // for each measure on this line
    	var nb_m_onLine = (str_lines[line]. match(/\|/g) || []).length;
    	for(let j=0; j<nb_m_onLine;j++){
			//    id is used to identify the measure in the hash
    		var id=(this.measures[count].getId());
    		if(line==0)tab[id]['part_first_line'] = true;
			tab[id]['lig']=line;	
    		tab[id]['pos']=j;
    		tab[id]['id']=id;
			count++;
    	}
    	// count+=nb_m_onLine;

      // Not sure why this can happen, but it does, so heres a bandaid.
      if (str_lyc.includes('|\n:|'))
        str_lyc = str_lyc.replace('|\n:|',':|');
    }
    this.measures_max_lines = line;
    this.measures_hash = tab;
    // debugger

    // console.log('str_lyc',str_lyc);

  	//case of final \n remove it
  	if(str_lyc.slice(-1)=='\n'){
			str_lyc = str_lyc.slice(0,-1);
  	}

  	let out = "P:"+this.title+"\n"
  	out+="K:"+this.tonality+"\n";
  	out+="M:"+this.meter+"\n";
  	out+="[V: V1]"+"\n";
  	out+=str_lyc;
  	// console.log("out => ",out);

		return out;
	}
	public renderWithLeftHand(){

		let str = "";
		let lyc = "";
    let tab = {};
		for(let i = 0; i < this.measures.length; i++){
			str += this.measures[i].render();
			lyc += this.measures[i].renderLyrics();
			tab[this.measures[i].getIdx()] = {idx:i, part:this.idx, meas:i};
		}

		let count = 0;
		if(this.measures.length)count = this.measures[0].getIdx();
		
    let str_lyc 	= "|:";
		//render and glue score/lyrics together.
    let str_lines = str.split('\n');
    let lyc_lines = lyc.split('\n');
    for(var i = 0; i < str_lines.length; i++){
    	let score = str_lines[i];
    	let lyrics = lyc_lines[i];

	  	//replace final bar with :|
    	if(i == str_lines.length-1){
	    	score=score.slice(0,-1);
        if(score.slice(-1) == '\n')score = score.slice(0,-1);
    		score+=":|";	
    	}

    	// str_lyc += score +"\n"+"w:"+lyrics+"\n"; //temporarily remove lyrics.
      str_lyc += score +"\n";


    	let nb_m_onLine = (str_lines[i]. match(/\|/g) || []).length;
    	for(let j=0; j<nb_m_onLine;j++){
    		let id=(count+j);
    		if(i==0)tab[id]['part_first_line'] = true;
    		tab[id]['lig']=i;
    		tab[id]['pos']=j;
    	}
    	count+=nb_m_onLine;

      // Not sure why this can happen, but it does, so heres a bandaid.
      if (str_lyc.includes('|\n:|'))
        str_lyc = str_lyc.replace('|\n:|',':|');
    }
    this.measures_max_lines = i;
    this.measures_hash = tab;



  	//case of final \n remove it
  	if(str_lyc.slice(-1)=='\n'){
			str_lyc = str_lyc.slice(0,-1);
  	}

  	let out = "P:"+this.title+"\n"
  	out+="K:"+this.tonality+"\n";
  	out+="M:"+this.meter+"\n";
  	out+="[V: V1]"+"\n";
  	out+=str_lyc;
  	// console.log("out => ",out);

		return out;
	}

	public renderLeftHand(){

		let str = "";
		let lyc = "";
    let tab = {};
		for(let i = 0; i < this.measures.length; i++){
			str += this.measures[i].renderLeftHand();
			// lyc += this.measures[i].renderLyrics();
			tab[this.measures[i].getIdx()] = {idx:i, part:this.idx, meas:i};
		}

		let count = 0;
		if(this.measures.length)count = this.measures[0].getIdx();
		
    let str_tot 	= "|:";
		//render and glue score/lyrics together.
    let str_lines = str.split('\n');
    for(var i = 0; i < str_lines.length; i++){
    	let score = str_lines[i];

	  	//replace final bar with :|
    	if(i == str_lines.length-1){
	    	score=score.slice(0,-1);
        if(score.slice(-1) == '\n')score = score.slice(0,-1);
    		score+=":|";	
    	}

      str_tot += score +"\n";


    	let nb_m_onLine = (str_lines[i]. match(/\|/g) || []).length;
    	for(let j=0; j<nb_m_onLine;j++){
    		let id=(count+j);
    		if(i==0)tab[id]['part_first_line'] = true;
    		tab[id]['lig']=i;
    		tab[id]['pos']=j;
    	}
    	count+=nb_m_onLine;

      // Not sure why this can happen, but it does, so heres a bandaid.
      if (str_tot.includes('|\n:|'))
        str_tot = str_tot.replace('|\n:|',':|');
    }
    this.measures_max_lines = i;
    this.measures_hash = tab;



  	//case of final \n remove it
  	if(str_tot.slice(-1)=='\n'){
			str_tot = str_tot.slice(0,-1);
  	}

  	let out = "P:"+"\n"
  	// out+="K:"+this.tonality+"\n";
  	out+="M:"+this.meter+"\n";
  	out+=str_tot;
  	// console.log("out => ",out);

		return out;
	}
  /*
  compute analysis based on chords and tonality.
  Sets analysis for every measure (delete what's already there).
   */
  public compute_analysis(){

    let degrees; 
    // let diatonic = ['A','B','C','D','E','F','G'];
    // let rot = diatonic.indexOf(this.tonality[0]);

    // console.log(Key.majorKey(this.tonality));
    // this.mu.getDiatonicScale(this.tonality[0]);
    //rotates the array diatonic to get the tonality diatonic scale. Ex :in Dmaj => ["D", "E", "F", "G", "A", "B", "C"]
    // let tonality_diatonic_scale = diatonic.slice(rot, diatonic.length).concat(diatonic.slice(0, rot));

    let isMinor = this.tonality.indexOf('m') >= 0;
    let key;
    let tonality_diatonic_scale;
    if(!isMinor){
      let k = Key.majorKey(this.tonality);
      tonality_diatonic_scale = k.scale;
      degrees = k.grades;

    } else{

      let k = Key.minorKey(this.tonality.replace('m',''));
      tonality_diatonic_scale = k.harmonic.scale;
      degrees = k.harmonic.grades;
    }

    let chordLine = '';
    for(let m of this.measures){

      let skipMeasure = false;
      chordLine = m.getChordsLine();

      // console.log('------------------');
      // console.log('chordLine => ',chordLine);
      // if(chordLine == '')continue; // if chord line is just a space, continue.
	  let analysis_line = '';
	  let split_chords = chordLine.split(/(\s)/);

	   for(let c of split_chords){

		   if(c=='')continue;
		   //   let root_tab = chordline.match(/([abcdefg][b#]*)/gm);
			let split_chord = c.match(/([ABCDEFG][b#]{0,2})(.*)/)
			
			if(split_chord == null ){

				// if(analysis_line[analysis_line.length-1] != ' ')
				analysis_line += ' ';

				continue;
			}
			let root= split_chord[1];
			let chord_type = split_chord[2];
	   
			if(root== null )continue; //if no chords in measure m, continue.
	   
			console.log('root_tab => ',root);
	   
			let degrees_tab = [];
			let d = degrees[ tonality_diatonic_scale.indexOf(root) ];
			
			
			if(chord_type[0] == "m"){
				if(d== undefined){
					skipMeasure = true;
					console.warn('can\'t find chord with root : ',root,' in tonality of ', this.tonality,' : ',tonality_diatonic_scale, ' skipping measure');
					continue;
				}
				d = d.toLowerCase();
				chord_type = chord_type.slice(1);//remove the m
			}else if(chord_type.indexOf('dim') != -1){
				if(d== undefined){
					skipMeasure = true;
					console.warn('can\'t find chord with root : ',root,' in tonality of ', this.tonality,' : ',tonality_diatonic_scale, ' skipping measure');
					continue;
				}
				d = d.toLowerCase();
			}
			// degrees_tab.push(d);
			console.log('degrees_tab => ',degrees_tab);
	   
			// chordLine = chordLine.replace(root,degrees);
			let c_analysis = d+chord_type;
			console.log("c_analysis =>", c_analysis)
			
			if(skipMeasure)
				analysis_line += '  ';
			else
				analysis_line += c_analysis;
	   }

      m.setAnalysisLine(analysis_line);
	  m.setBeatsFromMeter(); 
    //   m.setFromLines(); ??
    }
  }
}
