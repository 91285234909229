<section id="reset">
  <form ngNativeValidate class="" id="reset-form" role="form" (ngSubmit)="change_pass()">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Welcome {{user_nick}}<br>Please reset your password for {{user_email}}</h5>
        </div>

        <div class="modal-body">
          <input name="reset_psw" id="reset_psw" [type]="show_pass?'text':'password'" placeholder="new password" [(ngModel)]="user_pass" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
          <input name="reset_psw2" id="reset_psw2" [type]="show_pass?'text':'password'" placeholder="new password again" [(ngModel)]="passverif" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>

          <div class="chkpass">
            <input class="toggle_pass" type="checkbox" (click)="showPass()">
            <span>Show Passwords</span>
            <span style="color: red;" [hidden]="passverif == user_pass">Passwords are differents</span>
          </div>
          <h6 style="color: red;" id="rst_infos" [hidden]="!show_infos"></h6>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">Done</button>
        </div>
      </div>
      
    </div>
  </form>
</section>