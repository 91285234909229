
<div id="abcCanvas"> </div>  

<ng-container *ngIf="midi.MIDI_AVAILABLE == true">
<!--     <p > pre : {{prevMidiNotes}}</p>
<p > Ans : {{midiAnswer}}</p>
-->  </ng-container>

<ng-container contenteditable="true" *ngIf="midi.MIDI_AVAILABLE == false">
<div class="btn btn-primary" (click)="setNote('C')"> C  </div>
<div class="btn btn-primary" (click)="setNote('D')"> D  </div>
<div class="btn btn-primary" (click)="setNote('E')"> E  </div>
<div class="btn btn-primary" (click)="setNote('F')"> F  </div>
<div class="btn btn-primary" (click)="setNote('G')"> G  </div>
<div class="btn btn-primary" (click)="setNote('A')"> A  </div>
<div class="btn btn-primary" (click)="setNote('B')"> B  </div>

<br>

<button class="btn btn-primary" (click)="setAlt('#')"> #  </button>
<button class="btn btn-primary" (click)="setAlt('b')"> b  </button>
<button class="btn btn-primary" (click)="setAlt('')"> ♮  </button>

<br>

</ng-container>

<button class="btn btn-primary" (click)="validate()"> OK  </button>


<pre> <code> {{receivedData}} </code> </pre>



