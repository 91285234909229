import { environment } from "../../environments/environment";

type APIEndpointsExcluded =
  | "production"
  | "clientId_sand"
  | "clientId_prod"
  | "serverAdress";
type APIEndpoints = Exclude<keyof typeof environment, APIEndpointsExcluded>;

type APIMethods = "GET" | "POST" | "PUT" | "DELETE";

type APIOptions = {
  headers?: { [key: string]: string };
  query?: { [key: string]: string };
  body?: string | object;
};

type APIResponse<T> = {
  status: boolean;
  message: string;
  data?: T;
};

function validateResponseFormat(data: any): boolean {
  if (!data) {
    return false;
  }

  if (typeof data !== "object") {
    return false;
  }

  if (data.status === undefined) {
    return false;
  }

  if (data.message === undefined) {
    return false;
  }

  return true;
}

function isFormData(body: any): body is FormData {
  return body instanceof FormData;
}

async function genericAPI<T>(method: APIMethods, url: string, options?: APIOptions): Promise<APIResponse<T>> {
  const headers = {};

  let body;
  if (options?.body && method !== "GET" && !isFormData(options.body)) {
    headers["Content-Type"] = "application/json";
    body = options.body;
    if (typeof body !== "string") {
      body = JSON.stringify(body);
    }
  }

  if (options?.body && isFormData(options.body)) {
    body = options.body;
  }

  if (options?.query) {
    const query = new URLSearchParams(options.query).toString();
    url += "?" + query;
  }

  if (options?.headers) {
    Object.assign(headers, options.headers);
  }

  const fetchOpts: RequestInit = {
    headers,
    method,
    body,
  };

  let response: Response;
  try {
    response = await fetch(url, fetchOpts);
  } catch (error) {
    console.debug("Network error", error);
    return {
      status: false,
      message: "Network error",
    };
  }

  if (!response) {
    console.debug("No response");
    return {
      status: false,
      message: "No response",
    };
  }

  if (!response.ok) {
    console.debug("Server error", response.status, response.statusText, method, url, body);
    // return {
    //   status: false,
    //   message: "Server error",
    // };
  }

  let data: T;
  try {

    if (response.headers.get("Content-Type")?.includes("text/plain") || response.headers.get("Content-Type")?.includes("text/html")) {
      data = await response.text() as unknown as T;
      try {
        const data2 = JSON.parse(data as any);
        data = data2;
      } catch (error) {
        console.debug("Server error parsing JSON", error);
        return {
          status: false,
          message: "Server error",
        };
      }
    } else {
      data = await response.json();
    }

  } catch (error) {
    console.debug("Server error parsing JSON", error);
    return {
      status: false,
      message: "Server error",
    };
  }

  return {
    status: true,
    message: "Success",
    data,
  };
}

/**
 * Call an API endpoint using the method and options provided
 * @param method Method to use (GET, POST, PUT, DELETE)
 * @param endpoint Endpoint to call using values from environments/environment.ts
 * @param options Optional headers, query parameters and body
 * @returns APIResponse object with status, message and data
 */
async function novaxeAPI<T>(
  method: APIMethods,
  endpoint: APIEndpoints,
  options?: APIOptions
): Promise<APIResponse<T>> {
  let url = environment[endpoint];
  if (!url) {
    console.debug("Invalid endpoint", endpoint);
    return {
      status: false,
      message: "Invalid endpoint",
    };
  }

  if (url.includes('//')) {
    url = url.replace(/\/\//g, '/');
  }

  const data = await genericAPI<APIResponse<T>>(method, url, options);
  if (!data) {
    console.debug("No data");
    return {
      status: false,
      message: "No data",
    };
  }

  if (!data.status) {
    console.debug("API error", data.message);
    return {
      status: false,
      message: data.message,
    };
  }

  if (!validateResponseFormat(data.data)) {
    console.debug("Server error", data.data);
    return {
      status: false,
      message: "Server error",
    };
  }

  return data.data as APIResponse<T>;
}

export default novaxeAPI;
export { genericAPI };
export type { APIEndpoints, APIMethods, APIOptions, APIResponse };
