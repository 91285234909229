<section id="validate">
  <div *ngIf="user.has_manage()">
    <div *ngIf="list.length > 0" class="heading">
      <h1>Score Queue</h1>
      <p>({{ list.length }} in queue)</p>
    </div>
    <div class="container-fluid blocks-list">
      <div class="row justify-content-center">
        <ng-container
          class="col-md-6 col-lg-4 col-12"
          *ngFor="let score of list; index as i"
        >
          <div class="card-deck">
            <div class="card mb-4">
              <a
                class="clickable-row"
                [routerLink]="['/score/' + score.id]"
                (click)="$event.stopPropagation()"
              >
                <img
                  class="card-img-top img-fluid"
                  src="{{ score.img }}"
                  onerror="this.src='../assets/img/empty_score.png'"
                  alt="Album image"
                />
              </a>
              <div class="card-body">
                <h5
                  [routerLink]="['/score/' + score.id]"
                  (click)="$event.stopPropagation()"
                  class="card-title clickable-row"
                >
                  {{ score.title }}
                </h5>
                <p
                  [routerLink]="['/artist/' + score.artist]"
                  (click)="$event.stopPropagation()"
                  class="clickable-row"
                >
                  Artist: {{ score.artist }}
                </p>
                <p
                  [routerLink]="['/album/' + score.album]"
                  (click)="$event.stopPropagation()"
                  class="clickable-row"
                >
                  Album: {{ score.album }}
                </p>
                <p
                  [routerLink]="['/key/' + score.tonality]"
                  (click)="$event.stopPropagation()"
                  class="clickable-row"
                >
                  Tonality : {{ score.tonality }}
                </p>
                <p
                  [routerLink]="['/author/' + score.author]"
                  (click)="$event.stopPropagation()"
                  class="clickable-row"
                  style="margin-bottom: 0"
                >
                  <i>Transcription by {{ score.author }}</i>
                </p>
              </div>
              <td class="icons">
                <a
                  class="clickable-row"
                  [routerLink]="['/stats/' + score.id]"
                  (click)="$event.stopPropagation()"
                >
                  <i class="fa fa-chart-bar" aria-hidden="true"></i>
                </a>
                <a
                  class="clickable-row"
                  [hidden]="!user.canEditScore(score.author)"
                  (click)="openApproveModal(i)"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </a>
                <a
                  class="clickable-row"
                  [hidden]="!user.canEditScore(score.author)"
                  (click)="openDeleteModal(i)"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>
              </td>
              <div class="card-footer score-button">
                <a
                  [routerLink]="['/score/' + score.id]"
                  (click)="$event.stopPropagation()"
                  class="card-link clickable-row"
                >
                  <button
                    class="btn btn-success"
                    [class.lock]="score.price > 0"
                  >
                    See score
                  </button>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="list.length == 0" class="not-found">
          <h3>Good job!</h3>
          <p>The score queue has been cleared!</p>
          <p>"Grab yourself a Sprite and put some Netflix on my friend"</p>
          <p>- A 'wise' old man</p>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="fakebook-modal modal"
      [hidden]="modalInfos.hide"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete score</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="modalInfos.hide = true"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete the score : {{ modalInfos.title }} ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="modalInfos.hide = true"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="deleteScore()"
              class="btn btn-primary"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="fakebook-modal modal"
      [hidden]="modalInfosApprove.hide"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header good">
            <h5 class="modal-title">Approve score</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="modalInfosApprove.hide = true"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you approve this score :
            {{ modalInfosApprove.title }} ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="modalInfosApprove.hide = true"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="approveScore()"
              class="btn btn-primary"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
