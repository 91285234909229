<section id="fretboard" class="modal nofly" tabindex="-1" role="dialog" [hidden]="visible==false" (contextmenu)="close_fretboard();$event.preventDefault();" >

<div id="nvxFretboard" class="modal-dialog" role="document">

	<div class="menus">
		<div class="d-flex scaleandchords">

			<!--*********************-->
			<!--******* SCALE *******-->
			<!--*********************-->
			<div class="d-flex scale">
				<span class="option-title"><img src="assets/img/chordEditor/diamond.png" title="Scale"/></span>

				<div class="floored">
					<input id="scale_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" [(ngModel)]="activeLosanges" type="checkbox"  >
					<label class="tgl-btn" for="scale_toggle"></label>
				</div>

				<div class="apply-btns  score-pos-related">
	        <div class="switch-titled">
	          <label class="switch">
	            <input type="checkbox" [(ngModel)]="scale_follow_score" (change)="toggle_scale_follow_score()"/>
	            <span class="slider classic">
	              <span class="number">Score</span>
	              <span class="letter">Preset</span>
	            </span>
	          </label>
	        </div>
	        <span data-tooltip="Scale follows..." data-tooltip-position="bottom" style="right: 0px;padding:10px;"></span>		

	     	</div>
	     	

				<select class="w-auto form-control" [(ngModel)]="scale_tona" (change)="scale_clicked()" id="tona" [disabled]="scale_follow_score" >
					<option [ngValue]="'C#'">C#</option>
					<option [ngValue]="'F#'">F#</option>
					<option [ngValue]="'B'">B</option>
					<option [ngValue]="'E'">E</option>
					<option [ngValue]="'A'">A</option>
					<option [ngValue]="'D'">D</option>
					<option [ngValue]="'G'">G</option>
					<option [ngValue]="'C'">C</option>
					<option [ngValue]="'F'">F</option>
					<option [ngValue]="'Bb'">Bb</option>
					<option [ngValue]="'Eb'">Eb</option>
					<option [ngValue]="'Ab'">Ab</option>
					<option [ngValue]="'Db'">Db</option>
				<option value="Gb">Gb</option>
				</select>

				<select class="w-auto form-control select-type" style="text-transform: capitalize;" [(ngModel)]="scale_type" (change)="scale_clicked();" name="scale_selector" id="scales" [disabled]="scale_follow_score">
					
					<option [ngValue]="'major'">major</option>
					<option [ngValue]="'aeolian'">minor</option>

					<option disabled>_________</option>

					<option [ngValue]="'dorian'">dorian</option>
					<option [ngValue]="'phrygian'">phrygian</option>
					<option [ngValue]="'lydian'">lydian</option>
					<option [ngValue]="'mixolydian'">mixolydian</option>
					<option [ngValue]="'aeolian'">aeolian</option>
					<option [ngValue]="'locrian'">locrian</option>

					<option disabled>_________</option>

					<option [ngValue]="'major pentatonic'">major pentatonic</option>
					<option [ngValue]="'major blues'">major blues</option>
					<option [ngValue]="'minor pentatonic'">minor pentatonic</option>
					<option [ngValue]="'minor blues'">minor blues</option>

					<option disabled>_________</option>
					
					<option [ngValue]="'harmonic major'">harmonic major</option>
					<option [ngValue]="'harmonic minor'">harmonic minor</option>

					<option disabled>_________</option>

					<option [ngValue]="'diminished'">diminished</option>
					<option [ngValue]="'minor six diminished'">minor six diminished</option>
					<option [ngValue]="'half-whole diminished'">half-whole diminished</option>
					<option [ngValue]="'composite blues'">composite blues</option>

					<option disabled>_________</option>

					<option [ngValue]="'bebop'">bebop</option>
					<option [ngValue]="'bebop minor'">bebop minor</option>
					<option [ngValue]="'bebop major'">bebop major</option>
					<option [ngValue]="'bebop locrian'">bebop locrian</option>
					
					<option disabled>_________</option>
					
					<option [ngValue]="'ionian pentatonic'">ionian pentatonic</option>
					<option [ngValue]="'mixolydian pentatonic'">mixolydian pentatonic</option>
					<option [ngValue]="'ritusen'">ritusen</option>
					<option [ngValue]="'egyptian'">egyptian</option>
					<option [ngValue]="'neopolitan major pentatonic'">neopolitan major pentatonic</option>
					<option [ngValue]="'vietnamese 1'">vietnamese 1</option>
					<option [ngValue]="'pelog'">pelog</option>
					<option [ngValue]="'kumoijoshi'">kumoijoshi</option>
					<option [ngValue]="'hirajoshi'">hirajoshi</option>
					<option [ngValue]="'iwato'">iwato</option>
					<option [ngValue]="'in-sen'">in-sen</option>
					<option [ngValue]="'lydian pentatonic'">lydian pentatonic</option>
					<option [ngValue]="'malkos raga'">malkos raga</option>
					<option [ngValue]="'locrian pentatonic'">locrian pentatonic</option>
					<option [ngValue]="'minor six pentatonic'">minor six pentatonic</option>
					<option [ngValue]="'flat three pentatonic'">flat three pentatonic</option>
					<option [ngValue]="'flat six pentatonic'">flat six pentatonic</option>
					<option [ngValue]="'scriabin'">scriabin</option>
					<option [ngValue]="'whole tone pentatonic'">whole tone pentatonic</option>
					<option [ngValue]="'lydian #5P pentatonic'">lydian #5P pentatonic</option>
					<option [ngValue]="'lydian dominant pentatonic'">lydian dominant pentatonic</option>
					<option [ngValue]="'minor #7M pentatonic'">minor #7M pentatonic</option>
					<option [ngValue]="'super locrian pentatonic'">super locrian pentatonic</option>
					<option [ngValue]="'minor hexatonic'">minor hexatonic</option>
					<option [ngValue]="'augmented'">augmented</option>
					<option [ngValue]="'piongio'">piongio</option>
					<option [ngValue]="'prometheus neopolitan'">prometheus neopolitan</option>
					<option [ngValue]="'prometheus'">prometheus</option>
					<option [ngValue]="'mystery #1'">mystery #1</option>
					<option [ngValue]="'six tone symmetric'">six tone symmetric</option>
					<option [ngValue]="'whole tone'">whole tone</option>
					<option [ngValue]="'messiaen\'s mode #5'">messiaen's mode #5</option>
					<option [ngValue]="'locrian major'">locrian major</option>
					<option [ngValue]="'double harmonic lydian'">double harmonic lydian</option>
					<option [ngValue]="'altered'">altered</option>
					<option [ngValue]="'locrian #2'">locrian #2</option>
					<option [ngValue]="'mixolydian b6'">mixolydian b6</option>
					<option [ngValue]="'lydian dominant'">lydian dominant</option>
					<option [ngValue]="'lydian augmented'">lydian augmented</option>
					<option [ngValue]="'dorian b2'">dorian b2</option>
					<option [ngValue]="'melodic minor'">melodic minor</option>
					<option [ngValue]="'ultralocrian'">ultralocrian</option>
					<option [ngValue]="'locrian 6'">locrian 6</option>
					<option [ngValue]="'augmented heptatonic'">augmented heptatonic</option>
					<option [ngValue]="'dorian #4'">dorian #4</option>
					<option [ngValue]="'lydian diminished'">lydian diminished</option>
					<option [ngValue]="'leading whole tone'">leading whole tone</option>
					<option [ngValue]="'lydian minor'">lydian minor</option>
					<option [ngValue]="'phrygian dominant'">phrygian dominant</option>
					<option [ngValue]="'balinese'">balinese</option>
					<option [ngValue]="'neopolitan major'">neopolitan major</option>
					<option [ngValue]="'double harmonic major'">double harmonic major</option>
					<option [ngValue]="'hungarian minor'">hungarian minor</option>
					<option [ngValue]="'hungarian major'">hungarian major</option>
					<option [ngValue]="'oriental'">oriental</option>
					<option [ngValue]="'flamenco'">flamenco</option>
					<option [ngValue]="'todi raga'">todi raga</option>
					<option [ngValue]="'persian'">persian</option>
					<option [ngValue]="'enigmatic'">enigmatic</option>
					<option [ngValue]="'major augmented'">major augmented</option>
					<option [ngValue]="'lydian #9'">lydian #9</option>
					<option [ngValue]="'purvi raga'">purvi raga</option>
					<option [ngValue]="'spanish heptatonic'">spanish heptatonic</option>
					<option [ngValue]="'minor bebop'">minor bebop</option>
					<option [ngValue]="'ichikosucho'">ichikosucho</option>
					<option [ngValue]="'kafi raga'">kafi raga</option>
					<option [ngValue]="'chromatic'">chromatic</option>
				</select>

			</div>


			<!--*********************-->
			<!--******* CHORD *******-->
			<!--*********************-->
			<div class="d-flex chord">
				<span class="option-title"><img src="assets/img/chordEditor/1P.png" title="Chord"/></span>

				<div class="floored">
					<input id="chords_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" [(ngModel)]="activeBubbles">
					<label class="tgl-btn" for="chords_toggle"></label>
				</div>

				<div class="apply-btns  chord-pos-related">
	        <div class="switch-titled">
	          <label class="switch">
	            <input type="checkbox" [(ngModel)]="chord_follow_score" (change)="toggle_chord_follow_score()" [disabled]="!score_follow"/>
	            <span class="slider classic">
	              <span class="number">Score</span>
	              <span class="letter">Preset</span>
	            </span>
	          </label>
	        </div>
	        <span data-tooltip="Chord tones follows..." data-tooltip-position="bottom" style="right: 0px;padding:10px;"></span>
	     </div>
	     

				<select class="w-auto form-control" [(ngModel)]="chord_tona" id="tona2" (change)="chord_clicked();" [disabled]="chord_follow_score || !score_follow">
					<option [ngValue]="'C#'">C#</option>
					<option [ngValue]="'F#'">Gb</option>
					<option [ngValue]="'F#'">F#</option>
					<option [ngValue]="'B'">B</option>
					<option [ngValue]="'E'">E</option>
					<option [ngValue]="'A'">A</option>
					<option [ngValue]="'D'">D</option>
					<option [ngValue]="'G'">G</option>
					<option [ngValue]="'C'">C</option>
					<option [ngValue]="'F'">F</option>
					<option [ngValue]="'Bb'">Bb</option>
					<option [ngValue]="'Bb'">A#</option>
					<option [ngValue]="'Eb'">Eb</option>
					<option [ngValue]="'Eb'">D#</option>
					<option [ngValue]="'Ab'">Ab</option>
					<option [ngValue]="'G#'">G#</option>
					<option [ngValue]="'Db'">Db</option>
				<option value="Gb">Gb</option>
				</select>

				<select class="w-auto form-control select-type" style="text-transform: capitalize;" 
				[(ngModel)]="chord_type" name="scales_selector2" id="scales2" 
				(change)="chord_clicked();" [disabled]="chord_follow_score || !score_follow">
					<option *ngFor="let chord of chords_list" [value]="chord[2].split(' ')[0]">{{chord[1]||chord[2].split(' ')[0]}}</option>
				</select>

			</div>
		</div>

		<span class="caged">
			<div [class.active]="caged_position.indexOf('C') != -1" (click)="set_caged_position('C')" class="caged_btn"><span>C</span> </div> 
			<div [class.active]="caged_position.indexOf('A') != -1" (click)="set_caged_position('A')" class="caged_btn"><span>A</span> </div> 
			<div [class.active]="caged_position.indexOf('G') != -1" (click)="set_caged_position('G')" class="caged_btn"><span>G</span> </div> 
			<div [class.active]="caged_position.indexOf('E') != -1" (click)="set_caged_position('E')" class="caged_btn"><span>E</span> </div> 
			<div [class.active]="caged_position.indexOf('D') != -1" (click)="set_caged_position('D')" class="caged_btn"><span>D</span> </div> 
		</span>

		<div class="rightbuttons">
			<div class="btns">

				<div class="double display" [ngClass]="{disabled:cm.letters_Numbers_sync}" >
					<span data-tooltip="Display degrees" data-tooltip-position="bottom" class="display-num">1</span>
					<input id="display" [(ngModel)]="displayNotesMode"  [disabled]="cm.letters_Numbers_sync" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" (change)="toggle_fretboard_display_mode()">
					<label class="tgl-btn" for="display"></label>
					<span data-tooltip="Display letters" data-tooltip-position="bottom" class="display-let">A</span>
				</div>	

				<!-- <div class="double">
					<img src="assets/img/fretboard/midi.png"/>
					<span data-tooltip="Midi" data-tooltip-position="bottom"></span>
					<input id="follow_toggle_fret" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty"  (change)="toggle_midi()" [(ngModel)]="score_follow" type="checkbox">
					<label class="tgl-btn" for="follow_toggle_fret"></label>
				</div> -->
				<div class="switch-toggle switch-3 switch-candy">

					<input id="toggle_midi_on" name="state-d" type="radio" [checked]="score_follow==false" (change)="toggle_midi($event)"/>
					<label for="toggle_midi_on" >
						<img src="assets/img/fretboard/midi.png"/>
					</label>
				  
					<input id="toggle_midi_both" name="state-d" type="radio" [checked]="score_follow=='both'" (change)="toggle_midi($event)"/>
					<label for="toggle_midi_both"  >
						both
					</label>
				  
					<input id="toggle_midi_off" name="state-d" type="radio" checked="checked" [checked]="score_follow==true" (change)="toggle_midi($event)"/>
					<label for="toggle_midi_off" >
						<img src="assets/img/fretboard/score_icon.png"/>
					</label>
				  
				  </div>

				<!-- <div>
					<img src="assets/img/fretboard/score_icon.png"/>
					<span data-tooltip="Score" data-tooltip-position="bottom"></span>
				</div> -->

				<!-- (mousedown)/(mouseup) for ANIMATION #3 -->
				<!-- CHANGE BELOW TO:  -->
				<!-- <div (mousedown)="light_random_fing()" (mouseup)="unlight_finger_on_fretboard()">  -->
				<div>
					<span aria-hidden="true" class="fa fa-font fa-rotate-270"></span>
					<span data-tooltip="Rotate" data-tooltip-position="bottom"></span>
					<!-- ADD BELOW (click)="light_random_fing()" for ANIMATIONS #1 & #2 -->
					<input id="rotate_toggle" (change)="rotateAll()" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" [(ngModel)]="rotated">
					<label class="tgl-btn" for="rotate_toggle"></label>
				</div>

				<div class="double">
					<i class="fas fa-hand-point-right hands"></i>
					<span data-tooltip="Right handed" data-tooltip-position="bottom"></span>
					<input id="hand_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty"  (change)="toggle_hand()" type="checkbox">
					<label class="tgl-btn" for="hand_toggle"></label>
				</div>
				<div>
					<i class="fas fa-hand-point-left hands"></i>
					<span data-tooltip="Left handed" data-tooltip-position="bottom"></span>
				</div>
			</div>

		</div>

		<div class="finger_anim_display">
			<span>Skin</span>
			<select [ngModel]="selected_bg" (ngModelChange)="onBgChange($event)" class="w-auto form-control" style="margin-right: 15px;" [disabled]="cm.use_chromakey">
			    <option [value]="i" *ngFor="let i of bg">{{i}}</option>
			</select>

			<span>Animation</span>
			<select [ngModel]="selected_anim" (ngModelChange)="onChange($event)" class="w-auto form-control">
			    <option [value]="i" *ngFor="let i of anim">{{i}}</option>
			</select>

			<div class="glow-toggle">
				<i class="fa fa-circle" aria-hidden="true"></i>
				<span data-tooltip="Glowing chords" data-tooltip-position="top"></span>
				<input id="glow_toggle" class="tgl tgl-flat ng-pristine ng-untouched ng-valid ng-not-empty" [(ngModel)]="glowingBubbles" type="checkbox">
				<label class="tgl-btn" for="glow_toggle"></label>
			</div>
		</div>


		<button type="button" class="close" data-dismiss="modal" (contextmenu)="close_fretboard(true);$event.preventDefault();" (click)="close_fretboard(true)" aria-label="Close"> 
			<span aria-hidden="true" class="fa fa-times"></span> 
		</button>
	</div>

	<svg version="1.1" viewBox="-5 3 560 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="fret-grid"  id="fret-grid">

		<defs> 
			<pattern id="openedEye" patternUnits="objectBoundingBox" width="10" height="10"> <image xlink:href="assets/img/eye_open.png" x="0" y="0" width="8" height="10"></image></pattern> 
			<pattern id="closedEye" patternUnits="objectBoundingBox" width="10" height="10"> <image xlink:href="assets/img/eye_closed.png" x="0" y="0" width="8" height="10"></image></pattern> 
			<filter id="f2" color-interpolation-filters="sRGB">
                <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="imgBlur" />
                <feSpecularLighting in="imgBlur" surfaceScale="2" specularConstant=".75"
                  specularExponent="20" lighting-color="#bbbbbb" result="imgSpecular">
                 <fePointLight x="-5" y="-100" z="100" />
                </feSpecularLighting>
                <!-- Restrict the specular highlight to the object; this is necessary. -->
                <feComposite in="imgSpecular" in2="SourceAlpha" operator="in" result="imgMasked" />
                <feComposite in="SourceGraphic" in2="imgMasked"
                  operator="arithmetic" k1="0" k2="1" k3="2" k4="0"/>
            </filter>
		</defs>

		<!-- background -->

			<image width="550" height="110" y="0.4" id="bg-image" *ngIf="selected_bg=='Classic'"
				xlink:href="assets/img/fretboard/fretboard_background_v3.svg#fretboard_background">
			</image>
			<svg version="1.1" viewBox="4 -6 538.4 97.7" *ngIf="selected_bg=='Classic'">
				<text class="bg-text" x="53.861782" y="50.286163"><tspan x="53.861782" y="50.286163">3</tspan></text>
				<text class="bg-text" x="98.278229" y="50.052006"><tspan x="98.278229" y="50.052006">5</tspan></text>
				<text class="bg-text" x="142.36052" y="50.088692"><tspan x="142.36052" y="50.088692">7</tspan></text>
				<text class="bg-text" x="186.37064" y="50.086163"><tspan x="186.37064" y="50.086163">9</tspan></text>
				<text class="bg-text" x="251.67676" y="66.922852"><tspan x="251.67676" y="66.922852">12</tspan></text>
				<text class="bg-text" x="318.64386" y="49.967587"><tspan x="318.64386" y="49.967587">15</tspan></text>
				<text class="bg-text" x="363.63882" y="49.982162"><tspan x="363.63882" y="49.982162">17</tspan></text>
				<text class="bg-text" x="406.59961" y="49.979633"><tspan x="406.59961" y="49.979633">19</tspan></text>
				<text class="bg-text" x="451.15027" y="50.122849"><tspan x="451.15027" y="50.122849">21</tspan></text>
				<text class="bg-text" x="518.65778" y="50.016319"><tspan x="518.65778" y="50.016319">24</tspan></text>
				<text class="bg-text" x="251.67676" y="31.222847"><tspan x="251.67676" y="31.222847">12</tspan></text>
			</svg>

      <svg fretboardsvg width="550" height="110" viewBox="0 0 538 98" fill="none" xmlns="http://www.w3.org/2000/svg" y="0.4" id="bg-image" *ngIf="selected_bg=='Dark'"></svg>
			<svg version="1.1" viewBox="4 -6 538.4 97.7" *ngIf="selected_bg=='Dark'">
				<text class="bg-text" x="89.861782" y="50.286163"><tspan x="89.861782" y="50.286163">3</tspan></text>
				<text class="bg-text" x="147.00000" y="50.052006"><tspan x="147.00000" y="50.052006">5</tspan></text>
				<text class="bg-text" x="200.36052" y="50.088692"><tspan x="200.36052" y="50.088692">7</tspan></text>
				<text class="bg-text" x="246.37064" y="50.086163"><tspan x="246.37064" y="50.086163">9</tspan></text>
				<text class="bg-text" x="306.67676" y="66.922852"><tspan x="306.67676" y="66.922852">12</tspan></text>
				<text class="bg-text" x="361.64386" y="49.967587"><tspan x="361.64386" y="49.967587">15</tspan></text>
				<text class="bg-text" x="398.63882" y="49.982162"><tspan x="398.63882" y="49.982162">17</tspan></text>
				<text class="bg-text" x="434.00000" y="49.979633"><tspan x="434.00000" y="49.979633">19</tspan></text>
				<text class="bg-text" x="471.15027" y="50.122849"><tspan x="471.15027" y="50.122849">21</tspan></text>
				<text class="bg-text" x="524.65778" y="50.016319"><tspan x="524.65778" y="50.016319">24</tspan></text>
				<text class="bg-text" x="306.67676" y="31.222847"><tspan x="306.67676" y="31.222847">12</tspan></text>
			</svg>

		<!-- yellow fingers -->
		<svg *ngFor="let y of yStrings; index as i" [attr.id]="'FB_finger_string_'+i" [attr.y]="y" class="y-finger">
			<svg *ngFor="let x of (selected_bg=='Classic'?xStringNotes:xStringNotes2); index as j" 
				[attr.id]="'string_'+i+'_Finger_'+j" 
				[attr.x]="x" 
				class="finger" 
				viewBox="0 0 40 40" width="15" height="15" 
				[style.display]="fb_struct[i][j].display_fing ? 'block' : 'none'" >

				<path [style.filter]="selected_anim=='Misty' || selected_anim=='Misty-time'?'url(#f2) blur(1px)':''" style="transform:scale(1.2);transform-origin:50%;" d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z"/>

				<!-- FOR FINGERS ANIMATION -->
				<path id="pulse" [ngClass]="{'p-misty': (selected_anim=='Misty' || selected_anim=='Misty-time'), 'p-summer': selected_anim=='Summertime', 'p-fever': selected_anim=='Fever'}" [attr.style]="selected_anim=='Summertime'?'animation-duration:'+animationTime+'s;':''" d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z"/>
				
				<path [ngClass]="{'p-summer': selected_anim=='Misty-time'}" [attr.style]="selected_anim=='Misty-time'?'animation-duration:'+animationTime+'s;':''" d="M 0.20267886,17.651493 17.802677,0.05149334 35.402678,17.651493 17.802677,35.251499 Z"/>

				<text class="finger-diamond">{{(displayNotesMode)?notes_active[i][j]:active_intervals[active_scale.indexOf(notes_active[i][j])]}}</text>
			</svg>
		</svg>


		<!-- bubbles -->
		<svg *ngFor="let y of yStrings; index as i" [attr.id]="'FB_bubble_string_'+i" [attr.y]="y" [style.display]="activeStrings[i] && activeBubbles ? 'block' : 'none'" class="y-diamond rotate">
			<image 
				*ngFor="let x of (selected_bg=='Classic'?xStringNotes:xStringNotes2); index as j" 
				[style.display]="fb_struct[i][j].display_glow && glowingBubbles && (fb_struct[i][j].display_bub || fb_struct[i][j].display_midi) && (caged_filter.indexOf(j)>-1 || caged_filter.length == 0 || !score_follow)? 'block' : 'none'" 
				[attr.id]="'string_'+i+'_Glow_'+j" 
				[attr.x]="x" 
				width="13" height="13" viewBox="0 0 40 40" 
				class="bubble glow"
				xlink:href=""
				[attr.xlink:href]="'assets/img/chordEditor/'+(fb_struct[i][j].display_midi ? fb_struct[i][j].midi_img:fb_struct[i][j].bubble_img)">
			</image>
			<image 
				*ngFor="let x of (selected_bg=='Classic'?xStringNotes:xStringNotes2); index as j" 
				[style.display]="(fb_struct[i][j].display_bub || fb_struct[i][j].display_midi) && (caged_filter.indexOf(j)>-1 || caged_filter.length == 0 || !score_follow)? 'block' : 'none'" 
				[attr.id]="'string_'+i+'_Bubble_'+j" 
				[attr.x]="x" 
				width="13" height="13" viewBox="0 0 40 40" 
				class="bubble" 
				xlink:href=""
				[attr.xlink:href]="'assets/img/chordEditor/'+(fb_struct[i][j].display_midi ? fb_struct[i][j].midi_img:fb_struct[i][j].bubble_img)">

			</image>
		</svg>


		<!-- diamonds -->
		<svg *ngFor="let y of yStrings; index as i" [attr.id]="'FB_diamond_string_'+i" [attr.y]="y" [style.display]="(activeStrings[i] && activeLosanges) ? 'block' : 'none'" class="y-diamond">
			<svg fretboard-diamond 
				*ngFor="let x of (selected_bg=='Classic'?xStringNotes:xStringNotes2); index as j" 
				width="15" height="15" viewBox="0 0 40 40" class="diamond"
				[text]="(displayNotesMode)?notes_active[i][j]:active_intervals[active_scale.indexOf(notes_active[i][j])]" 
				[attr.note]="notes_active[i][j]" 
				[attr.x]="x" 
				[attr.id]="'string_'+i+'_Diamond_'+j" 
				[style.display]="((fb_struct[i][j].display_los || active_scale.indexOf(notes_active[i][j])>-1 )&& (caged_filter.indexOf(j)>-1 || caged_filter.length == 0)) ? 'block' : 'none'" 
				>
			</svg>
		</svg>

		<!-- eyes -->
		<g> 
			<circle *ngFor="let y of yStrings; index as i" [attr.id]="'stringToggle_'+i" class="stringToggle" [class.active]="activeStrings[i]" (click)="this.activeStrings[i] = !this.activeStrings[i];" r="5" cx="557" [attr.cy]="y+6.5"/>
		</g>
	</svg>

	<!-- <div class="chord-name" 
			 [style.font-family]="cm.novaxe_notation_on?'nvxChord':''"
			 [style.bottom]="cm.novaxe_notation_on?'10px':'0'"
			 [style.font-size]="cm.novaxe_notation_on?'30px':'35px'">
			 {{_chordName}}
	</div> -->

</div>
</section>