<div class="app-page">

  <div class="bandeau p-4"><h1><a [routerLink]="['/create-exercise']" href="#">Create your own</a></h1></div>

	<div class="bandeau p-4"><h1>Exercises</h1></div>
	<div class="bandeau">


	<div class="category card d-inline-flex col-4">
		<h2> Separates hands</h2>
		<h4 class="subtitle"> G clef</h4>
		<div style="display:flex">
			<ul class="category-col col-6">
        <li class="my-2">
            <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_1_0',ex_type:'100',tempo:-1,fastload:'true'}" tooltip="G clef only, free tempo" href="#">Level 1.0</a>
            <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_0',refEl)"> {{get_score('LEVEL_1_0')}}% </div>
        </li>
        <li class="my-2">
            <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_1_1',fastload:'true'}" tooltip="G clef only, slow tempo tempo" href="#">Level 1.1</a>
            <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_1',refEl)"> {{get_score('LEVEL_1_1')}}% </div>
        </li>
				<li class="my-2">
					  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_1_2',fastload:'true'}" tooltip="G clef only, free tempo" href="#">Level 1.2</a>
            <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_2',refEl)"> {{get_score('LEVEL_1_2')}}% </div>
				</li>
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="G clef, slow tempo." [queryParams]="{level:'lvl_1_3',fastload:'true'}" href="#">Level 1.3</a>
            <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_3',refEl)"> {{get_score('LEVEL_1_3')}}% </div>
				</li>
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="G clef, medium tempo." [queryParams]="{level:'lvl_1_4',fastload:'true'}" href="#">Level 1.4</a>
            <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_4',refEl)"> {{get_score('LEVEL_1_4')}}% </div>
				</li>
			</ul>
			<ul class="category-col col-6">
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="G clef, fast tempo." [queryParams]="{level:'lvl_1_5',fastload:'true'}" href="#">Level 1.5</a>
					<div class="results d-inline p-2 " #refEl_2 [style.color]="get_color('LEVEL_1_5',refEl_2)"> {{get_score('LEVEL_1_5')}}% </div>
				</li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" tooltip="G clef only, with simple rhythmic motives." [queryParams]="{level:'lvl_1_6',fastload:'true'}" href="#">Level 1.6</a>
					<div class="results d-inline p-2 " #refEl_3 [style.color]="get_color('LEVEL_1_6',refEl_3)"> {{get_score('LEVEL_1_6')}}% </div>
				</li>
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="G clef only, with simple rhythmic motives, low tempo." [queryParams]="{level:'lvl_1_7',fastload:'true'}" href="#">Level 1.7</a>
            <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_7',refEl)"> {{get_score('LEVEL_1_7')}}% </div>
				</li>
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="G clef only, with medium rhythmic motives, medium tempo." [queryParams]="{level:'lvl_1_8',fastload:'true'}" href="#">Level 1.8</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_8',refEl)"> {{get_score('LEVEL_1_8')}}% </div>
				</li>
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="G clef only, with medium rhythmic motives, fast tempo." [queryParams]="{level:'lvl_1_9',fastload:'true'}" href="#">Level 1.9</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_1_9',refEl)"> {{get_score('LEVEL_1_9')}}% </div>
				</li>
				<li class="my-2">
					<a [routerLink]="['/create-exercise']" tooltip="First score !" [queryParams]="{level:'FULL_SCORE',fastload:'true',score:'scores_easy'}" href="#">Full score !</a>
				</li>

			</ul>
			</div>

		<h4 class="subtitle"> F clef</h4>
		<div style="display:flex">
			<ul class="category-col col-6">
        <li class="my-2">
          <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_0',fastload:'true'}" tooltip="F clef only, free tempo." href="#">Level 2.0</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_0',refEl)"> {{get_score('LEVEL_2_0')}}% </div>
        </li>
        <li class="my-2">
          <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_1',fastload:'true'}" tooltip="F clef only, free tempo." href="#">Level 2.1</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_1',refEl)"> {{get_score('LEVEL_2_1')}}% </div>
        </li>
        <li class="my-2">
          <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_2',fastload:'true'}" tooltip="F clef only, free tempo." href="#">Level 2.2</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_2',refEl)"> {{get_score('LEVEL_2_2')}}% </div>
        </li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_3',fastload:'true'}" tooltip="F clef, slow tempo." href="#">Level 2.3</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_3',refEl)"> {{get_score('LEVEL_2_3')}}% </div>
				</li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_4',fastload:'true'}" tooltip="F clef, medium tempo." href="#">Level 2.4</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_4',refEl)"> {{get_score('LEVEL_2_4')}}% </div>
				</li>
			</ul>
			<ul class="category-col col-6">
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_5',fastload:'true'}" tooltip="F clef, fast tempo." href="#">Level 2.5</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_5',refEl)"> {{get_score('LEVEL_2_5')}}% </div>
				</li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_6',fastload:'true'}" tooltip="F clef only, with simple rhythmic motives, low tempo." href="#">Level 2.6</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_6',refEl)"> {{get_score('LEVEL_2_6')}}% </div>
				</li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_7',fastload:'true'}" tooltip="F clef only, with simple rhythmic motives, medium tempo." href="#">Level 2.7</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_7',refEl)"> {{get_score('LEVEL_2_7')}}% </div>
				</li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_8',fastload:'true'}" tooltip="F clef only, with medium rhythmic motives, fast tempo." href="#">Level 2.8</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_8',refEl)"> {{get_score('LEVEL_2_8')}}% </div>
				</li>
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_2_9',fastload:'true'}" tooltip="F clef, fast tempo." href="#">Level 2.9</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_2_9',refEl)"> {{get_score('LEVEL_2_9')}}% </div>
				</li>
			</ul>
		</div>

		<h4 class="subtitle"> Exam </h4>
		<div class="d-flex">
			<ul class="category-col col-6">
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_3_1',fastload:'true'}" tooltip="Clef of G or F at a medium tempo." href="#">Level 3.1</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_3_1',refEl)"> {{get_score('LEVEL_3_1')}}% </div>
				</li>
				  
				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_3_2',fastload:'true'}" tooltip="Clef of G or F at a fast tempo." href="#">Level 3.2</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_3_2',refEl)"> {{get_score('LEVEL_3_2')}}% </div>
				</li>

				<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_3_3',fastload:'true'}" tooltip="Clef of G or F at a very fast tempo." href="#">Level 3.3</a>
          <div class="results d-inline p-2 " #refEl [style.color]="get_color('LEVEL_3_3',refEl)"> {{get_score('LEVEL_3_3')}}% </div>
				</li>
			</ul>
		</div>
				

	</div>


	<div class="category card d-inline-flex col-4">
		<h2> Both hands </h2>
		<ul >
			<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_4_1',fastload:'true'}" 
              tooltip="G and F clefs, free tempo" href="#">Level 4</a>
				  
			</li>
			<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'lvl_4_1',fastload:'true'}" href="#">Level 5</a>
			</li>
			<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'HEARING',fastload:'true'}" tooltip="Ear training " href="#">Level 6</a>
			</li>
		</ul>
	</div>


	<div class="category card d-inline-flex col-4">
		<h2> Hard </h2>
		<ul >
			<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'LEVEL_1',fastload:'true'}" href="#">Level 7</a>
			</li>
			<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'LEVEL_1',fastload:'true'}" href="#">Level 8</a>
			</li>
			<li class="my-2">
				  <a [routerLink]="['/create-exercise']" [queryParams]="{level:'LEVEL_1',fastload:'true'}" href="#">Level 9</a>
			</li>
		</ul>
	</div>

	</div>



</div>
