<div
  class="chordstrip-and-selection-container"
  [ngClass]="{show:(visible==true)}"
  [hidden]="visible==false"
>
	<div class="chordstrip-container" 
  	tabindex="0"
	  (contextmenu)="changeChordName($event)"
  >
  	<div id="global-stripform" class="stripform-container">
      <!-- [ngClass]="{selected:(selected_measure === i)}" -->
  		<div
        class="card"
        *ngFor="let m of measures_list; let i=index"
        [attr.id]="'chordstrip_'+i"
      >
        <h1 class="strip_measure_nb">{{i+1}}</h1>
        <!-- [ngClass]="{selected:(selected_measure === i && selected_beat === j)}" -->
        <div class="card-body" [attr.id]="'chordstripname_'+i" >
          <h1
            style="min-width: 1em;"
            *ngFor="let n of chordsNameType[i]; let j=index"
            (click)="select(m,j)"
            [attr.id]="'chordstripname_'+i+'_'+j"
          >
            {{n[0]}}{{Translate[n[1]]}}
            <img *ngIf="n[0]!=''" [attr.src]="'assets/img/chordEditor/'+measures_basses[i][j] +'.png'">
          </h1>
				</div>
			</div>
		</div>
	</div>
</div>