import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CookieService } from "ngx-cookie";
import novaxeAPI, { APIResponse } from "src/app/utils/novaxeAPI";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserModel {
  public user_email: string;
  public user_nick: string;
  public user_role: "admin" | "manager" | "user" = "user";
  public user_is_logged = new BehaviorSubject(false); // inital value is "false"

  constructor(private cookieService: CookieService) {
    let cookie: any;
    cookie = this.cookieService.get("user");

    console.log("Cookie", cookie);

    if (cookie) {
      this.applyJwt(cookie);
    }

    // CLEAR ALL RESULTS in COOKIE =============DEBUG ONLY=============
    //
    // this.clearAllResults();
    //
    // ================================================================
    // console.log(this.getResults())

    setInterval(this.checkForTokenChanges, 1000 * 60 * 5);
    this.checkForTokenChanges();
  }

  private async checkForTokenChanges() {
    console.log("Checking for token changes");
    const resp = await novaxeAPI("GET", "apiCheckToken");
    if (!resp.status) {
      console.log("Token invalid, attempting to log out");
      if (this.user_is_logged.getValue()) await this.logout();
      console.error(resp.message);
      return;
    }

    const cookie = this.cookieService.get("user");
    if (cookie) {
      this.applyJwt(cookie);
    }
  }

  private urlSafeBase64Decode(data: string) {
    console.log("Decoding", data);
    data = data.replace(/-/g, "+").replace(/_/g, "/");
    return atob(data);
  }

  private applyJwt(jwtStr: string) {
    console.log("Applying jwt", jwtStr);

    const jwtBody = jwtStr.split(".");
    if (jwtBody.length !== 3) {
      console.log("Invalid jwt body");
      return;
    }

    try {
      const jwt = JSON.parse(this.urlSafeBase64Decode(jwtBody[1]));
      this.user_nick = jwt.nickname;
      this.user_email = jwt.email;
      this.user_role = jwt.role;
    } catch (e) {
      console.log(e);
      return;
    }

    console.log("User", this.get_user());
    if (this.user_nick && this.user_email) {
      console.log("User is logged in");
      this.user_is_logged.next(true);
    }
  }

  /**
   * create a new user
   */
  public async sign_up(
    pass: string,
    email: string,
    nick: string
  ): Promise<APIResponse<"server" | "email" | "password" | "nickname">> {
    let obj: object = {
      user_email: email,
      user_nick: nick,
      user_pass: pass,
    };

    if (email == "" || nick == "" || pass == "")
      throw "userModel : create() => missing information";

    if (!email) return { status: false, message: "No email", data: "email" };
    if (!nick)
      return { status: false, message: "No nickname", data: "nickname" };
    if (!pass)
      return { status: false, message: "No password", data: "password" };
    if (pass.length < 8)
      return { status: false, message: "Password too short", data: "password" };

    const resp = await novaxeAPI("POST", "apiCreateUser", { body: obj });
    if (!resp.status) {
      console.error(resp.message);
      resp.data = "server";
      return resp as APIResponse<"server">;
    }

    this.checkForTokenChanges();

    return resp as APIResponse<"server">;
  }

  public async login(
    pass: string
  ): Promise<APIResponse<"server" | "email" | "password">> {
    let obj: object = {
      user_email: this.user_email,
      user_nick: this.user_nick,
      user_pass: pass,
    };

    if (!this.user_email) {
      console.error("No email");
      return { status: false, message: "No email", data: "email" };
    }

    const resp = await novaxeAPI("POST", "apiSignIn", { body: obj });
    if (!resp.status) {
      console.error(resp.message);
      resp.data = "server";
      return resp as APIResponse<never>;
    }

    console.log("Login response", resp);

    this.checkForTokenChanges();

    return resp as APIResponse<never>;
  }

  public async logout() {
    const resp = await novaxeAPI("GET", "apiSignOut");
    if (!resp.status) {
      console.error(resp.message);
      return resp as APIResponse<never>;
    }

    this.user_email = "";
    this.user_nick = "";
    this.user_role = "user";
    this.user_is_logged.next(false);
  }

  public async reset_password() {
    let obj: object = {
      user_email: this.user_email,
      adress: environment.serverAdress + "reset-password?key=",
    };

    const resp = await novaxeAPI("POST", "apiSendLink", { body: obj });
    if (!resp.status) {
      console.error(resp.message);
      return resp as APIResponse<never>;
    }

    return resp as APIResponse<string>;
  }

  public async get_user_infos(key, reset) {
    let obj: object = {
      email_encrypt: key,
      pass_encrypt: reset,
    };

    const resp = await novaxeAPI<{
      nick: string;
      email: string;
      folder: string;
      role: "admin" | "manager" | "user";
    }>("POST", "apiUserInfos", { body: obj });
    if (!resp.status) {
      console.error(resp.message);
      return resp as APIResponse<never>;
    }

    this.user_nick = resp.data.nick;
    this.user_email = resp.data.email;
    this.user_role = resp.data.role;
    this.user_is_logged.next(true);
    // this.user_is_logged.next(false);

    return resp as APIResponse<never>;
  }

  public async update_user_pass(pass) {
    let obj: object = {
      user_email: this.user_email,
      user_pass: pass,
    };

    if (this.user_email == "" || pass == "")
      throw "userModel : update() => missing information";

    const resp = await novaxeAPI("POST", "apiUpdateUserPass", { body: obj });
    if (!resp.status) {
      console.error(resp.message);
      return resp as APIResponse<never>;
    }

    return resp as APIResponse<never>;
  }

  public is_logged() {
    return this.user_is_logged;
  }

  public getCookie(key: string) {
    return this.cookieService.get(key);
  }

  public addResults(level: string, result: any): any {
    let old_results = this.cookieService.getObject("results");

    if (!old_results) old_results = {};

    if (!old_results.hasOwnProperty(level)) {
      old_results[level] = [];
    }

    let D = new Date();
    let date =
      D.getUTCMonth() + 1 + "-" + D.getUTCDate() + "-" + D.getUTCFullYear();

    // if already results for this exo : mean it. else create it.
    if (
      old_results[level].length &&
      old_results[level][old_results[level].length - 1].hasOwnProperty(date)
    ) {
      console.log(
        "MEAN OF ",
        old_results[level][old_results[level].length - 1][date],
        "and ",
        result
      );
      old_results[level][old_results[level].length - 1][date] =
        (old_results[level][old_results[level].length - 1][date] + result) / 2;
    } else {
      old_results[level].push({ [date]: result });
    }
    // old_results[level]["3-7-2021"]= Math.round(Math.random()*100);
    // old_results[level]["3-6-2021"]= Math.round(Math.random()*100);
    // old_results[level]["3-5-2021"]= Math.round(Math.random()*100);
    // old_results[level]["3-4-2021"]= Math.round(Math.random()*100);
    // old_results[level]["3-3-2021"]= Math.round(Math.random()*100);
    // old_results[level]["3-2-2021"]= Math.round(Math.random()*100);

    // this.cookieService.putObject("results",{});
    this.cookieService.putObject("results", old_results);

    return old_results;
  }

  public clearAllResults() {
    this.cookieService.putObject("results", {});
  }

  public clearResults(level) {
    let old_results = this.cookieService.getObject("results");
    old_results[level] = [];
    this.cookieService.putObject("results", old_results);
  }

  public getResults() {
    if (!this.cookieService.getObject("results"))
      this.cookieService.putObject("results", {});

    return this.cookieService.getObject("results");
  }

  public get_nick() {
    return this.user_nick;
  }

  public set_user_nick(nic) {
    this.user_nick = nic;
  }

  public get_user_email() {
    return this.user_email;
  }

  public set_user_email(email) {
    this.user_email = email;
  }

  public get_user() {
    let usr = {
      userNick: this.user_nick,
      user_email: this.user_email,
      user_role: this.user_role,
      is_logged: this.is_logged().getValue(),
    };
    return usr;
  }

  public has_manage() {
    const hasManage = this.has_admin() || this.user_role === "manager";
    return hasManage;
  }

  public has_admin() {
    const hasAdmin = this.user_role === "admin";
    return hasAdmin;
  }

  public canEditScore(author: string) {
    // if (this.has_admin()) return true;
    if (this.has_manage()) return true;
    return author === this.get_nick();
  }
}
