<div style="padding:10px;text-align: center;">

	<!-- <p>Transport module</p> -->
	<button type="button" class="btn btn-success" (mousedown)="transport.play_pause()">
		<i [hidden]="transport.state=='playing' " class="fa fa-play"></i>
		<i [hidden]="transport.state!='playing'" class="fa fa-pause"></i>
	</button>
	<button type="button" class="btn btn-danger" (mousedown)="transport.stop()">
		<i class="fa fa-stop"></i>
	</button>


<!-- KEEP IT FOR DEBUG --------------------->
<!-- 	<table  class="col-1" style="margin:auto;">
		<tr>
			<th>M</th>
			<th>B</th>
			<th>Sb</th>
		</tr>

		<tr>
			<td>{{measure+1}}</td>
			<td>{{beat+1}}</td>
			<td>{{subBeat+1}}</td>
		</tr>

	</table>
 -->	
<!-- KEEP IT FOR DEBUG --------------------->
</div> 

