<section id="yt-audio">

	<div class="youtube-audio-container"
		[ngClass]="{show:(visible==true&&display_Y==false),show_mas:(visible==true&&display_Y==true)}"
		[hidden]="visible==false" tabindex="0" (mouseenter)="focus($event)" (keydown.shift)="keyPressed($event,true)"
		(keyup.shift)="keyPressed($event,false)" (contextmenu)="$event.preventDefault();">
		<!-- (mouseleave)="keyPressed($event,false)" -->

		<div id="global-waveform" class="waveform-container">
			<!-- spining/youtube link -->
			<div [ngClass]="{zindexzero:audio_loaded}" class="spining">

				<!-- SPINNER -->
				<div [hidden]="!loading_wheel" class="spinner3">
					<div class="double-bounce1"><img src="assets/img/music-placeholder-glow.png"></div>
					<div class="double-bounce2"><img src="assets/img/music-placeholder-glow.png"></div>
				</div>


				<span [hidden]="audio_loaded" class="	">
					<input type="text" placeholder="Youtube link" [(ngModel)]="link"
						(keydown.enter)="button_clicked()" />
					<button class="btn btn-primary fab fa-youtube ml-1" (click)="button_clicked()"></button>
				</span>

				<label [hidden]="audio_loaded" class="btn btn-primary fas fa-upload mb-0 ml-3 ">
					<input class="d-none" type="file" accept="audio/mp3" (change)="onFileChange($event)" />
				</label>

			</div> <!-- spining -->

			<div id="wave-timeline" [hidden]="timeline_hidden"></div> <!-- waveform timeline -->
			<div id="waveform" style="touch-action: none;"></div>
		</div>
	</div>



	<!-- <div class="youtube-video-container" [hidden]="display_Y==false" (contextmenu)="display_Y=false;$event.preventDefault();" >
	<div class="yt_player" id="player"></div> -->
	<!-- slider -->
	<!-- <div style="margin:5px;">
		<span class="col-2" >Volume : {{volume}}</span>
	</div>
</div> -->

	<span class="top_row">
		<span class="playerIcons">
			<i role="button" [ngClass]="{'active':(playerState!=2&&playerState!=1)}" class="fas fa-stop p-2"
				(click)="stopAudio()"></i>
			<i role="button" [ngClass]="{'active':playerState==2}" class="fas fa-pause p-2" (click)="pauseAudio()"></i>
			<i role="button" [ngClass]="{'active':playerState==1}" class="fas fa-play p-2" (click)="resumeAudio()"></i>

			<!--<i role="button" [class.active]="playback_rate==0.5" class="p-2" (click)="changePlaybackRate(0.5)">0.5</i>
			<i role="button" [class.active]="playback_rate==0.75" class="p-2" (click)="changePlaybackRate(0.75)">0.75</i>
			<i role="button" [class.active]="playback_rate==1" class="p-2" (click)="changePlaybackRate(1)">1</i> -->

			<i role="button" [class.active]="follow" title="Audio follow" class="p-2" (click)="toggleFollow()">f</i>
			<i role="button" [class.active]="center" title="Center score" class="p-2 fas fa-indent"
				(click)="toggleCenter()"></i>
			<i role="button" class="fas fa-volume-up p-2" (mouseover)='show_volume(true)'
				(mouseout)='show_volume(false)'>
				<div class="vertical-slider-wrapper vol" (mouseover)='show_volume(true)'
					(mouseout)='show_volume(false)'>
					<input class="col-2" type="range" min="0.0001" max="0.8" step="0.01" class="slider"
						id="myVolume_audio" (change)="set_volume()" (input)="set_volume()" [(ngModel)]="volume">
				</div>
			</i>
			<i role="button" class="p-2" (mouseover)='show_m_volume(true)' (mouseout)='show_m_volume(false)'>
				<img src="assets/img/metronome_ico.svg">
				<div class="vertical-slider-wrapper m-vol" (mouseover)='show_m_volume(true)'
					(mouseout)='show_m_volume(false)'>
					<input class="col-2" type="range" min="0.0001" max="0.8" step="0.01" class="slider" id="metro_vol"
						[(ngModel)]="cm.metroVolume">
				</div>
			</i>
		</span>

		<span class="displayed-chord" [style.font-family]="cm.novaxe_notation_on?'nvxChord':''"
			[style.font-size]="cm.novaxe_notation_on?'30px':'35px'" [style.right]="cm.novaxe_notation_on?'20px':'0'"
			style="width:0px;margin-right: 20%;" [style.bottom]="cm.novaxe_notation_on?'5px':'0'">
			{{displayed_chord}}
		</span>

		<span class="center-col">
			<span>Repeat</span>

			<input type="number" (change)="updateRepeat()" min="0" [(ngModel)]="repeat.start">
			<input type="number" (change)="updateRepeat()" min="0" [(ngModel)]="repeat.end">

			<i [ngClass]="{'active':repeat.active==true}" class="fa fa-undo "
				(click)="repeat.active=!repeat.active;updateRepeat()"></i>

			<img src="assets/img/loop_part.png" role="button" [class.inactive]="edit==false" class="p-2"
				(click)="setRepeatPart()" />

			<!-- <i role="button" class="fas fa-eye fa p-2" (click)="toggle_edit()" [ngClass]="{'active':edit==true}"></i> -->
			<i role="button" [class.active]="drag" [class.inactive]="edit==false" class="fas fa-arrows-alt fa p-2"
				(click)="drag_toggle(!drag)"></i>
			<i role="button" [class.inactive]="edit==false" class="p-2" (click)="addNextPart()">p</i>
			<i role="button" [class.inactive]="edit==false" class="p-2" (click)="addNextRegion()">m</i>
			<i [ngClass]="{'active':ghost==true}" class="fas fa-ghost " (click)="ghost=!ghost;updateGhost()"></i>
			<i [ngClass]="{'active':stretch==true}" class="fas fa-arrows-alt-h "
				(click)="stretch=!stretch;updateStretch()"></i>
		</span>

		<i id="beatC_button" role="button" class="p-2" (click)="setAllBeatsFromModel()">b</i>
		<i id="beatM_button" role="button" class="p-2" (click)="setAllMeasuresFromModel()">m</i>
	</span>

</section>