import { Component, OnInit, OnDestroy, Output, EventEmitter, NgZone } from '@angular/core';
import { Songmodel } from '@models/songmodel/songmodel';
import { DisplayService } from '@services/display/displayService';
import { Subscription } from 'rxjs/Subscription';

import { MidiService } from '@services/midi/midi.service';

import { ExerciseResultsService } from '@services/exercises/exercise_results/exercise-results.service';

export interface Answer{
  ans: string
}

import abcjs from 'abcjs';
declare global {
  var abcjs:any;
}

@Component({
  selector: 'app-chord',
  templateUrl: './chord.component.html',
  styleUrls: ['./chord.component.scss']
})
export class ChordComponent implements OnInit, OnDestroy {

  private paper:any; //score canvas
  private abcString:string; 
  private abcString$:Subscription;

  public receivedData: any ; //input json
  public parsedData: any ; //input json

  // @Output() answerJson  = new EventEmitter<Answer>(); //output answer
  @Output() askNext     = new EventEmitter<boolean>(); //output answer

  private note:string = 'C';
  private alteration:string = '';
  private type:string = '';

  private startTime:number=0;
  private endTime:number=0;

  private midiNotesTab$:Subscription;
  public prevMidiNotes:any=[]; //array of midi notes
  public midiAnswer:any=[]; //array of midi notes
  private MIDI_HAS_FIRED:boolean=false;

  constructor(private sm:Songmodel, private ds:DisplayService, private resService:ExerciseResultsService, public midi:MidiService, private zone:NgZone ) { 
    this.midi = midi;
    this.midiNotesTab$ = this.midi.notesTabSubject.subscribe((data)=>{
      this.zone.run( ()=>{

        if(!data.length)return;

        //if number of midinotes is inferior to previous => answer
        if(data.length > this.prevMidiNotes.length) {
          this.prevMidiNotes = [...data];
        } else if( ( data.length < this.prevMidiNotes.length ) && !this.MIDI_HAS_FIRED) {
          console.log("data => ",data);
          this.midiAnswer = [];
          this.midiAnswer = [...this.prevMidiNotes];
          // console.log("validateMidi !!!!!!!!!!");
          this.prevMidiNotes = [];
          this.validateMidi();
          this.MIDI_HAS_FIRED = true;
        }
      })

      // debugger
    })
  }

  ngOnInit(): void {

    //subscribe to the display service and render when new abc string
    this.abcString$ = this.ds.abcString$.subscribe(abcString=>{
      // console.log('abcString received in song part: '+abcString);
      this.abcString = abcString;
      this.renderAbcWithOptions();
    });
  }

  ngOnDestroy(): void {
    this.abcString$.unsubscribe(); 
    this.midiNotesTab$.unsubscribe();
  }

  ngAfterViewInit(){
    // console.log("SONG COMPONENT INIT ngAfterViewInit");
    this.paper = document.querySelector("#abcCanvas");
    this.loadFromJson();
    this.ds.renderFromModel();
    if(this.parsedData.hide_chord) this.ds.changeCss(".abcjs-chord","opacity:0");
    if(this.parsedData.hide_function) this.ds.changeCss(".abcjs-annotation","opacity:0");

  }

  setNote(a: string): void {
    this.note = a;
  }
  setAlt(a:string){
    this.alteration = a;
  }
  setType(t: string){
    this.type = t;
  }

  validate(){
    console.log('validate');
    this.stopChrono();
    let ans = {note:this.note+this.alteration, type:this.type, time:(this.endTime-this.startTime)};
    let hasWon = this.resService.addChordAnswer( ans, this.receivedData );
    this.askNext.emit( hasWon );
  }

  validateMidi(){
    this.ds.changeCss(".abcjs-chord","opacity:1");
    this.ds.changeCss(".abcjs-annotation","opacity:1");
    // console.log('validate');
    this.stopChrono();
    let ans = {midiNotes:this.midiAnswer, time:(this.endTime-this.startTime)};

    // let hasWon = true; //debug
    let hasWon = this.resService.addMidiAnswer( ans, this.receivedData );
    console.log("hasWon => ",hasWon);

    this.midiAnswer = [];
    this.prevMidiNotes = [];
    
    this.askNext.emit( hasWon );
  }

  startChrono(){
    this.startTime = performance.now();
  }

  stopChrono(){
    this.endTime = performance.now();
  }

  //----------------------------------LOADING / RENDERING

  renderAbcWithOptions(){
    // console.log("this.abcString => ",this.abcString);
    // debugger
    setTimeout(()=>{
      let staffwidth = (window.innerWidth>900)?window.innerWidth*0.6: window.innerWidth*0.9;
      abcjs.renderAbc('abcCanvas', this.abcString, {
        visualTranspose:this.sm.getTranspose(),
        staffwidth:90, 
        scale:2,
        add_classes:true, 
        responsive:'resize',
      });

      this.startChrono();

    },10)    
    // clickListener:this.measureClicked.bind(this), 
  }


  loadFromJson(){

    this.parsedData = JSON.parse(this.receivedData);

    let mode = (this.parsedData.mode=='n_minor' || this.parsedData.mode=='h_minor' || this.parsedData.mode=='m_minor')?'m':'';

    let transpo = ['G','Ab','A','Bb','B','C', 'Db', 'D', 'Eb', 'E', 'F', 'F#'].indexOf(this.parsedData.tonality)-5;
    // console.log('transpo => ',transpo);
    // debugger

    let infos = {
      "id": "60",
      "title": "",
      "artist": "",
      "album": "",
      "transcription": "",
      "style": "",
      "tonality": this.parsedData.tonality+mode,
      "signature": "",
      "owner": "1",
      "clef": "treble"
    }

    let parts =  [
      {
        "idx": 1,
        "title": "",
        "tonality":this.parsedData.tonality+mode, 
        "meter": "",
        "measures": [
          {
            "idx": 1,
            "eol": false,
            "collapse": true,
            "notes": "",
            "chords": this.parsedData.chord_name,
            "lyrics": "",
            "analysis": this.parsedData.chord_degree,
            "beats": [
              {
                "c": "",
                "n": "",
                "a": "",
                "l": "",
                "chord": this.parsedData.chord_name,
                "notes": "["+this.parsedData.abc_string+"]",
                "analysis": this.parsedData.chord_degree,
                "lyrics": ""
              }
            ],
          }
          
        ],
        "measures_max_lines": 3
      }
    ]
    this.sm.loadScoreFromJson(infos, parts);
    // this.sm.setTranspose(transpo);
  }

}//end of class
