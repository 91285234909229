import { Component, OnInit, Input, HostListener, NgZone } from '@angular/core';
import { Songmodel } from '@models/songmodel/songmodel';
import Font_chords_eq from '@assets/font_chords_eq.json';
import { DisplayService } from '@services/display/displayService';
import { Subscription } from 'rxjs/Subscription';
import { TransportService } from '@services/transport/transport.service';

import { AudioPlayer } from '@services/audioplayer/audioplayer.service';
import { YoutubeService } from '@services/youtube-service/youtube.service';

import { Interval } from "@tonaljs/tonal";
import { SelectionModel } from '@models/selectionmodel/selectionmodel';
import { Measure } from '@models/songmodel/measure';
import { ConfigModel } from '@models/configmodel/configModel';
import { CHROMA_KEY_COLOR } from 'src/app/utils/chromakey.constants';

@Component({
  selector: 'app-chordstrip',
  templateUrl: './chordstrip.component.html',
  styleUrls: ['./chordstrip.component.scss']
})
export class ChordstripComponent {


  // watcher of the input "chord" :
  @Input() set cur_chord(valeur: Array<any>) {
    this.change_displayed_chord(valeur);
  }

  @Input() set display(val:boolean){
    this.visible = val;

    setTimeout(()=>{
      let elem = $("#global-stripform").find("#chordstripname_"+this.selected_measure);
      this.checkInView(elem);
    },50);
  }

  @HostListener('mousewheel', ['$event']) onMousewheel(event) {
    event.preventDefault();
    this.scroll_increment = $('#global-stripform')[0].scrollLeft;
    $('#global-stripform').scrollLeft(this.scroll_increment+=event.deltaY);
    this.scroll_increment = (this.scroll_increment < 0)? 0:this.scroll_increment;
    this.scroll_increment = (this.scroll_increment > $('#global-stripform')[0].scrollWidth)? $('#global-stripform')[0].scrollWidth-100:this.scroll_increment;
  }

  public scroll_increment = 1;
  public visible:boolean=false;

  public chordstrip_hidden=true;

  public displayed_chord: string = '';

  public measures_list:any=[];
  public measures_chords:any=[];
  public measures_basses:any=[];
  public measures_analysis:any=[];

  public chordsNameType:any=[];
  public Translate = Font_chords_eq;

  private abcString$:Subscription;

  public selected_measure=2;
  public selected_beat=1;

  private beatChangeSub$:Subscription;
  private displayNotesMode_SUBJ_update$:Subscription;

  private use_chromakey_SUBJ_update$: Subscription;

  constructor(private sm:Songmodel, private dm:DisplayService, private zone:NgZone, private transport:TransportService, private sel:SelectionModel,private ap:AudioPlayer, private yt:YoutubeService, private cm:ConfigModel ){

    this.displayNotesMode_SUBJ_update$ = this.cm.displayNotesMode_SUBJ_update$.subscribe(data=>{
      if(data == 'letters'){
        this.chordsNameType = this.measures_analysis;
      } else
        this.chordsNameType = this.measures_chords;
    });

  this.beatChangeSub$ = this.transport.beatChange.subscribe((data)=>{

    if (data.measure === undefined || data.beat === undefined) {
      console.error('Selected measure or beat is not defined');
      return;
    }

    if (typeof data.measure === 'string') data.measure = parseInt(data.measure);
    if (typeof data.beat === 'string') data.beat = parseInt(data.beat);

    this.selected_measure = data.measure;
    this.selected_beat = data.beat;

    console.log(`this.selected_measure => ${this.selected_measure}.${this.selected_beat}`);

    console.log("this.selected_measure =>", this.selected_measure)
    console.log("this.selected_beat =>", this.selected_beat)

    let elem = $("#global-stripform").find("#chordstripname_"+this.selected_measure);
    // elem.addClass('selected');
    this.selectOne();
    this.checkInView(elem);
  });

  }

  selectOne(): void {
    this.deselectAll();
    let elem = $("#global-stripform").find("#chordstripname_"+this.selected_measure + "_" + this.selected_beat);
    if (!elem.length) return;
    elem[0].classList.add('selected');
  }

  deselectAll(): void {
    const selected = $('#global-stripform .selected');
    if (!selected.length) return;
    selected[0].classList.remove('selected');
  }

  ngAfterViewInit(): void {

    this.set_chord_line();

    this.abcString$ = this.dm.abcString$.subscribe(abcString=>{
      this.zone.run(()=>{
        this.measures_list = [];
        this.measures_chords = [];
        this.measures_basses = [];
        this.measures_analysis = [];

        this.set_chord_line();
      })
    });

    this.use_chromakey_SUBJ_update$ = this.cm.use_chromakey_update$.subscribe(
      (data) => {
        this.onContrast(data);
      }
    );
    setTimeout(()=>this.onContrast(this.cm.use_chromakey), 100);

  }

  ngOnDestroy() {
    this.abcString$.unsubscribe();
  }

  public set_chord_line(){
    var regex = /([A-G][b#]{0,2})(.*)/;
    var regexA = /([b#]{0,2}[I|V|i|v]{0,3})(.*)/;


    for(let p_nb = 0; p_nb < this.sm.getParts().length; p_nb++){

      let p = this.sm.getPart(p_nb);

      // console.log("%c part = >","color:red",p)

      // Pour chaque measure de la part
      for(let m_nb = 0; m_nb < p.getMeasures().length; m_nb++){

        let m = p.getMeasure(m_nb);

        // console.log("%c measure = >","color:blue",m)
        this.measures_list.push(m);

        let measure_chords = [];
        let measure_basses = [];
        let measure_analysis = [];

        // Pour chacun des accords de la measure on prends sa basse et
        // let c = m.chords.split(' ');
        let beats = m.getBeats();
        for(let b of beats){
          let bass = b.getChord().split('/')[1];
          if(bass != undefined){
            bass = bass.replace('/','');
          }else{
            bass = 'R';
          }

          let chord = b.getChord().split('/')[0];
          let n = chord.match(regex);

          let tonic;
          let cur_chord_type;
          // if(n==null)continue;
          if(n==null){
            tonic = '';
            cur_chord_type = '';
          }else{
            tonic = n[1];
            cur_chord_type = n[2];
          }

          measure_chords.push([tonic,cur_chord_type]);
          if(bass != 'R')bass = Interval.distance(tonic, bass);
          if (bass.endsWith('m')) bass = `minor/${bass}`;
          else if (bass.endsWith('M')) bass = `major/${bass}`;
          else if (bass.endsWith('d')) bass = `diminished/${bass}`;
          measure_basses.push([bass]);

        }







        // Analysis
        for(let b of beats){
          let a = b.getAnalysis();
          let chord = a.split('/')[0];
          let n = chord.match(regexA);
          if(n==null)continue;
          let tonic = n[1];
          let cur_chord_type = n[2];

          measure_analysis.push([tonic,cur_chord_type]);
        }


        this.measures_chords.push(measure_chords);
        this.measures_basses.push(measure_basses);
        this.measures_analysis.push(measure_analysis);
      }

    }

    this.chordsNameType = this.measures_chords;
  }

  // Select the received measure from Html
  public select(m:Measure, beat_nb:number=0){

      this.sel.setSelection([m],beat_nb);

      let t = m.getBeat(beat_nb).getAudioRegion().start
      if(!t)t=m.getAudioRegion().start;

      this.ap.setCurrentTime(t);
      this.yt.seekTo(t);

      this.transport.setMeasure(m.getIdx());
      this.transport.setBeat(Number(beat_nb));
  }


  private checkInView(elem) {
    console.log(`Checking if element is in view: (${this.selected_measure}.${this.selected_beat})`, elem.length);
    if (!elem.length) {
      console.log(`None! ${this.selected_measure}.${this.selected_beat}`);
      return;
    }
    if (this.selected_beat === undefined) {
      console.error('Selected beat is not defined');
      // Fallback to old method
      elem[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline:'center'
      });
      return;
    }

    let playAnimation = this.ap.isPlaying();
    if (!this.ap.shouldPlayChordstripAnimation()) playAnimation = false;

    const beatContainer = elem[0];
    const beatContainerJQ = $(beatContainer);

    const beatContainerWidth = beatContainerJQ.outerWidth();

    // console.log('Checking if element is in view:', elem);
    // console.log(`Selected beat: ${this.selected_measure}.${this.selected_beat}`);

    const beatElm = beatContainerJQ?.children()?.[this.selected_beat];
    if (!beatElm) {
      console.log(`Selected beat element is not defined ${this.selected_measure}.${this.selected_beat}`);
      return;
    }

    // console.log('Selected beat element:', beatElm.id);

    // Relative to the screen
    const elemWidth = $(beatElm).outerWidth();
    const elemLeft = $(beatElm).offset().left;

    const screenWidth = window.innerWidth;

    // console.log('Element:', elemLeft, elemWidth, screenWidth);


    const measure = this.sm.getMeasureByIdx(this.selected_measure);
    if (!measure) {
      console.error('Selected measure is not defined');
      return;
    }
    const beat = measure.getBeat(this.selected_beat);
    if (!beat) {
      console.error('Selected beat is not defined');
      return;
    }

    const audioRegion = beat.getAudioRegion();
    const beatStart = Math.floor((audioRegion?.start ?? 0) * 1000);
    const beatEnd = Math.floor((audioRegion?.end ?? 0) * 1000);

    const beatDuration = beatEnd - beatStart;
    // console.log(`Beat duration: ${beatDuration}ms`);

    // Not actually using center, instead using 1/4 of the screen width
    const screenCenter = screenWidth / 4;
    // console.log('Screen center:', screenCenter);


    const beatElmEnd = elemLeft + elemWidth;

    // Check if we can get the next beat, or the next measures first beat
    const nextBeat = $(`#chordstripname_${this.selected_measure}_${this.selected_beat + 1}`);
    const nextMeasure = $(`#chordstripname_${this.selected_measure + 1}_0`);

    const globalStripForm = $('#global-stripform');
    globalStripForm.stop();

    // Scroll left is the current scroll position of the chord strip, i.e how many pixels are off-screen to the left
    const scrollLeft = $('#global-stripform').scrollLeft();

    const currentBeatNeededToScroll = beatElmEnd - screenCenter;

    if (!playAnimation) {
      $('#global-stripform').scrollLeft(scrollLeft + currentBeatNeededToScroll - elemWidth);
      return;
    }

    // Go to end of current beat
    if (!nextBeat.length && !nextMeasure.length) {
      // console.log('No next beat or measure found');
      globalStripForm.animate({
        scrollLeft: scrollLeft + currentBeatNeededToScroll
      }, beatDuration, 'linear');
      return;
    }

    // Go to start of next measure
    if (!nextBeat.length) {
      // console.log('Going to next measure');
      // Scroll to the next measure
      const nextMeasureLeft = nextMeasure.offset().left;
      const neededToScroll = nextMeasureLeft - screenCenter;

      // Animate the scroll to the center of the screen
      globalStripForm.animate({
        scrollLeft: scrollLeft + neededToScroll
      }, beatDuration, 'linear');
      return;
    }

    // Go to next beat
    // console.log('Going to next beat');

    // Scroll to the next beat
    const nextBeatLeft = nextBeat.offset().left;
    const neededToScroll = nextBeatLeft - screenCenter;

    // Animate the scroll to the center of the screen
    globalStripForm.animate({
      scrollLeft: scrollLeft + neededToScroll
    }, beatDuration, 'linear');
  }


  // When the watcher of the input chord changes
  public change_displayed_chord(valeur: Array<any>):void{
    if(!valeur['chords'].length || valeur['full_chord'].empty){
      this.displayed_chord = ' ';
      return;
    }
    this.displayed_chord = valeur['chords'][0];
  }

  // When right click on the strip
  public changeChordName(e){
    e.preventDefault();

    if(this.cm.get_letters_Numbers_sync())return; // prevent changing chordStrip to letters/numbers when global sync is on

    if(this.chordsNameType == this.measures_chords){ //if its measure display

      this.chordsNameType = this.measures_analysis;
    } else
      this.chordsNameType = this.measures_chords;

    console.log("this.chordsNameType =>", this.chordsNameType)
    }

    private contrast: boolean = false;

    onContrast(newValue: boolean) {
      this.contrast = newValue;
      console.log("Contrast: ", this.contrast);
      this.contrast ? this.enableContrast() : this.disableContrast();
    }

    enableContrast() {
      console.log("Enabling contrast");
      const chordstripContainer = document.querySelector(".chordstrip-container") as HTMLElement;
      if (!chordstripContainer) return;
      chordstripContainer.style.backgroundColor = CHROMA_KEY_COLOR;

      const chordstripElms = document.querySelectorAll(".stripform-container .card") as NodeListOf<HTMLElement>;
      if (!chordstripElms) return;
      chordstripElms.forEach((elm) => {
        elm.style.backgroundColor = CHROMA_KEY_COLOR;
      });
    }

    disableContrast() {
      console.log("Disabling contrast");
      const chordstripContainer = document.querySelector(".chordstrip-container") as HTMLElement;
      if (!chordstripContainer) return;
      chordstripContainer.style.backgroundColor = "";

      const chordstripElms = document.querySelectorAll(".stripform-container .card") as NodeListOf<HTMLElement>;
      if (!chordstripElms) return;
      chordstripElms.forEach((elm) => {
        elm.style.backgroundColor = "";
      });
    }
}

