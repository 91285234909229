<div id="exercice-rythm">

 <!-- [hidden]="!debug_mode"   -->
  <ng-container >
<!-- *ngIf="debug_mode" -->
    <input type="checkbox" name="sound" [(ngModel)]="sound" (change)="set_mute()">
    <label for="sound">Sound</label>
    

    <div>
      <label for="#input_right">Score : </label>
      <textarea id="input_right"  style="white-space: pre-wrap; overflow-wrap: break-word;width:80%;" 
        [(ngModel)]="abc_string" 
        (input)="update_score()" 
        (change)="update_score()">
      </textarea>
    </div> 
  </ng-container>
 

    <span class="p-2">
      <label for="#input_transpose">Transpose </label>
      <input id="input_transpose" type="number" min="-6" max="6" [(ngModel)]="transpose" (change)="update_score()">
    </span>
    <span class="p-2">
       <label for="midi_off">Midi off </label>
       <input type="checkbox" id="midi_off" [(ngModel)]="MIDI_OFF">
    </span>
    <span class="p-2">
       <label for="random">Random song in book </label>
       <input type="checkbox" id="random" [(ngModel)]="random_song">
    </span>
    <span class="p-2">
      <label for="#song_number">Song number </label>
      <input id="song_number" type="number" min="0" [(ngModel)]="current_tune" (change)="update_score()">
    </span>
    <span class="p-2">
      <label for="#mute_chords">Mute chords </label>
      <input id="mute_chords" type="checkbox" [(ngModel)]="mute_chords" (change)="update_score()">
    </span>
     <div id="synthControl" ></div>


  <div id="abcjs-wrapper" style="overflow-y: scroll; height: 75vh; width: 100%; display: flex; flex-direction: row; justify-content: center;">
    <div id="abcjs-paper-1" style="width:800px;max-width: 1200px;"> </div>
    <!-- <div id="abcjs-paper-2" style="width:800px;max-width: 1200px;"> </div> -->
    <!-- <div id="abcjs-paper" style="width:800px;max-width: 1200px;"> </div> -->
  </div>


  <!-- <app-metro class="metronome"></app-metro> -->
  <!-- <app-fifth-circle [chord]="cur_chord" class="fifth_circle"></app-fifth-circle> -->

</div>
