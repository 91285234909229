const diatonicModes = [
  "major",
  "minor",
  "aeolian",
  "ionian",
  // "_________",
  "dorian",
  "phrygian",
  "lydian",
  "mixolydian",
  "locrian",
  // "_________",
  "major pentatonic",
  "major blues",
  "minor pentatonic",
  "minor blues",
  // "_________",
  "harmonic major",
  "harmonic minor",
  // "_________",
  "diminished",
  "minor six diminished",
  "half-whole diminished",
  "composite blues",
  // "_________",
  "bebop",
  "bebop minor",
  "bebop major",
  "bebop locrian",
  // "_________",
  "ionian pentatonic",
  "mixolydian pentatonic",
  "ritusen",
  "egyptian",
  "neopolitan major pentatonic",
  "vietnamese 1",
  "pelog",
  "kumoijoshi",
  "hirajoshi",
  "iwato",
  "in-sen",
  "lydian pentatonic",
  "malkos raga",
  "locrian pentatonic",
  "minor six pentatonic",
  "flat three pentatonic",
  "flat six pentatonic",
  "scriabin",
  "whole tone pentatonic",
  "lydian #5P pentatonic",
  "lydian dominant pentatonic",
  "minor #7M pentatonic",
  "super locrian pentatonic",
  "minor hexatonic",
  "augmented",
  "piongio",
  "prometheus neopolitan",
  "prometheus",
  "mystery #1",
  "six tone symmetric",
  "whole tone",
  "messiaen's mode #5",
  "locrian major",
  "double harmonic lydian",
  "altered",
  "locrian #2",
  "mixolydian b6",
  "lydian dominant",
  "lydian augmented",
  "dorian b2",
  "melodic minor",
  "ultralocrian",
  "locrian 6",
  "augmented heptatonic",
  "dorian #4",
  "lydian diminished",
  "leading whole tone",
  "lydian minor",
  "phrygian dominant",
  "balinese",
  "neopolitan major",
  "double harmonic major",
  "hungarian minor",
  "hungarian major",
  "oriental",
  "flamenco",
  "todi raga",
  "persian",
  "enigmatic",
  "major augmented",
  "lydian #9",
  "purvi raga",
  "spanish heptatonic",
  "minor bebop",
  "ichikosucho",
  "kafi raga",
  "chromatic",
] as const;

const diatonicModeShifts: {[key in PartialDiatonicModes]: number} = {
  '':0,
  'major':0,
  'minor':9,
  'aeolian':9,
  'ionian':0,
  // ----------------
  'dorian':10,
  'phrygian':8,
  'lydian':1,
  'mixolydian':11,
  'locrian':7,
  // ----------------
  'major pentatonic':0,
  'major blues':0,
  'minor pentatonic':9,
  'minor blues':9,
  // ----------------
  'harmonic major':0,
  'harmonic minor':9,
  // ----------------
  'diminished':0,
  'minor six diminished':9,
  'half-whole diminished':0,
  'composite blues':9,
  // ----------------
  'bebop':0,
  'bebop minor':9,
  'bebop major':0,
  'bebop locrian':7,
  // ----------------
  'ionian pentatonic':0,
  'mixolydian pentatonic':11,
  'ritusen':0,
  'egyptian':0,
  'neopolitan major pentatonic':0,
  'vietnamese 1':0,
  'pelog':0,
  'kumoijoshi':0,
  'hirajoshi':0,
  'iwato':0,
  'in-sen':0,
  'lydian pentatonic':1,
  'malkos raga':0,
  'locrian pentatonic':7,
  'minor six pentatonic':9,
  'flat three pentatonic':0,
  'flat six pentatonic':0,
  'scriabin':0,
  'whole tone pentatonic':0,
  'lydian #5P pentatonic':1,
  'lydian dominant pentatonic':1,
  'minor #7M pentatonic':9,
  'super locrian pentatonic':7,
  'minor hexatonic':9,
  'augmented':0,
  'piongio':0,
  'prometheus neopolitan':0,
  'prometheus':0,
  'mystery #1':0,
  'six tone symmetric':0,
  'whole tone':0,
  "messiaen's mode #5":0,
  'locrian major':7,
  'double harmonic lydian':1,
  'altered':0,
  'locrian #2':7,
  'mixolydian b6':11,
  'lydian dominant':1,
  'lydian augmented':1,
  'dorian b2':10,
  'melodic minor':9,
  'ultralocrian':7,
  'locrian 6':7,
  'augmented heptatonic':0,
  'dorian #4':10,
  'lydian diminished':1,
  'leading whole tone':0,
  'lydian minor':1,
  'phrygian dominant':8,
  'balinese':0,
  'neopolitan major':0,
  'double harmonic major':0,
  'hungarian minor':0,
  'hungarian major':0,
  'oriental':0,
  'flamenco':0,
  'todi raga':0,
  'persian':0,
  'enigmatic':0,
  'major augmented':0,
  'lydian #9':1,
  'purvi raga':0,
  'spanish heptatonic':0,
  'minor bebop':9,
  'ichikosucho':0,
  'kafi raga':0,
  'chromatic':0,
};

type DiatonicMode = (typeof diatonicModes)[number];

// Used for the select dropdown to allow for a blank / fallback option
type PartialDiatonicModes = DiatonicMode | "";

// Array of all diatonic modes
const diatonicModesArray = diatonicModes as any as DiatonicMode[];

export { DiatonicMode, PartialDiatonicModes, diatonicModesArray, diatonicModeShifts };
